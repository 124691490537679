import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlusAuthenticationService, TokenService } from '@karve.it/core';

import { Message } from 'primeng/api';
import { FREYA_ROUTES } from 'src/app/global.constants';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';

import { AuthMethod, AuthMethodType, ListAuthMethodsGQL, RedeemInviteTokenGQL, RedeemInviteTokenMutationVariables } from '../../../generated/graphql.generated';

import { BrandingService } from '../../services/branding.service';
import { GoogleHelperService } from '../../services/google-helper.service';
import { EMAIL_VALIDATION_PATTERN } from '../../shared/pattern.validator';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../auth.styles.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  @ViewChild('authContainer', { static: true }) authContainer!: ElementRef;

  subs = new SubSink();

  loginPath: string = FREYA_ROUTES.login;

  // Whether the token should be removed from the URL for security purposes
  removeTokenFromURL = environment.production;

  // The token loaded from the url, reset to undefined on error
  token: string;
  tokenLoading = true;

  showBusinessInfo = false;

  signUpForm = new FormGroup({
    givenName: new FormControl('', [Validators.required, Validators.minLength(1)]),
    familyName: new FormControl('', [Validators.required, Validators.minLength(1)]),
    email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern(EMAIL_VALIDATION_PATTERN)]),

    businessName: new FormControl('', [ Validators.minLength(1), Validators.maxLength(100) ]),
    // TODO: custom validator
    businessID: new FormControl('', [ Validators.minLength(1), Validators.maxLength(100) ]),
    // TODO: custom validator
    businessCode: new FormControl('', [ Validators.minLength(1), Validators.maxLength(8) ]),

    businessAddress: new FormControl('', [ Validators.minLength(1), Validators.maxLength(1024) ]),

    password: new FormControl('', [
      Validators.required,
      Validators.minLength(environment.passwordMinLength),
    ]),
    passwordConfirm: new FormControl('', [
      Validators.required,
      Validators.minLength(environment.passwordMinLength),
    ]),
  });

  formSubmitted = false;

  authMethods: AuthMethod[] = [];
  passwordAuthMethod: AuthMethod;

  addressValid = false;
  googleAutocompleteOptions = {
    fields: ["formatted_address", "address_components", "geometry", "icon", "name"],
    componentRestrictions: {
      country: ['CA', 'US'],
    },
  };

  // Messages inside this component, more agency than notifications
  signupMessages: Message[] = [];

  constructor(
    public router: Router,
    public googleHelper: GoogleHelperService,
    public brandingService: BrandingService,
    private redeemInviteTokenGQL: RedeemInviteTokenGQL,
    private plusAuth: PlusAuthenticationService,
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private authMethodsGQL: ListAuthMethodsGQL,
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.authMethodsGQL.fetch({}).subscribe((res) => {
      if (res.data.authMethods) {
        this.setAuthMethods(res.data.authMethods);
      }
    }, (err) => {
      console.error(`Error retrieving auth methods`, err);
      this.setAuthMethods([]);
    });

    this.subs.sink = this.route.queryParamMap.subscribe((params) => {
      if (params.get('token')) {
        this.token = params.get('token');

        if (this.removeTokenFromURL) {
          this.freyaHelper.setPageUrl('/auth/signup', true);
        }

        this.signUpForm.reset();

        this.subs.sink = this.tokenService.validateToken({
          token: this.token,
          tokenType: 'Invite'
        }).subscribe({
          next: (res) => {
            if (!res.data.validateToken) { return; }
  
            if (!res.data.validateToken.isValid) {
              this.setPageForInvalidToken();
              return;
            }
  
            const metadata = res.data.validateToken.metadata;
            const businessName = metadata.businessName || '';
            let businessID = '';
            let businessCode = '';
            if (businessName) {
              this.showBusinessInfo = true;
              businessID = businessName
                .toLowerCase()
                .replace(/[ ]/g, '-')
                .replace(/[^a-z0-9-]/g, '')
                .slice(0, 100);
  
              // Carry Moving And Storage -> CMS
              businessCode = businessName
                .split(' ')
                .filter((v) => ![
                  'and',
                  '&',
                  'n'
                ].includes(v.toLowerCase()))
                .map((v) => v[0])
                .filter(Boolean)
                .join('');
  
              this.signUpForm.get('businessName').addValidators(Validators.required);
              this.signUpForm.get('businessID').addValidators(Validators.required);
              this.signUpForm.get('businessCode').addValidators(Validators.required);
              this.signUpForm.get('businessAddress').addValidators(Validators.required);
            }
  
            this.signUpForm.setValue({
              givenName: metadata?.givenName, // .find((md) => md.key === 'givenName')?.value,
              familyName: metadata?.familyName,// .find((md) => md.key === 'familyName')?.value,
              email: metadata?.email,
  
              businessName,
              businessID,
              businessCode,
              businessAddress: '',
  
              password: '',
              passwordConfirm: '',
            });
  
            this.tokenLoading = false;
  
          },
          error: (err) => {
            console.error(err);
            this.setPageForInvalidToken();
          }
        });
      } else {
        this.tokenLoading = false;
        this.freyaHelper.setPageUrl('/auth/signup', true);
      }
    });
  }

  setAuthMethods(authMethods: AuthMethod[]) {
    this.authMethods = authMethods;
    console.log(authMethods);
    this.passwordAuthMethod = authMethods
      .find((a) => a.authMethodType === AuthMethodType.Password);

    // find password auth method. If it does not exist, show an error.
    if (!this.passwordAuthMethod) {
      this.signupMessages.push({
        severity: 'error',
        summary: 'Cannot sign up',
        detail: 'Password authentication is not available.',
      });
      return;
    }

    const passwordValidators = [
      Validators.required,
      Validators.minLength(this.passwordAuthMethod.password_minLength || 7),
    ];

    const passwordControl = this.signUpForm.get('password');
    passwordControl.clearValidators();
    passwordControl.setValidators(passwordValidators);
    const passwordConfirmControl = this.signUpForm.get('passwordConfirm');
    passwordConfirmControl.clearValidators();
    passwordConfirmControl.setValidators(passwordValidators);
  }

  setPageForInvalidToken() {
    this.token = undefined;
    this.localNotify.warning('Invite has expired or already been used');
    this.tokenLoading = false;
    this.freyaHelper.setPageUrl('/auth/signup', true);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  signup() {
    if (!this.signUpForm.valid || !this.token) {
      return;
    }
    if (this.showBusinessInfo && !this.addressValid) {
      this.signupMessages = [{
        severity: 'error',
        detail: `You must select your Business Address from the dropdown menu`,
      }];

      return;
    }

    const value = this.signUpForm.getRawValue();

    this.formSubmitted = true;

    const variables = {
      ...value,
      token: this.token,
    } as RedeemInviteTokenMutationVariables;


    if (!this.showBusinessInfo) {
      delete variables.businessAddress;
      delete variables.businessCode;
      delete variables.businessID;
      delete variables.businessName;
    }

    this.subs.sink = this.redeemInviteTokenGQL.mutate(variables).subscribe({
        next: (redeemRes) => {
          if (redeemRes.data?.redeemInviteToken) {
            this.afterSignupSuccess(
              this.signUpForm.controls.email.value,
              value.password,
            );
          } else {
            this.signupMessages = [{ severity: 'error', detail: 'Failed to Signup' }];
          }
        },
        error: (err) => {
          this.signupMessages = [{ severity: 'error', detail: err }];
          this.formSubmitted = false;
          this.scrollTop();
        },
      });
  }

  scrollTop() {
    this.authContainer.nativeElement.scrollTop = 0;
  }

  afterSignupSuccess(email: string, password: string) {
    this.subs.sink = this.plusAuth.authenticate({
        email,
        password,
      }).subscribe(async (authRes) => {
        if (authRes) {
          this.navigateToNextPage();
        }
    });
  }

  navigateToNextPage() {
    if (this.showBusinessInfo) {
      // this was a new system setup so let's take them to the tutorial
      this.router.navigate(['/estimating']);
    } else {
      this.router.navigate(['/']);
    }

  }

  passwordsValid() {
    const password = this.signUpForm.get('password').value;
    const passwordConfirm = this.signUpForm.get('passwordConfirm').value;

    if (!password || !passwordConfirm) { return false; }
    return password === passwordConfirm;
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  handleAddressChange(address: google.maps.places.AutocompleteResponse) {

    const control = this.signUpForm.get('businessAddress');
    console.log(address, control.value);

    if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
      control.setValue('');
      return;
    }

    control.setValue(address.formatted_address);
    this.addressValid = true;
  }

  handleAddressTextChange($event) {
    this.addressValid = false;
  }



}
