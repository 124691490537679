import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import MiniSearch from 'minisearch';

import { debounceTime, map, withLatestFrom } from 'rxjs';

import { FIELD_CONFIG } from '../../global.constants';
import { safeParseJSON } from '../../js';
import { brandingFeature } from '../../state/branding.store';

import { generateUUID } from '../../utilities/state.util';
import { JobToolActions } from '../job-tool.actions';
import { jobToolFeature } from '../job-tool.reducer';


import { InventoryActions } from './inventory.actions';
import { defaultInventory, inventoryFeature, InventoryItemDefinition, JobInventory } from './inventory.feature';
import { recalculateInventory } from './inventory.util';

export const loadInventoryFromJob = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {
    return actions$.pipe(
		ofType(InventoryActions.inventoryPageInitialized, JobToolActions.jobLoaded),
		withLatestFrom(store.select(jobToolFeature.selectJob)),
		map(([{}, job]) => {

			const fields = job?.fields;
			if (!fields?.length) {
				return InventoryActions.inventoryLoading();
			}

			// TODO: check if there is any change state we need to check

			const inventoryField = fields?.find((f) => f.name === FIELD_CONFIG.jobs.inventory.name);
			const inventoryNotesField = fields?.find((f) => f.name === FIELD_CONFIG.jobs.inventoryNotes.name);

			const inventory: JobInventory = safeParseJSON(inventoryField?.values[0]?.value, defaultInventory);
			const inventoryNotes = inventoryNotesField?.values[0]?.value;

			if (inventory) {
				// fix some default field values from the old inventory system
				for (const room of inventory.rooms) {
					room.id = room.id || generateUUID();
					for (const item of room.inventory) {
						item.id = item.id || generateUUID();
					}
				}
			}

			return InventoryActions.inventoryLoaded({
				inventory,
				notes: inventoryNotes,
			});

		}),
	);
}, { functional: true, dispatch: true });

export const searchForInventoryItem = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {

	let loaded = false;
	const itemMiniSearch = new MiniSearch({
		fields: ['reviewerName', 'label', 'rooms', 'aliases'],
		storeFields: [
			'name',
			'reviewerName',
			'label',
			'packing',
			'quantity',
			'volume',
			'weight',
			'generated',
			'rooms'
		],
		idField: 'label',
	});

	const itemMap: {
		[ id: string ]: InventoryItemDefinition,
	} = {};

    return actions$.pipe(
		ofType(InventoryActions.itemSearchCompleteRequest),
		withLatestFrom(store.select(brandingFeature.selectConfigs)),
		map(([{ search, roomId }, configs]) => {
			if (!loaded) {
				const items = configs['inventory.items'] || [];
				loaded = true;
				itemMiniSearch.addAll(cloneDeep(items));
				for (const item of items) {
					itemMap[item.label] = item;
				}
			}

			const searchResults = itemMiniSearch.search(
				search, {
				prefix: true,
				fuzzy: 0.2,
				boost: {
					reviewerName: 2,
					label: 2,
				}
			});

			const items = searchResults
				.map((r) => itemMap[r.id])
				.filter(Boolean);

			return InventoryActions.itemSearchCompleteResult({
				items,
			})


			// if (!combinedResults?.length){
			//   filteredItemOptions = cloneDeep(configs['inventory.items']);
			//   return;
			// }

		}),
	  );

}, { functional: true, dispatch: true });

export const inventoryChanged = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {
    return actions$.pipe(
		ofType(
			InventoryActions.addRoomButtonClicked,
			InventoryActions.itemSearchSelected,
			InventoryActions.itemSearchSave,
			InventoryActions.removeRoomClick,
			InventoryActions.removeItemClick,
			InventoryActions.quantityUpdated,
			InventoryActions.itemSearchEnterPressed,
			InventoryActions.editItemSaved,
		),
		withLatestFrom(
			store.select(inventoryFeature.selectRooms),
			store.select(inventoryFeature.selectWeightUnit),
			store.select(inventoryFeature.selectVolumeUnit),
			store.select(inventoryFeature.selectChanges),
		),
		debounceTime(1),
		map(([action, rooms, weightUnit, volumeUnit, changes]) => {

			const inventory = recalculateInventory(rooms, weightUnit, volumeUnit);
			const lastRoomChanged = 'roomId' in action ? action.roomId : undefined;

			return InventoryActions.inventoryChanged({
				lastRoomChanged,
				action,
				inventory,
				doFocus: 'doFocus' in action ? action.doFocus : undefined,
			});

		}),
	);
}, { functional: true, dispatch: true });

export const inventoryEffects = {
	searchForInventoryItem,
	loadInventoryFromJob,
	inventoryChanged,
};
