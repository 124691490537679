<section>
  <header>
    <div>
      <h2><i class="pi pi-calendar p-mr-1"></i> Timeline</h2>
    </div>

    <p-button
      label="Add Event"
      icon="pi pi-plus"
      [outlined]="true"
      (onClick)="openNewEventDialog()"
      [disabled]="(jobLoaded$ | async) === false"
    ></p-button>

  </header>
  <main
    class="p-mt-4"
    *ngIf="jobLoaded$ | async; else loading"
  >
    <article
      *ngFor="let jobEvent of jobEvents; let eventIndex = index"
      [style]="jobEvent.style"
    >
      <div
        class="eventDetails"
        (click)="openEventInRightPanel(jobEvent.event.id)"
      >
        <span class="title">{{ jobEvent.title }}</span>
        <span class="info"> {{ jobEvent.details }} </span>
      </div>
      <div>
        <p-button
          *ngIf="jobEvent?.event?.status !== 'booked'"
		      class="p-m-1"
          [outlined]="true"
		      label="Book"
		      (click)="bookEvent(jobEvent?.event?.id)"
		      icon="pi pi-calendar-plus"
          [disabled]="isEventMissingLocations(jobEvent?.event?.id)"
          pTooltip="{{ getMissingLocationsTooltip(jobEvent?.event?.id) }}"
		    ></p-button>
        <p-button
          icon="pi pi-ellipsis-v"
          [text]="true"
          (onClick)="openActionMenu($event, jobEvent, eventIndex)"
        />
      </div>
    </article>
  </main>
</section>

<p-menu
  #menu
  [model]="eventMenuItems"
  [popup]="true"
/>

<p-dialog
  header="New Event"
  [modal]="true"
  [(visible)]="isDialogVisible"
  [closable]="false"
  [breakpoints]="{ '1024px': '62vw', '510px': '93vw' }"
  [style]="{ width: '35vw' }"
>
  <ng-template pTemplate="headless">
    <app-timeline-create-event
      [event]="jobEvents?.[eventUnderEdittingIndex]"
      (closeDialog)="closeNewEventDialog()"
    ></app-timeline-create-event>
  </ng-template>
</p-dialog>

<p-dialog
  header="Schedule Event"
  [modal]="true"
  [(visible)]="isBookDialogVisible"
  [closable]="false"
  [style]="{ width: '90vw' }"
>
  <ng-template pTemplate="headless">
    <app-timeline-book-event
      [timezone]="timezone"
      [jobInDifferentTimezoneWarning]="jobInDifferentTimezoneWarning"
      [event]="eventUnderBooking"
      (closeDialog)="closeBookEventDialog()"
    ></app-timeline-book-event>
  </ng-template>
</p-dialog>

<ng-template #loading>
  <main class="p-mt-4">
    <p-skeleton
      class="flex-1"
      *ngFor="let _ of [1, 2, 3]"
      width="100%"
      height="5rem"
    />
  </main>
</ng-template>
