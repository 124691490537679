/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventDropArg, EventInput } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventResizeDoneArg, ThirdPartyDraggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { dayJsFullCalendarTimeZonePlugin, dayjs } from '@karve.it/core';
import { ZoneDir } from '@karve.it/interfaces/common.gql';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';

import { capitalize, cloneDeep, intersection } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { AvailabilityOutput, AvailabilityQuery, AvailabilityQueryVariables, CalendarEvent, CalendarEventForScheduleFragment, ScheduleEventsQueryVariables } from '../../../../../generated/graphql.generated';

import { AssetWithConfig } from 'src/app/availability/availability.interfaces';
import { CalendarHelperService } from 'src/app/calendar-helper.service';
import { JOB_EVENT_TYPES, MIN_EVENT_RENDER_TIME, ADDITIONAL_EVENT_TYPES as SUPPORTING_EVENT_TYPES, eventTypeInfoMap } from '../../../../../app/global.constants';
import { isFinalizedInvoice } from 'src/app/invoices/invoices.utils';
import { BrandingService } from 'src/app/services/branding.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FullCalendarHelperService } from 'src/app/services/full-calendar-helper.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { assetTypesDropdown } from 'src/app/shared/assets/assets';
import { dateToDateString } from 'src/app/time';
import { DataError } from 'src/app/utilities/errors.util';

import { RESOURCE_AREA_HEADER } from 'src/app/schedules/schedule.constants';
import { scheduleFeature } from 'src/app/schedules/store/schedules.reducer';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { SharedModule } from 'primeng/api';
import { ScheduleEventsActions } from 'src/app/jobsv2/job-state/event-schedule-state/event-schedule.actions';
import { jobToolFeature } from 'src/app/jobsv2/job-tool.reducer';
import { eventScheduleSelectors } from 'src/app/jobsv2/job-state/event-schedule-state/event-schedule.selectors';
import { getCalendarDate } from 'src/app/jobsv2/jobsv2-events-helpers';


export type EventChange = (EventResizeDoneArg | EventDropArg) & {
  notifyAttendees: boolean;
  status: 'Pending' | 'Saving' | 'Saved' | 'Failed';
  hasConflict: boolean;
};

// Define the view type mappings and session storage keys
export const SCHEDULE_VIEW_TYPE_MAP = {
  'resourceTimelineDay': 'day',
  'resourceTimelineWeek': 'week',
  'dayGridMonth': 'month',
};

@Component({
  selector: 'app-schedule-v2',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule,
    FullCalendarModule,
  ],
  templateUrl: './schedule-v2.component.html',
  styleUrls: ['./schedule-v2.component.scss'],
})
export class ScheduleV2Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  // Calendar
  @ViewChild('fc', { static: false }) calendarComponent: FullCalendarComponent;
  @ViewChild('scheduleOverlay') scheduleOverlay: OverlayPanel;

  @Output() initialized = new EventEmitter();

  @Input() height = '85vh';
  // Change the height based on the number of assets
  @Input() dynamicHeight = true;

  // The area whose events/availability should be shown, defaults to all
  @Input() area: string;

  @Input() jobInDifferentTimezoneWarning: string;
  @Input() timezone: string;
  @Input() event: CalendarEvent;

  // If false shows the totals for day, If true shows all events for day
  showMonthEvents = false;

  // This subject is fired when the user changes dates/view, the subscription uses a debounce to filter unecessary backend requests
  scheduleDebounce = new Subject<void>();

  calendar: Calendar;

  calendarTitle$ = new BehaviorSubject('');

  subs = new SubSink();
  timeOuts: NodeJS.Timeout[] = [];

  //observables
  calendarEvents$ = this.store.select(jobToolFeature.selectCalendarEvents);
  calendarAssets$;
  calendarAvailability$ = this.store.select(jobToolFeature.selectCalendarAvailability);
  eventDateSelected$ = this.store.select(eventScheduleSelectors.selectEventDate);
  calendarEventDataErrors$ = this.store.select(jobToolFeature.selectCalendarEventDataErrors);

  // Assets
  assets: AssetWithConfig[];
  noAvailableAssetsWarning = '';

  // Calendar Events
  calendarEvents: CalendarEventForScheduleFragment[] = [];
  calendarEventDataErrors: DataError[];

  // Availability
  availabilities: AvailabilityOutput[];
  availabilityQueryRef: QueryRef<AvailabilityQuery, AvailabilityQueryVariables>;

  startSeconds: number; // The Starting Time for the calendar in unix seconds
  endSeconds: number; // The Ending Time for the calenar in unix seconds

  notifyMasterToggle = true;

  // TODO: Break this out into a service value or a seperate file
  calendarOptions: CalendarOptions = {
    headerToolbar: false,
    plugins: [
      resourceTimelinePlugin,
      interactionPlugin,
      dayGridPlugin,
      dayJsFullCalendarTimeZonePlugin
    ],
    timeZone: this.timeZoneHelper.getCurrentTimezone(), // will be overriden in OnChanges with job timezone
    eventMinWidth: 0.001,
    height: 'auto',
    initialView: 'resourceTimelineDay',
    schedulerLicenseKey: environment.fullCalendarKey,
    eventDisplay: 'block', // Without this dayGrid events don't have backgrounds
    resourceAreaHeaderContent: RESOURCE_AREA_HEADER,
    resourceAreaWidth: this.fcHelper.getResourceAreaWidth(),
    stickyFooterScrollbar: true,
    titleFormat: { // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'short'
    },
    moreLinkDidMount: (arg) => {
      // @ts-ignore
      const date = arg.el.closest('.fc-day').dataset.date;
      const eventsForDay = this.calendarEvents.filter((ev) => dayjs(ev.start * 1000).format('YYYY-MM-DD') === date);

      const content = document.createElement('div');
      content.classList.add('content');

      const assetTypes = this.scheduleFilters.get('assetTypes').value;
      const filteredEventsForDay = eventsForDay.filter(event => !assetTypes.length || event.assets.some((asset) => assetTypes.includes(asset.type)));
      const totalEl = document.createElement('div');
      if (filteredEventsForDay.length > 0) {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        totalEl.classList.add('total');
        totalEl.style.color = 'var(--text-color)';
        totalEl.title = `Discounted Subtotal for ${formattedDate}`;
        const totalAmount = (filteredEventsForDay.reduce((total, current) => total + current.discountedSubTotal, 0) / 100).toFixed(2);
        totalEl.innerText = `$${totalAmount}`;
      }

      const eventBadgesEl = document.createElement('div');
      eventBadgesEl.classList.add('badges-container');
      const eventTypesToDisplay = {};

      for (const event of filteredEventsForDay) {
        if (event.type === 'book-off') {
          continue;
        }
        if (eventTypesToDisplay[event.type]) {
          eventTypesToDisplay[event.type].push(event);
          continue;
        }
        eventTypesToDisplay[event.type] = [event];
      }
      // Create a badge for every event type present on the day
      for (const eventType of Object.keys(eventTypesToDisplay)) {
        const badge = document.createElement('div');
        badge.title = `${eventTypesToDisplay[eventType]?.length} ${capitalize(eventType)} events`;
        badge.innerText = `${eventTypesToDisplay[eventType]?.length}`;

        const backgroundColor = eventTypeInfoMap[eventType]?.backgroundColor || 'primary-color';

        badge.style.backgroundColor = `var(--${backgroundColor})`;
        eventBadgesEl.append(badge);
      }

      content.append(totalEl, eventBadgesEl);

      arg.el.append(content);
    },
    moreLinkClick: (info) => {
      const tzDate = dayjs(dateToDateString(info.date), 'YYYY/MM/DD').add(1, 'day').toDate();
      this.calendar.gotoDate(tzDate);
      this.calendar.changeView('resourceTimelineDay');
    },
    moreLinkContent: (args) => ({ domNodes: [document.createElement('div')] }),
    dayMaxEvents: 0,
    views: {
      dayGridMonth: { // name of view
        titleFormat: { month: 'long', year: 'numeric' },
      },
      resourceTimelineWeek: {
        titleFormat: { month: 'long', day: 'numeric', year: 'numeric' }
      }
    },
    resourceLabelContent: (info) => (
      this.fcHelper.generateResourceLabel(info, this.assets as any)
    ),
    resources: [
      {
        id: 'loading',
        title: 'Loading ...',
      }
    ],
    resourceOrder: 'order',
    eventContent: (event) => {

      const calendarEvent = this.calendarEvents.find((ce) => ce.id === event.event._def.groupId);

      let eventEl;

      if (event.event.extendedProps?.type === 'availability') {
        eventEl = this.fcHelper.createFreyaAvailability(false);
      } else if (calendarEvent?.type === 'book-off') {
        eventEl = this.fcHelper.createBookedOffEvent(calendarEvent, this.calendar.view.type);
      } else {

        let disabled = false;

        if (this.freyaHelper.lockDate > calendarEvent.end) {
          disabled = true;
        }

        if (calendarEvent.invoices?.some(isFinalizedInvoice)) {
          disabled = true;
        }

        eventEl = this.fcHelper.createFreyaEvent(event, calendarEvent, this.calendar, false, false, false, disabled);
      }

      return { domNodes: [eventEl] };
    },
    eventResizableFromStart: false,
    eventDurationEditable: false,
    // Sets the granularity for moving events
    snapDuration: '00:05:00',
    // Sets the frequency of the lines
    slotDuration: '01:00:00',
    slotLabelInterval: '02:00:00',
    // Sets the start and end times for the day
    slotMinTime: '06:00:00',
    slotMaxTime: '22:00:00',
    navLinks: false,
    datesSet: (info) => {
      this.calendarHelper.displayedDateChanged(info.startStr, info.view.type);
    },
    dateClick: (info) => {
      if (info.view.type === 'dayGridMonth') {
        this.calendar.gotoDate(info.dateStr);
        this.calendar.changeView('resourceTimelineDay');
      }
    },
    navLinkWeekClick: (date, event) => {
      event.preventDefault();
    },
    events: [],
    eventOrder: 'start',
    eventResize: (info: EventResizeDoneArg) => {
      // Pending events do not send notifications
      const isPending = info.event?._def.extendedProps?.event?.status === 'pending';
    },
    // Do not automatically scroll the calendar when dragging an event on mobile (makes dragging awkward)
    dragScroll: !this.responsiveHelper.isSmallScreen,
    longPressDelay: this.responsiveHelper.isSmallScreen ? this.responsiveHelper.dragDelay : undefined
  };

  // Constants
  assetTypes = assetTypesDropdown;
  systemEventTypes = [...JOB_EVENT_TYPES];
  supportingEventTypes = SUPPORTING_EVENT_TYPES;

  // Filters
  scheduleFilters: UntypedFormGroup = new UntypedFormGroup({
    assetTypes: new UntypedFormControl([]),
    eventTypes: new UntypedFormControl([]),
    products: new UntypedFormControl([]),
    // supportingEventTypes: new FormControl([]),
  });

  formattedDate$ = this.store.select(scheduleFeature.selectFormattedDate);

  constructor(
    // Angular
    private store: Store,
    // Helpers
    public responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private fcHelper: FullCalendarHelperService,
    private calendarHelper: CalendarHelperService,
    private brandingService: BrandingService,
    private timeZoneHelper: TimezoneHelperService,
    public permissions: PermissionService,
  ) { }

  ngOnInit(): void {
    // Set the calenar date from other components
    this.subs.sink = this.fcHelper.changeCalendarDate.subscribe((date) => {
      if (!this.calendar) { return; }
      const tzDate = dayjs(date).format('YYYY-MM-DD');
      this.calendar.gotoDate(tzDate);
    });

    // This ensures that the calendar is rerendered if the container size changes to match that size.
    this.subs.sink = this.freyaHelper.containerSizeChanged.subscribe(() => {
      this.renderAfterDelay();
    });

    // Update the calendar to reflect any changes based on authentication status
    this.subs.sink = this.brandingService.currentZone().subscribe(() => {
      // skip initial behavioursubject callback
      if (!this.calendar) { return; }
      this.calendar.removeAllEvents();
      this.calendarEvents = [];
    });

    this.subs.sink = this.timeZoneHelper.timezoneChanged.subscribe(() => {
      this.calendar.setOption('timeZone', this.timeZoneHelper.getCurrentTimezone());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timezone) {
      this.calendar?.setOption('timeZone', this.timezone);
    }

    if (changes['event'] && this.event?.type) {
      this.calendarAssets$ = this.store.select(eventScheduleSelectors.selectFilteredCalendarAssets(this.event.type));
    }
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.setCalendarTitle();

    this.subs.sink = this.formattedDate$.subscribe((formattedDate) => {
      const currentCalendarDate = dayjs(this.calendar.getDate()).format('YYYY-MM-DD');

      if (formattedDate && formattedDate !== currentCalendarDate) {
        this.calendar.gotoDate(formattedDate);
      }
    });

    this.subs.sink = this.calendarAssets$.subscribe((calendarAssets) => {
      this.assets = cloneDeep(calendarAssets);

      this.calendar?.batchRendering(() => {
        this.convertAssetsIntoResources();
      });

      if (!this.assets.length) {
        this.noAvailableAssetsWarning = 'There are no available assets in the area where current job is being placed.';
      }
    });

    this.subs.sink = this.calendarEvents$.subscribe((calendarEvents) => {
      this.calendarEvents = cloneDeep(calendarEvents);

      this.calendar?.batchRendering(() => {

        this.removeEventsOfTypes(['calendar-event']);

        if (this.calendarEvents) {
          this.setCalendarTitle();
          this.convertCalendarEventsIntoEvents(this.calendarEvents);
        }
      });
    });

    this.subs.sink = this.calendarAvailability$.subscribe((calendarAvailability) => {
      this.availabilities = cloneDeep(calendarAvailability);

      this.calendar?.batchRendering(() => {
        this.removeEventsOfTypes(['availability']);
        if (this.availabilities) {
          this.convertAvailabilityIntoEvents();
        } else {
          this.availabilities = [];
        }
      });
    });

    this.subs.sink = this.calendarEventDataErrors$.subscribe((calendarEventDataErrors) => {
      this.calendarEventDataErrors = cloneDeep(calendarEventDataErrors);
    });

    this.subs.sink = this.eventDateSelected$.subscribe((eventDateSelected) => {
      if (eventDateSelected) {
        this.calendar.gotoDate(getCalendarDate(eventDateSelected));
      }
    });

    if (this.event?.start) {
      const dateForCalendar = new Date(this.event?.start * 1000);
      this.changeDate(dateForCalendar);
    } else this.changeDate('today');

    const container = document.getElementById('droppable-area');

    new ThirdPartyDraggable(container);
  }

  ngOnDestroy() {
    this.clearOverlays();
    this.timeOuts.forEach((t) => clearTimeout(t));
    this.subs.unsubscribe();
    this.freyaHelper.scheduleEditModeEnabled.next(false);
  }

  // CALENDAR HELPERS

  setCalendarDate(date: Date) {
    this.fcHelper.changeCalendarDate.next(date);
  }

  isMonthView() {
    return this.calendar.view.type === 'dayGridMonth';
  }

  /**
   * Changes the view based on the view type, throws a warning in edit mode
   *
   * @param viewType The type of you you want to render
   */
  changeView(viewType: string) {
    this.calendar.changeView(viewType);
    this.setCalendarHeight();

    this.store.dispatch(ScheduleEventsActions.dateSelected({
      date: this.getDateForDatePicker(),
      eventType: this.event?.type,
      ...(this.event?.status === 'required' && this.event?.start
        ? { currentEventIdToExclude: this.event?.id }
        : {}),
      ...(this.event?.assets?.length && this.event?.status === 'required'
          ? { preselectedAssets: this.event?.assets }
          : {})
    }));
  }

  changeDate(dateType: 'prev' | 'next' | 'today' | Date) {
    if (dateType === 'prev') {
      this.calendar.prev();
    }

    if (dateType === 'next') {
      this.calendar.next();
    }

    if (dateType === 'today') {
      this.calendar.today();
    }

    if (dateType instanceof Date) {
      this.calendar.gotoDate(dateType);
    }

    this.setCalendarTitle();

    this.store.dispatch(ScheduleEventsActions.dateSelected({
      date: this.getDateForDatePicker(),
      eventType: this.event?.type,
      ...(this.event?.status === 'required' && this.event?.start
        ? { currentEventIdToExclude: this.event?.id }
        : {}),
      ...(this.event?.assets?.length && this.event?.status === 'required'
          ? { preselectedAssets: this.event?.assets }
          : {})
    }));
  }

  setCalendarHeight(height?: string) {
    if (this.calendar.view.type === 'dayGridMonth') {
      height = 'auto';
    }


    if (!height) { return; }
    this.calendar.setOption('contentHeight', height);

  }

  clearOverlays() {
    const overlays = document.querySelectorAll('.freya-fc-hover');

    for (let i = 0; i < overlays.length; i++) {
      overlays.item(i).remove();
    }
  }

  setCalendarTitle() {
    this.calendarTitle$.next(this.calendar.getCurrentData().viewTitle);
  }

  // Calendar is drawn not dynamic, as such for events not watched by FC (container size) we must call this.
  renderAfterDelay() {
    this.calendar.setOption('resourceAreaWidth', this.fcHelper.getResourceAreaWidth());
    const timeout = setTimeout(() => { // Timeout ensures that the DOM has been updated before we try to redraw
      this.calendar.render();

      // This logic handles setting the top property for the times to never overlap with the dates
      const sourceElement = document.getElementsByClassName('calendar-header-wrapper').item(0);
      const targetElement = document.getElementsByClassName('fc-scrollgrid').item(0).getElementsByTagName('thead')[0];

      // Get the height of the source element
      const sourceElementHeight = sourceElement.clientHeight;

      // Set the top property of the target element based on the height of the source element
      targetElement.style.top = sourceElementHeight + 'px';
    }, 225);

    this.timeOuts.push(timeout);
  }

  getCalendarActionVariables() {
    const start = dayjs(this.calendar.view.currentStart || new Date()).startOf('day');
    const end = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');

    const duration = 0;

    const min = dayjs(start).startOf('day').subtract(duration, 'day').unix();
    const max = dayjs(end).endOf('day').add(duration, 'day').unix();

    return {
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
      min,
      max
    }
  }

  getDateForDatePicker() {
    const currentDate = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');
    return currentDate.format('MM/DD/YYYY');
  }

  /**
   * Remove events of a certain type from the calendar
   *
   * @param eventTypes The types to remove
   */
  removeEventsOfTypes(eventTypes: string[]) {
    const eventsToRemove = this.calendar.getEvents().filter((e) => eventTypes.includes(e?.extendedProps?.type));

    for (const event of eventsToRemove) {
      event.remove();
    }

  }

  // QUERY HELPERS
  getScheduleVariables(): { availabilty: AvailabilityQueryVariables; events: ScheduleEventsQueryVariables } {
    const start = dayjs(this.calendar.view.currentStart || new Date()).startOf('day');
    const end = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');

    // add / subtract one period so previous/next loads quickly
    // TODO: REDO this logic to run as supporting requests
    const duration = 0; // end.diff(start, 'day');
    const min = dayjs(start).startOf('day').subtract(duration, 'day').unix();
    const max = dayjs(end).endOf('day').add(duration, 'day').unix();

    return {
      availabilty: {
        objectIds: this.getDisplayedAssets().map((a) => a.id),
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
        zone: this.area || undefined,
      },
      events: {
        filter: {
          min,
          max,
        },
        limit: 2000,
      }
    };
  }

  generateAssetInput() {
    return {
      filter: {
        types: this.scheduleFilters.get('assetTypes').value.length ? this.scheduleFilters.get('assetTypes').value : undefined,
        zoneDir: ZoneDir.any,
      },
      limit: -1
    };
  }

  getDisplayedAssets() {
    let assetsToDisplay = [];
    if (this.scheduleFilters.get('assetTypes').value?.length) {
      assetsToDisplay = this.assets.filter((a) => this.scheduleFilters.get('assetTypes').value.includes(a.type));
    } else {
      assetsToDisplay = this.assets;
    }

    return assetsToDisplay;
  }

  // PARSE OBJECTS TO Full Calendar
  convertCalendarEventsIntoEvents(events: CalendarEventForScheduleFragment[]) {

    for (const ev of events) {
      const start = ev.start;
      const end = ev.end;

      // This may contain the id of deleted assets...
      const allAssetIds = ev.assets.map((a) => a.id);

      // ...therefore, make sure allAssetIds contains the id of at least one existing asset...
      const hasExistingAssets = ev?.assets?.length
        && intersection(allAssetIds, this.assets.map((a) => a.id)).length;

      // ...otherwise assign 'none' so the event is assigned to the 'Unassigned' resource
      const assetIds = hasExistingAssets ? allAssetIds : ['none'];

      let endForRendering = end;

      if ((end - start) < MIN_EVENT_RENDER_TIME) {
        endForRendering += MIN_EVENT_RENDER_TIME - (end - start);
      }

      const {
        backgroundColor,
        textColor,
      } = this.fcHelper.determineColorsFromEvent(ev);

      // Create Actual Event
      const event: EventInput = {
        title: ev.title,
        groupId: ev.id,
        start: start * 1000,
        end: endForRendering * 1000,
        id: ev.id,
        extendedProps: {
          type: 'calendar-event',
          eventId: ev.id,
          event: ev,
          eventType: ev.type,
        },
        resourceIds: assetIds,
        color: backgroundColor,
        textColor,
        editable: false,
        resourceEditable: false,
      };
      this.calendar.addEvent(event);

    }


    // After all events have been looped the conflicts list will be up to date
    this.renderAfterDelay();
  }

  // Turn System Assets into Calendar Resources
  convertAssetsIntoResources() {
    this.calendar.refetchResources();
    if (!this.assets) { return; }

    let assetsToDisplay = this.getDisplayedAssets();

    if (this.scheduleFilters.get('assetTypes').value?.length) {
      assetsToDisplay = this.assets.filter((a) => this.scheduleFilters.get('assetTypes').value.includes(a.type));
    } else {
      assetsToDisplay = this.assets;
    }

    let order = 0;


    this.calendar.addResource({ // Add a resource for events that don't have any assets
      id: 'none',
      title: 'Unassigned',
      eventColor: 'red',
      type: 'Unassigned',
      order,
    });
    order++;

    for (const asset of assetsToDisplay) {
      this.calendar.addResource({
        id: asset.id,
        title: `${asset.name} (${asset.type})`,
        eventColor: asset.zones[0]?.color,
        extendedProps: {
          id: asset.id,
          type: asset.type,
          name: asset.name,
        },
        order,
      });

      // increment order
      order++;
    }

    const loadingResource = this.calendar.getResourceById('loading');

    loadingResource?.remove();

    if (this.dynamicHeight) {
      this.setCalendarHeight(`${80 + ((this.calendar.getResources().length) * 52)}px`);
      // this.calendar.setOption('contentHeight', ``);
    }
  }

  convertAvailabilityIntoEvents() {
    for (const object of this.availabilities) {

      for (const day of object.dayAvailability) {

        // If the asset has no availability, we need to put a dummy availability to show the inverse of
        if (!day.availability?.length) {
          this.calendar.addEvent({
            title: 'Unavailable',
            groupId: `${object.objectId}-availability`,
            start: `${day.date}T00:00:00`,
            end: `${day.date}T00:00:1`,
            display: 'inverse-background',
            // The Asset that this availability belongs to
            resourceIds: [object.objectId],
            color: '#666666',
            extendedProps: {
              type: 'availability',
            },
          });

          continue;
        }

        for (const avail of day.availability) {
          const start = avail.start * 1000;
          const end = avail.end * 1000;

          this.calendar.addEvent({
            title: 'Unavailable',
            groupId: `${object.objectId}-availability`,
            start,
            end,
            display: 'inverse-background',
            // The Asset that this availability belongs to
            resourceIds: [object.objectId],
            color: '#666666',
            extendedProps: {
              type: 'availability',
            },
          });
        }
      }
    }
  }

  setMaxEvents(showAllEvents) {
    if (showAllEvents) {
      this.calendar.setOption('dayMaxEvents', false);
    } else {
      this.calendar.setOption('dayMaxEvents', 0);
    }
  }
}
