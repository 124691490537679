import { Location as AngularLocation } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FetchPolicy } from '@apollo/client/core';
import { FieldService, PlusAuthenticationService, RoleService, UserService, ZoneService } from '@karve.it/core';
import { JobService } from '@karve.it/features';
import { Artifact } from '@karve.it/interfaces/artifacts';
import { RawUser, UserRole } from '@karve.it/interfaces/auth';
import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';
import { Field } from '@karve.it/interfaces/fields';
import { RoleSearch } from '@karve.it/interfaces/roles';
import { GenerateUsersQueryInput } from '@karve.it/interfaces/users';
import { AssignObjectsToZonesInput, Zone, ZonesInput } from '@karve.it/interfaces/zones';
import { Store } from '@ngrx/store';
import { QueryOptionsAlone } from 'apollo-angular/types';
import cronstrue from 'cronstrue';
import { isEqual, isNaN, parseInt, pick } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { cmdFlags } from '../../cmd';
import { BaseCalendarEventFragment, BaseLocationFragment, BaseUserFragment, BaseZoneWithParentFragment, DocumentReadyToViewGQL, EstimatesJobFragment, FullCalendarEventFragment, FullJobFragment, FullTransactionFragment, GetConfigValuesGQL, GetConfigValuesQueryVariables, Invoice_TotalsFragment, JobPageListJobsGQL, Job_UsersFragment, LocationsGQL, LocationsQueryVariables, ServiceAreaQueryMatch, UpdateJobArchivedGQL, UpdateJobGQL, User } from '../../generated/graphql.generated';

import { MenuService } from '../base/menu/app.menu.service';
import { Country, DistanceUnit, EventLocationTypes, JOB_ROLE_MAP, jurisdictions } from '../global.constants';
import { remainingBalance } from '../jobs/jobs.util';
import { JobToolActions } from '../jobsv2/job-tool.actions';
import { FullJobFragmentWithFields, jobToolFeature } from '../jobsv2/job-tool.reducer';
import { Jobv2SelectAreaManuallyComponent, SelectAreaManuallyDialogDataComponent, SelectAreaManuallyDialogResult } from '../jobsv2/jobv2-create/jobv2-select-area-manually/jobv2-select-area-manually.component';
import { strToTitleCase } from '../js';
import { CloseJobComponent } from '../shared/close-job/close-job.component';
import { ConfirmationWithLoadingStateComponent, ConfirmationWithLoadingStateInput } from '../shared/confirmation-with-loading-state/confirmation-with-loading-state.component';
import { ContactAccountingComponent } from '../shared/contact-accounting/contact-accounting.component';
import { DeleteJobComponent } from '../shared/delete-job/delete-job.component';
import { FreyaDatePipe } from '../shared/freya-date.pipe';
import { OpenToPageInput } from '../shared/help-dialog/help-dialog.component';
import { getConfigValueByKey } from '../utilities/configs.util';
import { getJobCustomer } from '../utilities/job-customer.util';
import { Metadata } from '../utilities/metadata.util';

import { BrandingService } from './branding.service';
import { DetailsHelperService } from './details-helper.service';
import { FreyaNotificationsService } from './freya-notifications.service';
import { PermissionService } from './permission.service';
import { ResponsiveHelperService } from './responsive-helper.service';
import { TimezoneHelperService } from './timezone-helper.service';

export type ViewableArtifact = Pick<Artifact, 'name' | 'contentType' | 'id'> & Partial<Pick<Artifact, 'url' | 'signedUrl'>>;;

export interface ArtifactDialogInput {
  artifact: ViewableArtifact;
  artifactUrl?: string;
}

export interface ClosureReason {
  id: string;
  title: string;
  stages: string[];
}

// eslint-disable-next-line max-len
export type TransactionWithAvailability = FullTransactionFragment & { exceedsInvoiceBalance: boolean; linkedToInvoice: boolean; unavailable: boolean };

@Injectable({
  providedIn: 'root'
})
export class FreyaHelperService implements OnDestroy {

  subs = new SubSink();

  containerSizeChanged = new Subject<void>(); // When the size of a page container changes, likely due to a side panel being opened

  visibilityChanged = new Subject<boolean>();

  pageVisible = this.visibilityChanged.pipe(filter(vislble => vislble));

  pageHidden = this.visibilityChanged.pipe(filter(vislble => !vislble));

  layoutScrolled = new Subject<void>();

  transactionCreated = new Subject<void>();

  artifactOpened = new Subject<ArtifactDialogInput>();

  helpDialogOpened = new Subject<OpenToPageInput>();

  countryPromise: Promise<Country>;

  // Zones
  currentZone: BaseZoneWithParentFragment;
  inRootOrCorporateZone = true;

  scheduleEditModeEnabled = new BehaviorSubject(false);

  defaultEstimateLength = new BehaviorSubject<number | undefined>(undefined);

  defaultVirtualEstimateLength = new BehaviorSubject<number | undefined>(undefined);

  lockDate$ = new BehaviorSubject<number | undefined>(undefined);

  lockDateSupportInfo$ = new BehaviorSubject<{
    supportEmail: string;
    emailSubject?: string;
    contactAccountingButtonText?: string
    emailBodyPrefix?: string;
    emailBodySuffix?: string;
    eventLockedWarning?: string;
  } | undefined>(undefined);

  // Used to set the `minDate` input on calendar components, which expects a date object
  lockDateObject$ = this.lockDate$.pipe(
    map((unixLockDate) => new Date( unixLockDate * 1000)),
  );

  authStateSubscription: Subscription;

  initialized = false;

  constructor(
    private zoneService: ZoneService,
    private localNotify: FreyaNotificationsService,
    private jobService: JobService,
    private detailsHelper: DetailsHelperService,
    private router: Router,
    private userService: UserService,
    private plusAuth: PlusAuthenticationService,
    private angularLocation: AngularLocation,
    private roleService: RoleService,
    private fieldService: FieldService,
    private branding: BrandingService,
    private dialogService: DialogService,
    private responsiveHelper: ResponsiveHelperService,
    private updateJobGQL: UpdateJobGQL,
    private archiveJobGQL: UpdateJobArchivedGQL,
    private confirm: ConfirmationService,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private getLocationGQL: LocationsGQL,
    private permissionsHelper: PermissionService,
    private timeZoneHelper: TimezoneHelperService,
    private documentReadyToViewGQL: DocumentReadyToViewGQL,
    private jobPageListJobsGQL: JobPageListJobsGQL,
    private freyaDatePipe: FreyaDatePipe,
    private menuService: MenuService,
    private store: Store,
  ) {}

  manageSubscriptions() {
    this.authStateSubscription = this.plusAuth.authState.subscribe((state) => {
      if (state === 'authenticated' || state === 'reauthenticated') {
        if (this.initialized) { return; }
        this.initializeSubscriptions();
        this.initialized = true;
      } else if (state === 'deauthenticated') {
        this.subs.unsubscribe();
        this.initialized = false;
      }
    });
  }

  initializeSubscriptions() {
    this.subs.sink = this.branding.currentZone().subscribe((z) => {
      this.currentZone = z;
      this.inRootOrCorporateZone = (this.currentZone?.type === 'root' || this.currentZone?.type === 'corporate');
    });

    this.watchLockDate();

    this.watchConfigs();

    this.subs.sink = this.documentReadyToViewGQL.subscribe()
      .pipe(
        map((res) => res.data?.documentReadyToView),
        filter((doc) => doc.userId === this.plusAuth.user.id),
        withLatestFrom(this.store.select(jobToolFeature.selectJob))
      ).subscribe(([ doc, job ]) => {

        const { artifactId, templateType } = doc;

        if (templateType === 'invoice') {
          this.detailsHelper.pushUpdate({
            action: 'update',
            id: artifactId,
            type: 'Invoice',
          });

          this.localNotify.success('Invoice generated');

          this.store.dispatch(JobToolActions.invoiceReadyToView({ jobId: job.id }));
        }

        this.detailsHelper.pushUpdate({
          action: 'update',
          id: artifactId,
          type: 'Artifacts',
        });

    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.authStateSubscription.unsubscribe();
  }

  checkIfDate(value) {
    // https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date
    return Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value);
  }

  // Checks for matching values, if you modify the properties of an object it will show as removed.
  getAddedAndRemoved(original: any[], modified: any[], removedAsIds = true,) {
    const removed = original.filter((o) => !modified.find((m) => isEqual(m, o)));

    const added = modified.filter((m) => !original.find((o) => isEqual(o, m)));

    return { added, removed: removedAsIds ? removed.map((r) => r.id) : removed };
  }

  // Checks if the object has been added, removed or updated (Updating will only work for Objects with an ID property)
  getAddedRemovedUpdated(original: any[], modified: any[], 
    removedAsIds = true,  propertiesToCompare: string[],originalIdentifier = 'id', modifiedIdentifier = 'id') {
      // Removed if it exists in Original and not in Modified
      const removed = original.filter(o => !modified.some(m => m[modifiedIdentifier] === o[originalIdentifier]));
      // Added if it exists in modified and not in Original
      const added = modified.filter(m => !original.some(o => m[ modifiedIdentifier ] === o[originalIdentifier]));
      // Updated if the id matches and the properties are modified
    const updated = modified.filter(m => {
        const matchingModified = original.find(o => o[originalIdentifier] === m[modifiedIdentifier]);
        return matchingModified && !isEqual(pick(matchingModified, propertiesToCompare), pick(m, propertiesToCompare));
    });

    return {
        added,
        removed: removedAsIds ? removed.map(r => r[originalIdentifier]) : removed,
        updated
    };
}

  // Will add and remove objects from zones based on the delta of two arrays
  saveZoneChanges(initial: Zone[], final: Zone[], objects: string[]): Promise<any> {

    const { added: addToZones, removed: removeFromZones }
     = this.getAddedAndRemoved(initial.map((z) => z.id), final.map((z) => z.id), false);

    // Add
    const add = new Promise((resolve, reject) => {
      if (!addToZones?.length) { return resolve(true); }

      const addInput = {
        addObjects: objects,
        zoneIds: addToZones,
        removeObjects: [],
      } as AssignObjectsToZonesInput;

      this.subs.sink = this.zoneService.assignObjectsToZones(addInput).subscribe(
        (res) => {
          resolve(true);
        },
        (err) => {
          reject();
          this.localNotify.error('Could not add object to zones.');
        });

    });

    // Remove
    const remove = new Promise((resolve, reject) => {
      if (!removeFromZones?.length) { return resolve(true); }

      const removeInput = {
        addObjects: [],
        removeObjects: objects,
        zoneIds: removeFromZones
      } as AssignObjectsToZonesInput;

      this.subs.sink = this.zoneService.assignObjectsToZones(removeInput).subscribe(
        (res) => {
          resolve(true);
        },
        (err) => {
          reject();
          this.localNotify.apolloError(`Could not remove object from zones`,err);
        });
    });

    return Promise.all([add, remove]);
  }

  getJobCustomerId(job: Job_UsersFragment): string {
    return job?.users?.find((u) => u.role === JOB_ROLE_MAP.customerRole)?.user?.id;
  }

  cancelJob(jobId: string, metadata?: Metadata) {
    this.subs.sink = this.jobService.updateJobs({
      updateJobs: [
        {
          jobId,
          stage: 'cancelled',
          metadata,
        }
      ] }).subscribe((res) => {
      this.localNotify.success('Job was cancelled');
      this.detailsHelper.pushUpdate({
        id:jobId,
        type:'Jobs',
        action:'removed'
      },true);
      this.router.navigate(['/job', jobId]);
    }, (err) => {
      console.error(err);
      this.localNotify.error('Could not cancel job');
    });
  }

  getEventCustomer(event: FullCalendarEventFragment) {
    return event?.job?.users?.find((a) => a?.role === JOB_ROLE_MAP.customerRole)?.user;
  }

  getUser(id: string, cache = true) {
    return new Promise<RawUser>((resolve, reject) => {
      const userQueryInput = {
        roles: true,
        zones: true,
      } as GenerateUsersQueryInput;

      this.userService.listUsersV2({ userIds: [id], showDeactivated: true }, userQueryInput, cache).subscribe((res) => {
        resolve(res.data.usersv2.users[0]);
      }, (err) => {
        reject(err);
      });
    });
  }

  async getDockLocation(networkOnly?: boolean): Promise<BaseLocationFragment> {
    return new Promise(async (resolve, reject) => {
        try {
            const id = await this.getDockLocationId(networkOnly);

            if (!id) {
                reject('No ID found');
                return;
            }

            const opts: QueryOptionsAlone<LocationsQueryVariables, any> = networkOnly ? { fetchPolicy: 'network-only' } : {};

            this.getLocationGQL.fetch({ filter: { ids: [id] } }, opts).subscribe((res) => {
                resolve(res.data.locations.locations[0]);
            }, (err) => {
                this.localNotify.apolloError('Failed to get dock location', err);
                reject(err);
            });
        } catch (error) {
            reject(error);
        }
    });
}

  async getDockLocationId(networkOnly?: boolean): Promise<string> {
    return new Promise((resolve, reject) => {

      const opts: QueryOptionsAlone<GetConfigValuesQueryVariables, any> = networkOnly ? { fetchPolicy: 'network-only' } : {};

      this.subs.sink = this.getConfigValuesGQL.fetch({keys: ['franchise-info.dockLocationId']}, opts).subscribe((res) => {
        if (!res.data.getConfigValues?.length){
          this.localNotify.warning('No Dock Location set for this zone');
          reject();
        }

        resolve(res.data.getConfigValues[0]?.value);
      }, (err) => {
        reject(`getDockLocation:${err}`);
      });
    });
  }

  /**
   * Returns the current country based on the currency zone.
   * Apollo deals with caching
   * Cache will reset when zone changes.
   * Can be called multiple times at once and only one call will be made.
   */
  getCountry(): Country {
    const countryCode = this.branding.currentBranding.value.country || environment.defaultCountry;
    if (!countryCode) { throw new Error('Could not resolve country.'); }
    let country = jurisdictions.find((j) => j.country === countryCode);
    if (!country) {
      country = jurisdictions.find((j) => j.country === environment.defaultCountry);
    }
    return country;
  }

  // Resolves the currency based on the zone
  getCurrency() {
    const country = this.getCountry();
    return country?.currency || environment.defaultCurrency;
  }

  // Resolves the units (imperial or metric) based on the zone
  getUnits(): DistanceUnit {
    const country = this.getCountry();
    return country?.units || environment.defaultUnits;
  }

  /**
   * Add an attribute to an array of attributes
   *
   * @param attributes The current attributes of the object
   * @param value The attribute you want to add
   * @returns Attributes updated with the new value
   */
  addAttribute(attributes: string[], value: string): string[] {
    if (!attributes?.length) { attributes = []; } // If attributes is undefined

    if (attributes.includes(value)) { // Already included
      return attributes;
    } else {
      return [...attributes, value];
    }
  }

  /**
   * Replace attributes based on conflicting attributes.
   *
   * @param attributes Current attributes of the object
   * @param value attribute that you want to add
   * @param conflictingAttributes The attributes that conflict with the attributes you want to add
   */
  replaceAttribute(attributes: string[], value: string, conflictingAttributes: string[]): string[]{
    if (!attributes?.length) { attributes = []; }

    attributes = attributes.filter((att) => !conflictingAttributes.includes(att));

    return this.addAttribute(attributes, value);
  }

    /**
     * Replace attributes based on conflicting attributes.
     *
     * @param attributes Current attributes of the object
     * @param value attribute that you want to add
     * @param conflictingPrefix The attributes prefix that conflicts with the attribute you are trying to add
     */
    replaceAttributeByPrefix(attributes: string[], value: string, conflictingPrefix: string): string[]{
      if (!attributes?.length) { attributes = []; }

      attributes = attributes.filter((att) => !att.includes(conflictingPrefix));

      return this.addAttribute(attributes, value);
    }


  /**
   * Get the start and end time a calendar event without it's travel time.
   *
   * @param event The Calendar Event
   * @returns Start and End unix times
   */
  getCalendarEventStartandEnd(event: BaseCalendarEventFragment | CalendarEvent, overrideStart?: number, overrideEnd?: number) {

    const locations = event.locations || [];
    let startTravel = 0;
    let endTravel = 0;

    const start = overrideStart || event.start;
    const end = overrideEnd || event.end;

    locations.forEach((location, i) => {
      const nextLocation = locations[i + 1];
      const travelTime = location.travelTimeToNextLocation + location.travelTimeToNextLocationOffset;
      const time = location.estimatedTimeAtLocation + travelTime;
      // add time at location to start if we are at the dock
      if (location.type === EventLocationTypes.dockStart) {
        startTravel += time;
      }

      // add time to end if next location is dock end
      if (nextLocation?.type === EventLocationTypes.dockEnd) {
        endTravel += travelTime;
      }

      // add dock end time at location to end travel time
      if (location.type === EventLocationTypes.dockEnd) {
        endTravel += location.estimatedTimeAtLocation;
      }
    });

    return {
      start,
      end,
      startTravel,
      endTravel,
      eventStart: start + startTravel,
      eventEnd: end - endTravel,
    };
  }

  /**
   * Change the page URL without triggering angular route detection. (Cosmetic Changes)
   *
   * @param url The url you want to appear in the url bar
   */
  setPageUrl(url: string, replaceState = false) {
    if (replaceState) { // Replace instead of adding a new state
      this.angularLocation.replaceState(url);
      return;
    }

    this.angularLocation.go(url);
  }

  getAttributeValueByPrefix(
    attributes: string[],
    prefix: string,
    deliminator = '::',
  ) {
    if (!attributes) { return undefined; }
    const attr = attributes.find((_attr) => _attr.startsWith(prefix + deliminator));

    if (!attr) { return undefined; }

    const [ _prefix, value ] = attr.split(deliminator);
    return value;

  }

  /**
   *
   * Get Roles as a promise
   *
   * @param search RoleSearch Input
   * @returns The Role you wanted
   */
  async getRoles(search: RoleSearch): Promise<UserRole[]> {
    return new Promise((resolve, reject) => {
      this.roleService.listRoles(search).subscribe((res) => {
        if (!res.data?.roles?.length) { resolve(undefined); }
        resolve(res.data.roles);
      }, (err) => {
        console.error(err);
        reject(err);
      });
    });
  }

  /**
   *
   * Get a Zones as a promise
   *
   * @param input Zones Input
   * @returns The Zone that matches your criteria
   */
  async getZones(input: ZonesInput): Promise<Zone[]> {
    return new Promise((resolve, reject) => {
      this.zoneService.listZones(input, {}).subscribe((res) => {
        if (!res.data.zones?.nodes?.length) { resolve(undefined); }
        resolve(res.data.zones.nodes);
      }, (err) => {
        console.error(err);
        reject(err);
      });
    });
  }

  // /**
  //  * Get a list of valid actions for a MenuModel (used heavily in details sidepanel)
  //  *
  //  * @param actions The MenuItem Actions that we want to check for permission restrictions
  //  * @param permissionsValid The result of the watchPermissions query
  //  * @returns An array of the actions that should be shown to the user
  //  */
  getValidActions(actions: MenuItem[], permissionsValid: boolean[]){
    const validActions = [];

    let index = 0;
    for(const valid of permissionsValid){
      if (!valid) { index++; continue; }

      validActions.push(actions[index]);
      index++;
    }

    return validActions;
  }


  async getFieldValues(
    fieldNames: string[],
    objectIds: string[],
    objectLabels: string[],
    fetchPolicy?: FetchPolicy
): Promise<Field[]>{
    return this.fieldService.listFields({
      filter: {names: fieldNames},
      objects: objectIds,
      objectLabels,
    },
      fetchPolicy
    ).pipe(map((res) => {
      const output = [];
      for(const field of res.data.fields.fields){
        output.push(field);
      }
      return output;
    })).toPromise();
  }

  get onTheJobPage(): boolean{
    return this.router.url.includes('estimating');
  }

  get onTheSchedulePage(): boolean {
    return this.router.url.includes('schedule');
  }

  downloadFile(url: string, fileName: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
  }

  openItem(itemType: 'job' | 'user', id: string, openInNewTab?: boolean) {

    if (!id) { return; }

    const route = itemType === 'job' ? '/job' : '/user';

    const isJobV2PageEnabled = this.menuService.isJobV2Enabled;

    let url = this.router.createUrlTree([ route, id ]).toString();

    if (isJobV2PageEnabled && itemType === 'job') {
      url = this.router.createUrlTree([ 'jobs', id, 'overview' ]).toString();
    }

    if (openInNewTab) {
      this.openInNewTab(url);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  openInNewTab(url: string) {
    return window.open(url, '_newtab');
  }

  /**
   * Opens the artifact dialog to a given artifact,
   * provided the artifact is a document or an image (otherwise opens in a new tab).
   *
   * If the artifact is a document,
   * the document is displayed inside an iframe using the artifact's `signedUrl` or `url` properties.
   * However, this can be overriden by setting the `artifactUrl` param to a different URL.
   * This is useful if you want to use a temporary link to display the document in an external service (e.g. Pandadocs).
   *
   * @param artifact The artifact you want to open the dialog to.
   * @param artifactUrl (Optional) A URL to override the artifact's default URL.
   *
   */
  openInDialog(artifact: ViewableArtifact, artifactUrl?: string) {
    this.artifactOpened.next({ artifact, artifactUrl });
  }

  openHelpDialog(targetHelpId: string, sectionId?: string) {
    this.helpDialogOpened.next({ targetHelpId, sectionId });
  }

  getCurrentPage(url: string): string {
    const [ _blank, currentPage ] = url.split(/[/?]/);
    return currentPage;
  }

  disableAction(action: MenuItem, reason?: string) {

    action.disabled = true;

    const actionType = action.label.split(' ')[0];

    let tooltipLabel = `${actionType} not available`;

    if (reason) {
      tooltipLabel = `${tooltipLabel}: ${reason}`;
    }

    action.tooltipOptions = { tooltipLabel };
  }

  enableAction(action: MenuItem) {
    action.disabled = false;
    action.tooltipOptions = undefined;
  }

  openDeleteJobDialog(job: FullJobFragment | EstimatesJobFragment) {
    return this.dialogService.open(DeleteJobComponent, {
      header: 'Delete Job?',
      width: this.responsiveHelper.dialogWidth,
      data: { job },
      contentStyle: this.getDialogContentStyle('1rem'),
    });
  }

  openCloseJobDialog(job: FullJobFragment | EstimatesJobFragment) {
    return this.dialogService.open(CloseJobComponent, {
      header: `Closing job ${job.code}`,
      width: this.responsiveHelper.dialogWidth,
      contentStyle: this.getDialogContentStyle('1rem'),
      data: {
        job
      }
    });
  }

  openClosedJob(jobId: string) {
    this.updateJobGQL.mutate({updateJobs: [{
      jobId,
      closeJob: false,
    }]}).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: jobId,
        type: 'Jobs',
        action: 'update'
      });
      this.localNotify.success(`Job opened`);
    }, (err) => {
      this.localNotify.error(`Failed to open job`);
      console.error(err);
    });
  }

  toggleJobArchived(job: FullJobFragment | EstimatesJobFragment) {
    const isJobArchived = Boolean(job?.archivedAt);

    if (isJobArchived) {
      this.setJobArchived(job, false);
      return;
    }

    this.confirm.confirm({
      header: 'Archive Job?',
      message: 'Archiving a job prevents anyone from making changes until someone with the appropriate permissions unarchives the job.',
      accept: () => {
        this.setJobArchived(job, true);
      },
      acceptLabel: 'Archive Job',
      acceptIcon: 'pi pi-folder',
      acceptButtonStyleClass: 'p-button-warning',
      rejectLabel: 'Exit Dialog',
    });
  }

  setJobArchived(job: FullJobFragment | EstimatesJobFragment, archive: boolean) {
    const verb = archive ? 'archive' : 'unarchive';

    this.archiveJobGQL.mutate({jobId: job.id, archived: archive})
      .subscribe(() => {
        this.detailsHelper.pushUpdate({
          id: job.id,
          type: 'Jobs',
          action: 'update',
        });

        this.localNotify.success(`Job ${verb}d`);
      }, (err) => {
        this.localNotify.error(`Failed to ${verb} job`);
        console.error(err);
      });
  }

  getDialogContentStyle(paddingBottom?: string) {
    return {
      'border-radius': '0 0 6px 6px',
      'padding-bottom': paddingBottom || 0,
    };
  }

  get lockDate() {
    return this.lockDate$.value;
  }

  getFormattedLockDate(format = 'h:mm a, MMM DD') {
    if (!this.lockDate) {
      return 'No Lock Date';
    }
    return this.timeZoneHelper.getDayJsTimeZoneObject(this.lockDate).format(format);
  }

  /**
   * Takes a cron expression and returns a human-readable description of the cron schedule if the cron expression is valid,
   * otherwise returns "invalid expression", e.g. "0 12 * * *" becomes "at 12:00 PM, every day"
   */
  parseCrontime(time: string, verbose = true) {

    let output: string;

    try {
      output = cronstrue.toString(time, {
        throwExceptionOnParseError: true,
        verbose,
      });

      // change to lowercase
      output = output[0].toLowerCase() + output.slice(1);
    } catch (err) {
      output = 'Invalid Interval';
    }
    return output;
  }

  /**
   * Watches for changes in the value of the lock date config and the user permissions to modify a locked event.
   * If the user lacks the relevant permissions, it sets the lock date for the application to the value of the config,
   * otherwise it sets it to 0, which effectively unlocks all events
   * as an event is defined as locked if it ends before the lock date
   */
  watchLockDate() {

    const currentDate$ = this.getConfigValuesGQL.watch({ keys: [ 'rolling-lock-date.currentDate' ] }).valueChanges.pipe(
      filter((res) => !res.loading),
      map((res) => res?.data?.getConfigValues.find((c) => c.key === 'rolling-lock-date.currentDate')),
      map(config => parseInt(config.value)),
      filter(num => !isNaN(num))
    );

    const canModifyLockedEvents$ = this.permissionsHelper.watchPermissionsAndRestrictions([ {
      permission: 'calendarEvents.edit',
      restriction: {
        modifyLockedEvents: true,
      },
    }]).pipe(
      // Typeguard to help TS figure out val is an array
      filter((val): val is boolean[] => Array.isArray(val)),
      // Filter out empty arrays
      filter((val) => val.length > 0),
      // Get first value
      map((val) => val[0]),
      distinctUntilChanged(),
    );

    this.subs.sink = combineLatest([ currentDate$, canModifyLockedEvents$ ]).subscribe(([ currentDate, canModifyLockedEvents ]) => {
      const lockDate = canModifyLockedEvents ? 0 : currentDate;

      this.lockDate$.next(lockDate);

    });
  }

  watchConfigs() {
    this.subs.sink = this.getConfigValuesGQL.watch({
      keys: [
        'calendarEvents.estimateLength',
        'calendarEvents.virtualEstimateLength',
        'rolling-lock-date.supportEmail',
        'rolling-lock-date.emailSubject',
        'rolling-lock-date.contactAccountingButtonText',
        'rolling-lock-date.emailBodyPrefix',
        'rolling-lock-date.emailBodySuffix',
        'rolling-lock-date.eventLockedWarning',
      ]
    }).valueChanges
      .subscribe((res) => {
        if (!res?.data?.getConfigValues?.length) { return; }

        const estimateLength = res.data.getConfigValues.find((c) => c.key === 'calendarEvents.estimateLength')?.value;
        this.defaultEstimateLength.next(Number(estimateLength));

        const virtualEstimateLength = res.data.getConfigValues.find((c) => c.key === 'calendarEvents.virtualEstimateLength')?.value;
        this.defaultVirtualEstimateLength.next(Number(virtualEstimateLength));

        const supportEmail = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.supportEmail');
        const emailSubject = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.emailSubject');
        const contactAccountingButtonText = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.contactAccountingButtonText');
        const emailBodyPrefix = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.emailBodyPrefix');
        const emailBodySuffix = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.emailBodySuffix');
        const eventLockedWarning = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.eventLockedWarning');

        if (supportEmail) {
          this.lockDateSupportInfo$.next({
            supportEmail,
            emailSubject,
            contactAccountingButtonText,
            emailBodyPrefix,
            emailBodySuffix,
            eventLockedWarning,
          });
        } else {
          this.lockDateSupportInfo$.next(undefined);
        }
      });
  }

  confirmWithLoading<T>(confirmation: ConfirmationWithLoadingStateInput<T>) {
    this.dialogService.open(ConfirmationWithLoadingStateComponent, {
      header: confirmation?.header,
      data: confirmation,
      contentStyle: this.getDialogContentStyle('1.5rem'),
      width: this.responsiveHelper.dialogWidth,
    });
  }

  getTransactionsAvailableForInvoice(
    invoice: Invoice_TotalsFragment,
    transactions: FullTransactionFragment[],
  ) {

    const transactionsWithAvailability: TransactionWithAvailability[] = [];

    let transactionsAvailable = false;

    const remainingInvoiceBalance = remainingBalance(invoice, false);

    for (const transaction of transactions) {

      const exceedsInvoiceBalance = transaction.amount > remainingInvoiceBalance;

      const linkedToInvoice = transaction.invoice && !transaction.invoice.deletedAt && !transaction.invoice.voidedAt;

      const unavailable = exceedsInvoiceBalance || linkedToInvoice;

      const transactionWithAvailability: TransactionWithAvailability = {
        ...transaction,
        exceedsInvoiceBalance,
        linkedToInvoice,
        unavailable,
      };

      if (!unavailable) {
        transactionsAvailable = true;
      }

      transactionsWithAvailability.push(transactionWithAvailability);
    }

    return { transactionsWithAvailability, transactionsAvailable };

  }

  setDisabledControls(
    enable: boolean,
    formGroup: UntypedFormGroup,
    ctrlNames: string[],
    silent?: boolean,
  ) {
    for (const ctrlName of ctrlNames) {

      const ctrl = formGroup.get(ctrlName);

      if (enable && silent) {
        ctrl.enable({ emitEvent: false });
      } else if (enable) {
        ctrl.enable();
      } else if (!enable && silent) {
        ctrl.disable({ emitEvent: false });
      } else {
        ctrl.disable();
      }
    }
  }

  contactAccounting(eventId: string) {

    this.jobPageListJobsGQL.fetch({
      filter: { eventIds: [ eventId ]},
      // Resolve all this to avoid losing cached data
      resolve: ['discounts', 'stages', 'users', 'locations'],
    }).subscribe((res) => {

      const [ job ] = res.data.jobs.jobs;

      if (!job) {
        console.error('No job found for eventId', eventId);
        return;
      }

      const event = job.events?.find((event) => event.id === eventId);

      const eventDate = event.start ? this.freyaDatePipe.transform(event.start, 'h:mm a, MMM d') : 'Unscheduled';

      const eventName = `${ event.title } (${ strToTitleCase(event.status) }), ${ eventDate }`;

      const customer = getJobCustomer(job.users) as BaseUserFragment;

      const customerName = `${customer.familyName}, ${customer.givenName}`;

      const userName = `${ this.plusAuth.user?.familyName }, ${ this.plusAuth.user?.givenName } (${ this.plusAuth.user?.email })`;

      let body = ``;

      const { emailBodyPrefix, emailBodySuffix, emailSubject } = this.lockDateSupportInfo$.value;

      if (emailBodyPrefix) {
        body += emailBodyPrefix + '\n\n';
      }

      body += `
Job: ${ job.code }
Customer: ${ customerName }
Event: ${ eventName }
Link: ${ location.href }
User making the request: ${ userName }
      `;

      if (emailBodySuffix) {
        body += '\n\n' + emailBodySuffix;
      }

      this.dialogService.open(ContactAccountingComponent, {
        header: 'Contact Accounting',
        width: this.responsiveHelper.dialogWidth,
        data: {
          to: this.lockDateSupportInfo$!.value.supportEmail,
          subject: emailSubject ? `${job.code}: ${emailSubject}` : `Lock Date Change Request for ${job.code}`,
          body,
        },
        contentStyle: this.getDialogContentStyle('1rem'),
      });
    });

  }

  async openSelectAreaDialog(
    opts: {
      actionRequired?: boolean;
      closestAreas?: ServiceAreaQueryMatch[];
      header?: string;
      description?: string;
      onlyShowCurrentSubzones?: boolean;
    },
  ) {
    opts = opts || {};
    opts.header = opts.header || 'Select an area';
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing
    opts.onlyShowCurrentSubzones = opts.onlyShowCurrentSubzones ?? cmdFlags.SelectZone_ShowOnlySubZones;

    const ref = this.dialogService.open(Jobv2SelectAreaManuallyComponent, {
      header: opts.header,
      closable: true,
      dismissableMask: !opts.actionRequired,
      contentStyle: this.getDialogContentStyle('1.5rem'),
      data: {
        showCancel: true,
        onlyShowAreas: cmdFlags.SelectZone_ShowAreas,
        onlyShowCurrentSubzones: opts.onlyShowCurrentSubzones,
        description: opts.description,
        showAreaWarning: true,
        closestAreas: opts.closestAreas,
      } as unknown as SelectAreaManuallyDialogDataComponent,
    });

    return new Promise<BaseZoneWithParentFragment>((resolve, reject) => {
      const sub = ref.onClose.subscribe(async (res: SelectAreaManuallyDialogResult) => {
        sub.unsubscribe();

        if (!res) {
          return resolve(undefined);
        }

        if (res.error) {
          this.localNotify.error('Error loading areas to select', res.error.message);
          return resolve(res.zone);
        }

        return resolve(res.zone);
      });
    });
  }

  getDisableClose(job: FullJobFragmentWithFields | EstimatesJobFragment) {

    if (!job) {
      return {
        disableClose: true,
        reason: 'No job found',
      };
    }

    const hasPendingEvents = job.events.some((event) => [ 'booked', 'pending' ].includes(event.status));

    if (hasPendingEvents) {
      return {
        disableClose: true,
        reason: 'This job has pending events',
      };
    }

    const hasPendingTransactions = job.transactions.some((transaction) => transaction.stage === 'pending');

    if (hasPendingTransactions) {
      return {
        disableClose: true,
        reason: 'This job has pending transactions',
      };
    }

    const hasPendingTags = job.tags.some((tag) => tag.name === 'Pending');

    if (hasPendingTags) {
      return {
        disableClose: true,
        reason: 'This job has pending tags',
      };
    }

    return {
      disableClose: false,
      reason: null,
    };
  }

  getTagColors(severity: 'success' | 'warning' | 'danger' | 'neutral'): { color: string, backgroundColor: string } {
    switch (severity) {
      case 'success':
        return {
          color: '#0A470A',
          backgroundColor: '#E3FBE3',
        };
      case 'warning':
        return {
          color: '#492B08',
          backgroundColor: '#FDF0E1',
        };
      case 'danger':
        return {
          color: '#7D1212',
          backgroundColor: '#FCE4E4',
        };
      case 'neutral':
        return {
          color: '#32383E',
          backgroundColor: '#F0F4F8',
        };
      default:
        throw new Error('Invalid severity');
    }
  }


}
