import {ApolloError} from '@apollo/client/core';

import { GraphQLError } from 'graphql';
import { errorSrcWithoutHttpClient } from 'ngx-markdown';

export interface DataError{
    listIndex: number;
    message: string;
    code?: string;
    property?: string;
  }

/**
 *
 * @param errors List of the errors returned by the query
 * @param rootQuery The name of the root query, used in the case that two queries are run in one call
 * @param pathIndex The error path level that conatins the list index value for the error
 */
export function parseGraphqlErrors(errors: readonly GraphQLError[], rootQuery, indexLevel = 2): DataError[] {
    // Return an empty array if the query has no errors
    if (!errors?.length) { return []; }

    // Filter out errors from other queries in the same call
    const filteredErrors = errors.filter((err) => err.path[0] === rootQuery);

    return filteredErrors.map((err) => ({
        listIndex: err.path[indexLevel],
        message: err.message,
        code: err?.extensions?.code,
        property: err?.path[indexLevel + 1] || undefined,
    } as DataError));
}

export function getApolloErrorMessage(error: ApolloError) {
    //TO DO improve this logic to handle protocolErrors and clientErrors as well
    const { graphQLErrors, protocolErrors, clientErrors, networkError, message } = error;

    const networkErrors = (networkError as any)?.error?.errors;
    return (
        graphQLErrors?.[0]?.message ||
        networkErrors?.[0]?.message ||
        message
    );
}
