import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

import { combineLatest, map } from 'rxjs';
import { SubSink } from 'subsink';

import { Asset, CreateCalendarEventMutationVariables, EditCalendarEventInput, SingleEditInput } from '../../../../../generated/graphql.generated';
import { COLOR_MAP } from '../../../../colors';
import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { eventTypeInfoMapV2 } from '../../../../global.constants';
import { EventStatuses } from '../../../../schedules/dispatch/store/dispatch.reducer';
import { SharedModule } from '../../../../shared/shared.module';
import { JobToolActions } from '../../../job-tool.actions';
import { FullJobFragmentWithFields, JobEvent, JobsV2EventLocation, jobToolFeature, UpdateJobsV2CalendarEventInput, UserWithName } from '../../../job-tool.reducer';
import { getResourceChanges } from '../../../jobsv2-helpers';


type EventType = 'estimating' | 'virtualEstimate' | 'moving' | 'delivery' | 'packing' | 'unpacking';

@Component({
  selector: 'app-timeline-create-event',
  standalone: true,
  imports: [
    FreyaCommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  templateUrl: './timeline-create-event.component.html',
  styleUrl: './timeline-create-event.component.scss'
})
export class TimelineCreateEventComponent implements OnInit, OnDestroy {

  @Input() event: JobEvent | null;

  @Output() closeDialog = new EventEmitter<void>();

  private creatingEvent$ = this.store.select(jobToolFeature.eventCreationLoading);
  private updatingEvent$ = this.store.select(jobToolFeature.eventUpdateLoading);

  public isLoading = false;
  public searchedAssets$ = this.store.select(jobToolFeature.selectAssets);
  public searchedCrew$ = this.store.select(jobToolFeature.selectSearchedCrew);
  public potentialEventLocations$ = this.store.select(jobToolFeature.selectPotentialEventLocations);
  private job$ = this.store.select(jobToolFeature.selectJob);
  public job: FullJobFragmentWithFields;

  public eventTypes = Object.values(eventTypeInfoMapV2).map((event) => ({
    name: event.name,
    value: event.value,
    backgroundColor: COLOR_MAP[event?.backgroundColor]?.color || 'white',
  }));

  public eventStatus = Object.values(EventStatuses);

  public eventForm = new FormGroup({
    type: new FormControl<EventType>('moving', { nonNullable: true, validators: [Validators.required] }),
    title: new FormControl('Moving', 
      { nonNullable: true, validators: [Validators.required, Validators.maxLength(50), Validators.minLength(3)] }),
    status: new FormControl<EventStatuses>(EventStatuses.Required, { nonNullable: true, validators: [Validators.required] }),
    assets: new FormControl([], { nonNullable: true }),
    locations: new FormControl<JobsV2EventLocation[]>([], { nonNullable: true }),
    crew: new FormControl<UserWithName[]>([], { nonNullable: true }),

  });

  public isEditMode = false;

  private subs = new SubSink();

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.watchJobLocations();
    this.watchEventLoading();
    this.detectMode();
    this.setupTypeChangeListener();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private watchJobLocations() {
    this.subs.sink = this.job$.subscribe((job) => {
      this.job = job;
    });
  }

  setupTypeChangeListener() {
    this.subs.add(
      this.eventForm.get('type')?.valueChanges.subscribe((selectedType) => {
        // Only update the title if we are in create mode
        //TO DO explore if we need to update default names in edit mode
        if (!this.event) {
          const selectedEventType = this.eventTypes.find(type => type.value === selectedType);
          if (selectedEventType) {
            this.eventForm.get('title')?.setValue(`${selectedEventType.name}`);
          }
        }
      })
    );
  }



  private watchEventLoading() {
    this.subs.sink = combineLatest([this.creatingEvent$, this.updatingEvent$])
      .pipe(
        map(([creating, updating]) => creating || updating),
      )
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      })
  }

  private detectMode() {
    // If event is provided, we are in edit mode
    if (this.event) {
      const { locations = [], crew = [] } = this.event;
      const { type, title, assets, status } = this.event.event;

      this.eventForm.patchValue({
        type: type as EventType,
        title,
        status: status as EventStatuses,
        assets: structuredClone(assets),
        locations: structuredClone(locations),
        crew: structuredClone(crew),
      });

      this.isEditMode = true;
    }
  }
  public searchAssets(event: AutoCompleteCompleteEvent) {
    this.store.dispatch(JobToolActions.assetSearched({ assetSearch: event.query }));
  }

  public searchCrew(event: AutoCompleteCompleteEvent) {
    this.store.dispatch(JobToolActions.crewSearched({ crewSearch: event.query }));
  }

  public formSubmitted(): void {
    if (!this.eventForm.valid) return;

    const { type, title, status, assets = [], crew = [], locations } = this.eventForm.value;

    // Calculate location order
    const orderedLocations = this.calculateLocationOrder(locations);

    if (this.isEditMode) {

      const originalEvent = this.event.event;
      const { crew: originalCrew, locations: originalLocations } = this.event

      const { added: addAssets, removed: removeAssets } = getResourceChanges({
        original: originalEvent.assets, modified: assets, keyExtractor: (item: Asset) => item.id
      });

      const { added: addLocations, removed: removeLocations, edited: editLocations } = getResourceChanges<JobsV2EventLocation>({
        original: originalLocations,
        modified: orderedLocations,
        keyExtractor: (item: any) => item.id,
        compareFn: (original, modified) => original.order === modified.order &&
          original.estimatedTimeAtLocation === modified.estimatedTimeAtLocation,
        detectEdited: true
      });

      const { added: attendeesToAdd, removed: attendeesToRemove } = getResourceChanges({
        original: originalCrew, modified: crew,
        keyExtractor: (item: UserWithName) => item.id
      });

      const editCalendarEventInput: SingleEditInput = {
        id: originalEvent.id,
        edit: {
          type,
          title,
          status,
          setAssets: {
            addAssets: addAssets.map((a) => a.id),
            removeAssets: removeAssets.map((a) => a.id),
          },
          setLocations: {
            addLocations: addLocations.map((l) => ({
              estimatedTimeAtLocation: l.estimatedTimeAtLocation,
              ignoreInDuration: true,
              locationId: l.locationId,
              order: l.order,
              type: l.type,
            })),
            editLocations: editLocations.map((el) => ({
              estimatedTimeAtLocation: el.estimatedTimeAtLocation,
              id: el.id,
              ignoreInDuration: true,
              order: el.order,
              type: el.type,
            })),
            removeLocations: removeLocations.map((rl) => rl.id),
          },
          setAttendees: {
            addAttendees: attendeesToAdd.map((u) => ({ role: u.role, userId: u.id })),
            removeAttendees: attendeesToRemove.map((u) => ({ role: u.role, userId: u.id })),
          },
        }
      };

      this.store.dispatch(JobToolActions.eventUpdateRequested({
        edits: [editCalendarEventInput]
      }));

      const updateSuccess$ = this.store.select(jobToolFeature.eventUpdateLoaded);

      // Subscribe to the success indicator
      this.subs.sink = updateSuccess$.subscribe((success) => {
        if (success) {
          this.operationSuccessfull();
        }
        // If not successful, do nothing and let the user adjust as needed
      });
    } else {

      const createEventsInput: CreateCalendarEventMutationVariables = {
        calendarEvents: [
          {
            type,
            title,
            status,
            assetIds: assets.map((asset: Asset) => asset.id),
            attendees: crew.map((crew: UserWithName) => ({
              userId: crew.id,
              role: crew.role
            })),
            jobId: this.job.id,
            sequentialOrder: (this.job?.events?.length || 0) + 1,
            // We want to remove address, id and addressLabel from the ordered locations
            locations: orderedLocations.map((location) => ({
              estimatedTimeAtLocation: location.estimatedTimeAtLocation,
              ignoreInDuration: location.ignoreInDuration,
              locationId: location.locationId,
              order: location.order,
              type: location.type,
            })),
          },
        ],
      };

      this.store.dispatch(JobToolActions.eventCreationRequested({ createEventsInput }));

      const updateSuccess$ = this.store.select(jobToolFeature.eventCreationLoaded);

      // Subscribe to the success indicator
      this.subs.sink = updateSuccess$.subscribe((success) => {
        if (success) {
          this.operationSuccessfull();
        }
        // If not successful, do nothing and let the user adjust as needed
      });
    }


  }

  private operationSuccessfull() {
    this.eventForm.reset();
    this.subs.unsubscribe();
    this.onClose()
  }

  public onClose() {
    this.closeDialog.emit();
  }

  private calculateLocationOrder(locations: JobsV2EventLocation[]): JobsV2EventLocation[] {
    return locations.map((location, index) => ({ ...location, order: index }));
  }

  drop(event: CdkDragDrop<string[]>) {
    // We are modifying the form array directly, without using the form control methods (patchValue, setValue, etc.)
    moveItemInArray(this.eventForm.value.locations, event.previousIndex, event.currentIndex);
  }
}
