import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { SubSink } from 'subsink';

import { OverviewChipComponent } from '../job-overview/overview-header/overview-chip/overview-chip.component';
import { jobToolFeature } from '../job-tool.reducer';

import { InventoryActions } from './inventory.actions';
import { inventoryFeature } from './inventory.feature';

@Component({
  selector: 'app-job-inventory',
  standalone: true,
  imports: [
    CommonModule,
    FreyaCommonModule,
    OverviewChipComponent,
  ],
  templateUrl: './job-inventory.component.html',
  styleUrl: './job-inventory.component.scss'
})
export class JobInventoryComponent implements OnInit {

  job$ = this.store.select(jobToolFeature.selectJob);
  jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);

  search$ = this.store.select(inventoryFeature.selectSearch);
  searchResults$ = this.store.select(inventoryFeature.selectSearchResults);
  totalVolume$ = this.store.select(inventoryFeature.selectTotalVolume);
  totalWeight$ = this.store.select(inventoryFeature.selectTotalWeight);
  totalItems$ = this.store.select(inventoryFeature.selectTotalItems);
  totalBoxes$ = this.store.select(inventoryFeature.selectTotalBoxes);

  weightUnit$ = this.store.select(inventoryFeature.selectWeightUnit);
  volumeUnit$ = this.store.select(inventoryFeature.selectVolumeUnit);

  rooms$ = this.store.select(inventoryFeature.selectRooms);

  addRoomDialogVisible$ = this.store.select(inventoryFeature.selectAddRoomDialogVisible);
  addRoomOptions$ = this.store.select(inventoryFeature.selectAddRoomOptions);

  editItemDialogVisible$ = this.store.select(inventoryFeature.selectEditItemDialogVisible);
  editItem$ = this.store.select(inventoryFeature.selectEditItem);
  editItemRoom$ = this.store.select(inventoryFeature.selectEditItemRoom);

  focus$ = this.store.select(inventoryFeature.selectFocus);
  hasChanges$ = this.store.select(jobToolFeature.selectChargesUpdates);

  InventoryActions = InventoryActions;

  constructor(
    public store: Store,
    public router: Router,
  ) { }

  private subs = new SubSink();

  ngOnInit(): void {
    this.store.dispatch(InventoryActions.inventoryPageInitialized());
  }
}
