import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEventService } from '@karve.it/features';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { take, map } from 'rxjs/operators';

import { BaseCalendarEventFragment, EditCalendarEventGQL, FullCalendarEventFragment } from '../../generated/graphql.generated';

import { JobEventStatus } from '../global.constants';
import { CalendarEvent } from '../interfaces/calendarEvents';
import { CompleteEventsConfirmationComponent } from '../shared/complete-events-confirmation/complete-events-confirmation.component';
import { currentTimeSeconds } from '../time';

import { DetailsHelperService } from './details-helper.service';
import { FreyaHelperService } from './freya-helper.service';
import { FreyaNotificationsService } from './freya-notifications.service';
import { ResponsiveHelperService } from './responsive-helper.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventHelperService {

  constructor(
    private eventService: CalendarEventService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private editCalendarEventGQL: EditCalendarEventGQL,
    private freyaHelper: FreyaHelperService,
    private responsiveHelper: ResponsiveHelperService,
    private router: Router,
  ) { }

  private bookEventSubject = new Subject<any>();

  bookEvent$ = this.bookEventSubject.asObservable();

  emitBookEvent(event: any) {
    this.bookEventSubject.next(event);
  }

  updateEventStatus(id: string, status: JobEventStatus){
    return new Promise((resolve, reject) => {
      this.eventService.editCalendarEvent({ids: [id], edit: {
        status
      }}).subscribe(() => {
        this.detailsHelper.pushUpdate({
          id,
          type:'Events',
          action:'update'
        });
        this.localNotify.success(`Event status set to ${status}`);
        resolve(true);
      }, (err) => {
        this.localNotify.apolloError(`Failed to update event status`,err);
        reject();
      });
    });
  }

  unscheduleEvent(event: FullCalendarEventFragment | CalendarEvent){
    this.confirmationService.confirm({
      header: 'Cancel Event?',
      message: `Cancelling this event will remove it from the schedule and remove any associated charges and discounts`,
      acceptLabel: 'Cancel this Event',
      acceptIcon: 'pi pi-ban',
      rejectLabel: `Don't Cancel this Event`,
      rejectIcon: 'pi pi-times',
      accept: () => {
          this.eventService.editCalendarEvent({
            ids: [event.id],
            edit: {
              status: 'cancelled',
            }
          }).subscribe(() => {
            this.localNotify.success('Event cancelled');
            this.detailsHelper.pushUpdate({
                id: event.id,
                type:'Events',
                action:'update',
                update: { status: 'cancelled' },
            });
          }, (err) => {
            this.localNotify.apolloError('Could not cancel event', err);
          });
      }
    });
  }

  uncancelEvent(event: FullCalendarEventFragment, navigateCommands?: any[]) {

    const message = `This will add the event back into the schedule, `
    + 'but it will not actually book the event nor check if there are any conflicting events scheduled for the same time. '
    + 'Please make sure there are no conflicting events, then book the event manually.';

    this.confirmationService.confirm({
      header: 'Undo Cancel?',
      message,
      acceptLabel: 'Undo Cancel',
      acceptIcon: 'pi pi-undo',
      rejectLabel: 'Nevermind',
      rejectIcon: 'pi pi-times',
      accept: () => {
          this.eventService.editCalendarEvent({
            ids: [event.id],
            edit: {
              status: 'pending',
            }
          }).subscribe(() => {

            this.localNotify.success('Cancellation undone');

            this.detailsHelper.pushUpdate({
                id: event.id,
                type:'Events',
                action:'update',
            });

            if (navigateCommands) {
                this.router.navigate(navigateCommands);
            };
        });
      }
    });
  }

  confirmCompleteEvents(events: BaseCalendarEventFragment[], prompt: string) {

    const incompleteEvents = this.getPastIncompleteEvents(events);

    if (!incompleteEvents.length) { return Promise.resolve({
      confirmed: false,
      incompleteEvents,
    }); }

    const ref = this.dialogService.open(CompleteEventsConfirmationComponent, {
      header: 'Mark Events as Complete?',
      contentStyle: this.freyaHelper.getDialogContentStyle('1.5rem'),
      width: this.responsiveHelper.dialogWidth,
      closable: false,
      data: {
        events: incompleteEvents,
        prompt,
      },
    });

    return ref.onClose.pipe(
      take(1),
      map((confirmed: boolean) => ({ confirmed, incompleteEvents }))
    ).toPromise();
  }

  getPastIncompleteEvents(events: FullCalendarEventFragment[]) {

    if (!events) {
      return [];
    }

    const now = currentTimeSeconds();

    return events.filter((e) => {

      const finished = e.start < now;

      const incomplete = e.status !== 'completed' && e.status !== 'required';

      return finished && incomplete;
    });
  }

  markConfirmed(events: BaseCalendarEventFragment[]) {

    if (!events?.length) { return; }

    const ids = events.map((e) => e.id);
    const edit = { status: 'confirmed' };
    return this.editCalendarEventGQL.mutate({ ids, edit }).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: ids[0],
        type:'Events',
        action:'update'
      });

      this.localNotify.success(`Event${ ids?.length > 1 ? 's' : '' } confirmed`);
    }, (err) => {
      this.localNotify.apolloError('Failed to mark events as confirmed', err);
    });

  }

  markComplete(events: BaseCalendarEventFragment[]) {

    if (!events?.length) { return; }

    const ids = events.map((e) => e.id);

    const edit = { status: 'completed' };

    return this.editCalendarEventGQL.mutate({ ids, edit }).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: ids[0],
        type:'Events',
        action:'update'
      });

      this.localNotify.success('Events marked as complete');
    }, (err) => {
      this.localNotify.apolloError('Failed to mark events as complete', err);
    });

  }

}
