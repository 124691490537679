import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  AddCommentInput,
  Asset,
  BaseArtifactFragment,
  BaseCommentFragment,
  BaseFieldFragment,
  BaseZoneFragment,
  CalendarEvent,
  CommentWithRepliesFragment,
  ConfigValue,
  CreateCalendarEventMutationVariables,
  EditProfileMutationVariables,
  FullJobFragment,
  FullTransactionFragment,
  InvoiceWithArtifactsFragment,
  Role,
  RouteDistances,
  SingleEditInput,
  TagsQuery,
  UpdateCommentInput,
  UpdateJobInput,
  User,
} from '../../generated/graphql.generated';
import { UserProfile } from '../interfaces/auth';

import { ViewableArtifact } from '../services/freya-helper.service';

import { Modes, UpdateJobsV2Input } from './job-tool.reducer';
import { CalendarEventWithLockedAndInvoicedFlag } from './jobv2-create/jobv2-interfaces';
export interface JobsV2UpdatedLocation {
  id: string;
  locationType: string;
  addressLineOne: string;
}

export interface JobsV2LocationsChange {
  [locationType: string]: JobsV2UpdatedLocation;
}

export const JobToolActions = createActionGroup({
  source: 'Job Tool',
  events: {
    'Params Set': props<{
      jobId: string;
      // The currently logged in user
      user: UserProfile;
      tab: {
        name: string;
        index: number;
      };
    }>(),
    'Job Timezone Changed': props<{
      jobTimeZone: string;
    }>(),
    'Tab Changed': props<{
      name: string;
      index: number;
    }>(),

    'Job Loading': emptyProps(),

    'Job Loaded': props<{
      job: FullJobFragment;
      comments: CommentWithRepliesFragment[];
      fields?: Partial<BaseFieldFragment>[];
      totalComments: number;
      documents: BaseArtifactFragment[];
      invoices: InvoiceWithArtifactsFragment[];
      transactions: FullTransactionFragment[];
    }>(),

    'Job Not Found Error': props<{
      jobId: string;
    }>(),

    'Job Load Error': props<{
      error: Error;
      jobId: string;
    }>(),

    'Load Job Configs': props<{ jobConfigsKeys: string[] }>(),
    'Load Job Configs Success': props<{ configValues: ConfigValue[] }>(),
    'Load Job Configs Error': props<{ error: Error }>(),

    // List Event
    'Event List Success': props<{ event: CalendarEvent }>(),
    'Event List Error': props<{
      error: Error;
    }>(),

    'Init Job Overview Page': emptyProps(),

    'Add Locked And Invoiced Flag To Events': props<{ events: CalendarEventWithLockedAndInvoicedFlag[] }>(),

    // 'commentId' is a temporary ID used to track this comment before
    // it is assigned an ID by the backend
    'Add Comment': props<{
      input: AddCommentInput;
      temporaryCommentId: string;
    }>(),
    'Add Comment Success': props<{
      comment: CommentWithRepliesFragment;
      commentId: string;
    }>(),
    'Add Comment Error': props<{
      error: Error;
      input: AddCommentInput;
      commentId: string;
    }>(),
    'Comment Loaded': props<{ comment: CommentWithRepliesFragment }>(),
    'Comment Load Error': props<{ error: Error; commentId: string }>(),

    'Update Comment': props<{ input: UpdateCommentInput }>(),
    'Update Comment Success': props<{ comment: CommentWithRepliesFragment }>(),
    'Update Comment Error': props<{
      error: Error;
      input: UpdateCommentInput;
    }>(),

    'Delete Comment': props<{ comment: BaseCommentFragment }>(),
    'Delete Comment Success': props<{ comment: BaseCommentFragment }>(),
    'Delete Comment Error': props<{
      error: Error;
      comment: BaseCommentFragment;
    }>(),

    'Inventory Loading': emptyProps(),
    'Inventory Loaded': props<{
      inventory?: Partial<BaseFieldFragment>[];
    }>(),
    'Inventory Load Error': props<{
      error: Error;
    }>(),

    // Job Overview tab actions
    // TODO: [HIGH_PRIORITY] This is a duplicate one in jobsv2-timeline-availability-state.effects.ts
    // TODO: [HIGH_PRIORITY] Merge them once the overview-tool and job-creation-tool are merged
    'Calculate Distance Success': props<{distances: RouteDistances}>(),
    'Calculate Distance Error': props<{
      error: Error;
    }>(),

    // Job Agent actions
    'Job Agent Mode Changed to Edit': emptyProps(),

    // List Roles
    'Roles Loading': emptyProps(),
    'Roles Loaded': props<{
      roles: Role[];
    }>(),
    'Roles Load Error': props<{
      error: Error;
    }>(),

    // List Employees
    'Employee Searched': props<{
      employeeSearch: string;
    }>(),
    'Employees Loading': emptyProps(),
    'Employees Loaded': props<{
      employees: User[];
    }>(),
    'Employees Load Error': props<{
      error: Error;
    }>(),

    // List Tags
    // TODO: [HIGH_PRIORITY] There is a duplicate one in jobsv2-edit-state.effects.ts
    'Tag Searched': props<{
      tagSearch: string;
    }>(),
    'Tags Loading': emptyProps(),
    'Tags Loaded': props<{
      tags: TagsQuery['tags']['tags'];
    }>(),
    'Tags Load Error': props<{
      error: Error;
    }>(),

    'Tag Assigned To Job': props<{
      tags: TagsQuery['tags']['tags'];
      originalTags: TagsQuery['tags']['tags'];
    }>(),
    'Tag Assignment Success': props<{ isSuccess: boolean }>(),
    'Tag Assignment Error': props<{
      error: Error;
      tags: TagsQuery['tags']['tags'];
      originalTags: TagsQuery['tags']['tags'];
    }>(),

    // Update
    // TODO: [HIGH_PRIORITY] There is a duplicate one in jobsv2-edit-state.effects.ts
    'Job Agent Changed': props<{
      updateJobInput: UpdateJobsV2Input;
    }>(),
    'Job Agent Change Success': props<{ isSuccess: boolean }>(),
    'Job Agent Change Error': props<{
      error: Error;
      updateJobInput: UpdateJobsV2Input;
    }>(),

    'Update Customer': props<{ customerEditInput: EditProfileMutationVariables }>(),
    'Update Customer Success': props<{ customerEditInput: EditProfileMutationVariables }>(),
    'Update Customer Error': props<{ error: Error }>(),

    'Update Job Stage': props<{
      updateJobInput: UpdateJobInput,
    }>(),

    'Update Job Stage Error': props<{
      error: Error;
      currentStage: string;
      updateJobInput: UpdateJobInput,
    }>(),

    'Update Job Stage Success': props<{
      isSuccess: boolean;
    }>(),

    'Update Job Area Success': props<{
      isSuccess: boolean,
      jobId: string,
    }>(),

    'Update Job Area Error': props<{
      error: Error;
    }>(),

    'Job Update Requested': emptyProps(),
    'Job Update Success': props<{
      updateJobInput: UpdateJobInput,
      locationsToAdd?: JobsV2LocationsChange, locationsToRemove?: JobsV2LocationsChange,
      zone?: BaseZoneFragment,
    }>(),
    'Job Update Error': props<{
      error: Error;
    }>(),

    'Job Deleted Requested':props<{
      jobId: string;
    }>(),

    'Job Deleted Success': props<{
      jobId: string;
    }>(),

    'Job Deleted Error': props<{
      error: Error;
    }>(),

    // List Assets
    'Asset Searched': props<{
      assetSearch: string;
    }>(),
    'Assets Search Loading': emptyProps(),
    'Assets Search Loaded': props<{
      assets: Asset[];
    }>(),
    'Assets Search Error': props<{
      error: Error;
    }>(),

    // List Crew
    'Crew Searched': props<{
      crewSearch: string;
    }>(),
    'Searched Crew Loading': emptyProps(),
    'Searched Crew Loaded': props<{
      crew: User[];
    }>(),
    'Searched Crew Error': props<{
      error: Error;
    }>(),

    // Create Event
    'Event Creation Requested': props<{
      createEventsInput: CreateCalendarEventMutationVariables;
    }>(),
    'Event Creation Success': props<{ isSuccess: boolean, events: CalendarEvent[] }>(),
    'Event Creation Error': props<{
      error: Error;
    }>(),

    // Update Event
    'Event Update Requested': props<{
      edits: SingleEditInput[];
    }>(),
    'Event Update Success': props<{
      updatedEvents: CalendarEvent[];
      retrieveSchedule?: boolean,
    }>(),
    'Event Update Error': props<{
      error: Error;
    }>(),

    'Event Deletion Requested': props<{
      eventId: string;
    }>(),
    'Event Deletion Success': props<{
      eventId: string;
    }>(),
    'Event Deletion Error': props<{
      error: Error;
    }>(),

    'Summary InPlace Editing Activated': emptyProps(),
    'Customer Details InPlace Editing Activated': emptyProps(),
    'Location Details InPlace Editing Activated': emptyProps(),

    // NoOp action - this action does nothing
    'No Op': emptyProps(),
    'Send Estimate Button Clicked': props<{
      estimate: BaseArtifactFragment,
    }>(),
    'Send Estimate Success': props<{
      estimate: BaseArtifactFragment,
    }>(),
    'Send Estimate Error': props<{
      error: Error,
      estimate: BaseArtifactFragment,
    }>(),
    'Send Invoice Button Clicked': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Send Invoice Success': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Send Invoice Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Finalize Invoice Button Clicked': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Finalize Invoice Success': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Finalize Invoice Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Forced Update Invoice Button Clicked': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Forced Update Invoice Success': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Forced Update Invoice Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Open Invoice As Button Clicked': props<{
      invoice: InvoiceWithArtifactsFragment,
      role: 'customer' | 'employee',
    }>(),
    'Open Invoice As Success': props<{
      invoice: InvoiceWithArtifactsFragment,
      role: 'customer' | 'employee',
    }>(),
    'Open Invoice As Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
      role: 'customer' | 'employee',
    }>(),
    'Void Invoice Button Pressed': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Void Invoice Success': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Void Invoice Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Delete Invoice Button Pressed': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Delete Invoice Success': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Delete Invoice Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Export Invoice to Quickbooks Button Pressed': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Export Invoice to Quickbooks Success': props<{
      invoice: InvoiceWithArtifactsFragment,
      quickbooksId: string,
    }>(),
    'Export Invoice to Quickbooks Error': props<{
      error: Error,
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Payment Applied to Invoice': props<{
      jobId: string,
    }>(),
    'Generate Job Document Success': props<{
      document: BaseArtifactFragment,
      jobId: string,
    }>(),
    'Delete Document Success': props<{
      documentId: string,
    }>(),
    'Create Invoice Success': props<{
      invoice: InvoiceWithArtifactsFragment,
    }>(),
    'Create Invoice Error': props<{
      error: Error,
    }>(),
    'Invoice Ready To View': props<{
      jobId: string,
    }>(),
    'View Receipt Button Clicked': props<{
      artifactReceiptId: string,
    }>(),
    'Update Transaction Success': props<{
      transaction: FullTransactionFragment,
    }>(),
    'Update Transaction Error': props<{
      error: Error,
    }>(),
    'Create Transaction Success': props<{
      jobId: string;
    }>(),
    'Create Transaction Error': props<{
      error: Error,
      jobId: string,
    }>(),
    'Delete Transaction Success': props<{
      jobId: string,
    }>(),
    'Delete Transaction Error': props<{
      error: Error,
    }>(),
    'Get Invoice Details Success': props<{
      artifact: ViewableArtifact, documentType: string,
    }>(),
    'Get Invoice Details Error': props<{
      error: Error,
    }>(),

    'Close Existing Job': props<{
      jobId: string,
      closedReason: string,
    }>(),
    'Close Existing Job Success': props<{ closedAt: number }>(),
    'Close Existing Job Error': props<{
      error: Error,
    }>(),

    'Reopen Existing Job': props<{
      jobId: string,
    }>(),
    'Reopen Existing Job Success': emptyProps(),
    'Reopen Existing Job Error': props<{
      error: Error,
    }>(),
  },
});
