import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, createSelector } from '@ngrx/store';

import { MenuItem } from 'primeng/api';
import { withLatestFrom } from 'rxjs';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { SubSink } from 'subsink';

import { FullTransactionFragment, TransactionStage } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { SharedModule } from '../../shared/shared.module';
import { TagColor } from '../job-estimate/job-estimate.component';
import { JobInvoiceComponent } from '../job-invoice/job-invoice.component';
import { JobToolActions } from '../job-tool.actions';
import { FullJobFragmentWithFields, jobToolFeature } from '../job-tool.reducer';
import { JobTransactionComponent } from '../job-transaction/job-transaction.component';

@Component({
  selector: 'app-job-financials',
  standalone: true,
  imports: [
    CommonModule,
    FreyaCommonModule,
    SharedModule,
    JobInvoiceComponent,
    JobTransactionComponent,
  ],
  templateUrl: './job-financials.component.html',
  styleUrls: [
    './job-financials.component.scss',
    './document-container-style.scss',
  ],
})
export class JobFinancialsComponent implements OnInit {
  job$ = this.store.select(jobToolFeature.selectJob);

  customer$ = this.store.select(jobToolFeature.selectCustomer);

  jobLoaded$ = this.store.select(jobToolFeature.jobLoaded); 

  latestInvoice$ = this.store.select(jobToolFeature.selectLatestInvoice);

  olderInvoices$ = this.store.select(jobToolFeature.selectOlderInvoices);

  transactions$ = this.store.select(jobToolFeature.selectTransactions);

  invoicesEmpty$ = this.store.select(createSelector(
    jobToolFeature.selectInvoices,
    jobToolFeature.jobLoaded,
    (invoices, jobLoaded) => jobLoaded && invoices.length === 0,
  ));

  transactionsEmpty$ = this.store.select(createSelector(
    jobToolFeature.selectTransactions,
    jobToolFeature.jobLoaded,
    (transactions, jobLoaded) => jobLoaded && transactions.length === 0,
  ));

  job: FullJobFragmentWithFields | undefined = undefined;

  customer: FullJobFragmentWithFields['users'][number]['user'];

  transactionActions: MenuItem[] =[];

  showMoreInvoices = false;

  colorMap: Record<TransactionStage | 'deleted', TagColor> ={
    [TransactionStage.Paid]: { backgroundColor: '#E3FBE3', text: '#0A470A' },
    [TransactionStage.Pending]: { backgroundColor: '#F0F4F8', text: '#32383E' },
    [TransactionStage.Cancelled]: { backgroundColor: '#FCE4E4', text: '#7D1212' },
    deleted: { backgroundColor: '#FCE4E4', text: '#7D1212' },
  }

  constructor(
    public store: Store,
    private route: ActivatedRoute,
    public router: Router,
    private mutateService: FreyaMutateService,
    private documentHelper: DocumentHelperService,
    private detailsHelper: DetailsHelperService,
  ) { }

  private subs = new SubSink();

  ngOnInit(): void {
    this.subs.sink = this.job$.pipe(withLatestFrom(this.customer$)).subscribe(([ job, customer ]) => {
      this.customer = customer;
      this.job = job;
    });
  }

  openLegacyClick() {
    const jobId = this.job?.id;
    if (!jobId) return;

    const redirectTo = `/estimating/${jobId}`;
      this.router.navigate([redirectTo], {
        relativeTo: this.route,
        queryParams: {
          step: 'confirmation',
        },
      });
  }

  setTransactionActions(transaction: FullTransactionFragment) {

    const artifactReceiptId = transaction?.metadata?.artifactReceiptId;

    const transactionDeleted = Boolean(transaction.deletedAt);

    this.transactionActions = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.mutateService.openMutateObject({
          objectType: 'transaction',
          mutateType: 'update',
          object: transaction,
        }),
        disabled: transactionDeleted,
      },
      {
        label: 'View Receipt',
        icon: 'pi pi-eye',
        disabled: !artifactReceiptId,
        command: () => this.store.dispatch(JobToolActions.viewReceiptButtonClicked({ artifactReceiptId })),
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.mutateService.openDeleteObject({
          objectId: transaction.id,
          objectName: `this ${transaction.type}`,
          objectType: 'transaction',
          customTitle: 'Delete Transaction',
          additionalValues: [{
            property: 'jobId',
            value: this.job?.id,
          }],
        }),
        disabled: transactionDeleted,
      },
    ];
  }

  openCreateInvoiceDialog() {
    this.documentHelper.openCreateInvoiceDialog(this.job?.id);
  }

  openCreateTransactionDialog() {
    this.mutateService.openMutateObject({
      objectType: 'transaction',
      mutateType: 'create',
      additionalValues: [
        {
          property: 'job',
          value: this.job,
        },
        {
          property: 'user',
          value: this.customer,
        }
      ],
    });
  }

  openInvoiceDetails(invoiceId: string) {
    this.detailsHelper.detailsItem.next({ type: 'invoice', item: { id: invoiceId } });
  }
}
