import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { DiscountsGQL, DiscountsQueryVariables, Expense, ListExpensesGQL, ListProductsForEstimatingGQL, ListProductsForEstimatingQueryVariables, ListTaxesGQL, Tax, UpdateChargesGQL, UpdateJobGQL, UpdateJobMutationVariables, ZoneDir } from "graphql.generated";
import { catchError, filter, map, from, of, switchMap, tap, withLatestFrom, distinctUntilChanged } from "rxjs";

import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";
import { ProductHelperService } from "src/app/services/product-helper.service";

import { InventoryActions } from '../../job-inventory/inventory.actions';
import { JobToolActions } from "../../job-tool.actions";
import { jobToolFeature } from "../../job-tool.reducer";


import { generateAddDiscountsInput, generateCreateEventsInput, generateEditEventsInput, generateFieldsInput, generateNewChargesInput, generateRemoveChargesInput, generateRemoveDiscountsInput, generateRemoveEventsInput, generateUpdateChargesInput, getExistingChargesWithUnsavedChanges, getUnsavedChargesWithUpdates } from "../../jobsv2-charges-helpers";

import { WorkOrdersActions } from "./workorders.actions";

//load products, discounts, expenses and taxes every time when state?.job?.id changed
export const triggerLoadProductsOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    map(() => WorkOrdersActions.productsLoading())
  );
}, { functional: true, dispatch: true });

export const triggerLoadAvailableDiscountsOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    map(() => WorkOrdersActions.availableDiscountsLoading())
  );
}, { functional: true, dispatch: true });

export const loadTaxesOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);
  const listTaxesGQL = inject(ListTaxesGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() =>
      listTaxesGQL.fetch({}).pipe(
        map((res) => {
          if (res.loading) return WorkOrdersActions.noop();
          const taxes = res.data?.taxes?.taxes || [];
          return WorkOrdersActions.taxesLoaded({ taxes: taxes as Tax[] });
        })
      )
    )
  );
}, { functional: true, dispatch: true });

export const loadExpensesOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);
  const listExpensesGQL = inject(ListExpensesGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() =>
      listExpensesGQL.fetch({}).pipe(
        map((res) => {
          if (res.loading) return WorkOrdersActions.noop();
          const expenses = res.data?.expenses?.expenses || [];
          return WorkOrdersActions.expensesLoaded({ expenses: expenses as Expense[] });
        })
      )
    )
  );
}, { functional: true, dispatch: true });


export const loadProductsEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const listProductsGQL = inject(ListProductsForEstimatingGQL);
  const productHelper = inject(ProductHelperService);


  return actions$.pipe(
    ofType(WorkOrdersActions.productsLoading),
    switchMap(() => {
      const listProductQueryVariables: ListProductsForEstimatingQueryVariables = {
        limit: 200,
        skip: 0,
        filter: {
          hasActivePrice: true,
          zoneDir: ZoneDir.Gte,
        },
        sort: 'name:ASC',
        pricesFilter: {
          isActive: true,
        }
      };

      return from(listProductsGQL.fetch(listProductQueryVariables)).pipe(
        map(response => {
          const products = response.data.products.products || [];


          let filteredProducts = productHelper.filterOutProductsWithInactivePrices(products);


          filteredProducts = filteredProducts.sort((prodA, prodB) => {
            if (!prodA.category) {
              return 1;
            }
            return prodA.category.localeCompare(prodB?.category || '');
          });


          return WorkOrdersActions.productsLoadedSuccess({ products: filteredProducts });
        }),
        catchError(error =>
          of(WorkOrdersActions.productsLoadedError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });

export const loadAvailableDiscountsEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const discountsGQL = inject(DiscountsGQL);

  return actions$.pipe(
    ofType(WorkOrdersActions.availableDiscountsLoading),
    switchMap(() => {
      const listDiscountsQueryVariables: DiscountsQueryVariables = {
        filter: {
          applyable: true,
          singleUse: false,
        },
        limit: -1,
      };

      return from(discountsGQL.fetch(listDiscountsQueryVariables)).pipe(
        map(response => {
          const discounts = response.data.discounts.discounts || [];

          return WorkOrdersActions.availableDiscountsLoadedSuccess({ discounts });
        }),
        catchError(error =>
          of(WorkOrdersActions.availableDiscountsLoadedError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });

export const saveChangesEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const updateJobGQL = inject(UpdateJobGQL);
  const store = inject(Store);
  const localNotify = inject(FreyaNotificationsService);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.saveButtonClicked,
      InventoryActions.saveButtonClicked,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectChargesUpdates),
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectProducts),
      store.select(jobToolFeature.selectUser),
    ),
    switchMap(([action, chargesUpdates, job, products, user]) => {

      //charges that are not created yet, with latest updates
      const addedChargesWithUpdates = getUnsavedChargesWithUpdates(chargesUpdates);
      const newChargesInput = generateNewChargesInput(user?.id, products, addedChargesWithUpdates, job.currency);

      //already created charges that should be updated
      const existingChargesNeedUpdate = getExistingChargesWithUnsavedChanges(chargesUpdates, job);
      const chargesToUpdateInput = generateUpdateChargesInput(existingChargesNeedUpdate);

      //created charges to be removed
      const chargesToRemoveInput = generateRemoveChargesInput(chargesUpdates);

      //existing discounts to be removed
      const discountsToRemoveInput = generateRemoveDiscountsInput(chargesUpdates);

      //existing discounts to be added
      const { existingDiscountsToAdd, newDiscountsToCreate } = generateAddDiscountsInput(chargesUpdates);

      //reordered and cancelled events
      const editEventsInput = generateEditEventsInput(chargesUpdates);

      //deleted events
      const deleteEventsInput = generateRemoveEventsInput(chargesUpdates);

      //created events
      const createEventsInput = generateCreateEventsInput(chargesUpdates);

      const fieldsInput = generateFieldsInput(chargesUpdates);

      const updateJobQueryVariables: UpdateJobMutationVariables = {
        updateJobs: [{
          jobId: job?.id,
          ...(chargesToUpdateInput?.charges?.length > 0 && { updateCharges: chargesToUpdateInput}),
          ...(newChargesInput?.charges?.length > 0 && { newCharges: newChargesInput }),
          ...(chargesToRemoveInput?.length > 0 && { removeCharges: chargesToRemoveInput }),
          ...(discountsToRemoveInput?.length > 0 && { removeDiscounts: discountsToRemoveInput }),
          ...(existingDiscountsToAdd?.length > 0 && { addDiscounts: existingDiscountsToAdd }),
          ...(newDiscountsToCreate?.eventsIds?.length > 0 && { createDiscounts: newDiscountsToCreate }),
          ...(editEventsInput?.edits?.length > 0 && { editCalendarEvents: editEventsInput }),
          ...(deleteEventsInput?.length > 0 && { removeCalendarEvents: deleteEventsInput }),
          ...(createEventsInput?.length > 0 && { addEvents: createEventsInput }),
          ...(fieldsInput?.length > 0 && {
            fields: {
              objectLabel: 'Job',
              fields: fieldsInput,
            }
          }),
        }]
      };

      return updateJobGQL.mutate(updateJobQueryVariables).pipe(
        map((result) => {
          const job = result?.data?.updateJobs?.jobs[0];
          const fields = result?.data?.updateJobs?.fields;
          localNotify.addToast.next({severity: 'success', summary: 'Job saved'});
          return WorkOrdersActions.changesSavedSuccess({ job, fields });
        }),
        catchError((error) => {
          // Handle error and dispatch error action
          localNotify.error(`Cannot save job changes. Error occurred: ${error}`);
          return of(WorkOrdersActions.changesSavedError({ error }));
        })
      );
    })
  );
}, { functional: true, dispatch: true });

// local storage
export const saveUnsavedChangesToLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  return actions$.pipe(
    ofType(
      WorkOrdersActions.productForAddingSelected,
      WorkOrdersActions.productsForAddingSubmitted,
      WorkOrdersActions.existingChargesUpdated,
      WorkOrdersActions.updateChargesOrder,
      WorkOrdersActions.reorderEvents,
      WorkOrdersActions.removeCharge,
      WorkOrdersActions.addDiscount,
      WorkOrdersActions.createSingleUseDiscount,
      WorkOrdersActions.createCustomCharge,
      WorkOrdersActions.removeDiscount,
      WorkOrdersActions.reorderEvents,
      WorkOrdersActions.cancelEvent,
      WorkOrdersActions.deleteEvent,
      WorkOrdersActions.createEvent,
      WorkOrdersActions.duplicateEvent,
      WorkOrdersActions.cancelButtonClicked,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectChargesUpdates)
    ),
    tap(([action, job, chargesUpdates]) => {

      const existingChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');

      const customer = job?.users?.find(u => u.role === 'customer').user;

      const updatedChanges = {
        ...existingChanges,
        [job.id]: {
          jobCode: job?.code,
          jobCustomer: (customer.givenName || '') + ' ' + (customer.familyName || ''),
          changes: chargesUpdates
        }
      };

      localStorage.setItem('workorders_unsaved_changes', JSON.stringify(updatedChanges));
    })
  );
}, { functional: true, dispatch: false });

export const loadUnsavedWorkOrdersChangesFromLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);

  return actions$.pipe(
    ofType(JobToolActions.jobLoaded),
    withLatestFrom(store.select(jobToolFeature.selectJob)),
    map(([action, job]) => {
      const unsavedChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');
      const jobUnsavedChanges = unsavedChanges[job?.id];

      return jobUnsavedChanges ? jobUnsavedChanges : null;
    }),
    filter(jobUnsavedChanges => !!jobUnsavedChanges),
    map(jobUnsavedChanges =>
      WorkOrdersActions.applyUnsavedChangesFromLS({
        unsavedChanges: jobUnsavedChanges.changes
      })
    )
  );
}, { functional: true, dispatch: true });

export const RemoveSavedChangesFromLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.changesSavedSuccess,
    ),
    withLatestFrom(store.select(jobToolFeature.selectJob)),
    map(([action, job]) => {
      const unsavedChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');

      if (job?.id && unsavedChanges[job.id]) {
        delete unsavedChanges[job.id];

        localStorage.setItem('workorders_unsaved_changes', JSON.stringify(unsavedChanges));
      }
    })
  );
}, { functional: true, dispatch: false });

//unsaved changes toasts
export const generateUnsavedChangesRemindersEffect = createEffect(() => {
  const actions$ = inject(Actions);

  return actions$.pipe(
    ofType(JobToolActions.jobLoaded),
    map(() => {
      const unsavedChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');

      const unsavedChangesToastsInfo = Object.keys(unsavedChanges).map(jobId => {
        const { jobCode, jobCustomer } = unsavedChanges[jobId];
        return { jobId, jobCode, jobCustomer };
      });

      return WorkOrdersActions.generateUnsavedChangesToasts({ unsavedChangesToastsInfo });
    })
  );
}, { functional: true, dispatch: true });