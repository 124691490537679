<p-button
    *ngIf="action === 'create-close'"
    [outlined]="true"
    (click)="createClose()"
    label="Create & Close"
    [disabled]="isButtonDisabled()"
    [pTooltip]="isButtonDisabled() ? savingDisabledMessage : null">
</p-button>
<p-button
    *ngIf="action === 'create'"
    (click)="create()"
    label="Create Job"
    class="create-btn"
    [icon]="jobSaving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    [disabled]="isButtonDisabled() || (jobSaving$ | async)"
    [pTooltip]="isButtonDisabled() ? savingDisabledMessage : null"
></p-button>
<p-button
    *ngIf="action === 'update'"
    (click)="updateJob()"
    label="Update Job"
    [icon]="jobUpdating ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    [disabled]="isButtonDisabled()"
    [pTooltip]="isButtonDisabled() ? savingDisabledMessage : null">
</p-button>