import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApolloError } from '@apollo/client/core';
import { EMPTY, Observable, catchError, distinctUntilChanged, from, map, of, switchMap, withLatestFrom } from "rxjs";
import { ScheduleEventsActions } from "./event-schedule.actions";
import { calculateEventTime, getTimingForQuery } from "../../jobsv2-events-helpers";
import { jobToolFeature } from "../../job-tool.reducer";
import { Store } from "@ngrx/store";
import { eventScheduleSelectors } from "./event-schedule.selectors";
import { CONFIGS_KEYS, eventTypeInfoMapV2 } from "src/app/global.constants";
import { AssetsGQL, AvailabilityGQL, BulkEditCalendarEventGQL, BulkEditCalendarEventMutationVariables, CalendarEvent, FindGQL, FindQueryVariables, LockWindowGQL, LockWindowMutationVariables, ScheduleEventsGQL, ZoneDir } from "graphql.generated";
import { convertCalendarFormatToStandard } from "src/app/time";
import { EstimateHelperService } from "src/app/services/estimate-helper.service";
import { FreyaHelperService } from "src/app/services/freya-helper.service";
import { AssetService } from "@karve.it/features";
import { capitalize, flatten } from "lodash";
import { strToTitleCase } from "src/app/js";
import { YemboHelperService } from "src/app/services/yembo-helper.service";
import { ConfirmationService } from "primeng/api";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";
import { parseGraphqlErrors } from "src/app/utilities/errors.util";
import { firstInitialLastName } from "src/app/users/users.utils";
import { getEventCustomer } from "src/app/shared/event-location/calendarevent.util";
import { JobToolActions } from "../../job-tool.actions";

export const loadAssetsEffect = createEffect(() => {
  const store = inject(Store);
  const assetsGQL = inject(AssetsGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() => {
      return from(assetsGQL.fetch({
        filter: {
          zoneDir: ZoneDir.Any,
        },
        limit: -1,
      })).pipe(
        map(response => {
          const assets = response?.data?.assets?.assets || [];

          return ScheduleEventsActions.assetsLoadingSuccess({ assets });
        }),
        catchError(error =>
          of(ScheduleEventsActions.assetsLoadingError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });


export const calculateEventTimeEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);

  return actions$.pipe(
    ofType(
      ScheduleEventsActions.bookEventScreenOpened,
      ScheduleEventsActions.startEndDockIncluded,
    ),
    map((action) => ({
      eventId: action?.eventId,
    })),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(eventScheduleSelectors?.selectConfigs),
      store.select(eventScheduleSelectors?.selectStartToDockIncluded),
      store.select(eventScheduleSelectors?.selectDockToEndIncluded),
    ),
    switchMap(([action, job, configs, startToDock, dockToEnd]) => {

      const eventType = job?.events?.find(e => e.id === action?.eventId)?.type;
      const estimatingLength = configs[CONFIGS_KEYS.estimatingLength]
        ? Number(configs[CONFIGS_KEYS.estimatingLength]) : undefined;
      const voseLength = configs[CONFIGS_KEYS.virtualEstimateLength]
        ? Number(configs[CONFIGS_KEYS.virtualEstimateLength]) : undefined;

      const eventTimeFromConfig = {
        estimating: estimatingLength,
        virtualEstimate: voseLength,
      }

      const result = calculateEventTime(
        job,
        eventType,
        startToDock,
        dockToEnd,
        eventTimeFromConfig
      );

      return of(ScheduleEventsActions.eventInfoUpdated({ updatedEventInfo: result }));
    })
  );
}, { functional: true, dispatch: true });

export const eventDateSelectedEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const findGQL = inject(FindGQL);
  const store = inject(Store);
  const estimateHelper = inject(EstimateHelperService);
  const freyaHelperService = inject(FreyaHelperService);

  return actions$.pipe(
    ofType(
      ScheduleEventsActions.dateSelected,
      ScheduleEventsActions.rescheduleButtonClicked,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(eventScheduleSelectors?.selectTotalTime)
    ),
    switchMap(([action, job, totalTime]) => {
      const { date, eventType } = action;
      const formattedDate = convertCalendarFormatToStandard(date);

      let excludedEventIds = [];

      if ('currentEventIdToExclude' in action) {
        excludedEventIds = [action?.currentEventIdToExclude];
      }

      const queryVariables: FindQueryVariables = {
        timeWindow: {
          startDate: formattedDate,
          endDate: formattedDate,
          minWindowLength: totalTime,
        },
        minNumAssets: 1,
        assetsFilter: {
          types: eventTypeInfoMapV2[eventType]?.assetTypes || [],
        },
        overrideUnavailabilities: !estimateHelper.restrictionsEnabled.value,
        zoneInput: job.zone.id,
        ...(excludedEventIds && excludedEventIds.length > 0 ? { excludedEventIds } : {}),
      };

      return from(findGQL.fetch(queryVariables)).pipe(
        switchMap((response) => {
          const windows = response?.data?.find?.windows || [];
          const avails = [];
          let hasLockedWindows = false;

          for (const window of windows) {
            if (freyaHelperService.lockDate > window.end) {
              hasLockedWindows = true;
              continue;
            }
            avails.push(...window.startTimes);
          }

          const possibleWindows = windows;
          const possibleTimes = [...new Set(avails)];

          return of(ScheduleEventsActions.findAvailabilitySuccess({
            startTimes: possibleTimes,
            possibleWindows,
            hasLockedWindows,
          }));
        }),
        catchError((error) =>
          of(ScheduleEventsActions.findAvailabilityError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });

export const getAvailableAssetsBasedOnTimeEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const assetService = inject(AssetService);
  const estimateHelper = inject(EstimateHelperService);

  return actions$.pipe(
    ofType(ScheduleEventsActions.timeSelected),
    withLatestFrom(
      store.select(eventScheduleSelectors.selectPossibleWindows),
      store.select(jobToolFeature.selectJob),
    ),
    switchMap(([action, possibleWindows, job]) => {
      let assets: any[] = [];
      let availableAssets: any[] = [];

      // If disabled restrictions, availability doesn't matter
      // TO DO check and implement presetting assets when restrictions disabled
      if (!estimateHelper.restrictionsEnabled.value) {
        return assetService.listAssets({}).pipe(
          map((res) => {
            availableAssets = res.data.assets.assets;
            return ScheduleEventsActions.availableAssetsSet({ availableAssets });
          })
        );
      } else {
        assets = possibleWindows
          .filter((w) => w.startTimes?.includes(action.time))
          .map((w) => w.assets);

        const assetList = flatten(assets);
        const assetIds = new Set(assetList.map((a) => a.id));

        assetIds.forEach((id) => {
          const asset = assetList.find((asset) => asset.id === id);
          if (asset) {
            availableAssets.push(asset);
          }
        });

        return of(ScheduleEventsActions.availableAssetsSet({ availableAssets }));
      }
    })
  );
}, { functional: true, dispatch: true });

export const initiateEventBookingEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const estimateHelper = inject(EstimateHelperService);
  const yemboHelper = inject(YemboHelperService);
  const assetService = inject(AssetService);
  const confirmationService = inject(ConfirmationService);
  const lockWindowGQL = inject(LockWindowGQL);
  const localNotify = inject(FreyaNotificationsService);

  return actions$.pipe(
    ofType(ScheduleEventsActions.bookButtonClicked),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectEventBookingData),
      store.select(eventScheduleSelectors?.selectConfigs),
    ),
    switchMap(([action, job, bookingData, configs]) => {
      const event = job?.events?.find(e => e.id === action?.eventId);

      return assetService.listAssets({}).pipe(
        switchMap((res) => {
          const selectedAssets = bookingData?.eventInput?.selectedAssets;
          const selectedAsset = res.data.assets.assets.find(a => a.id === selectedAssets[0]);
          const assetHasYemboEmail = Boolean(selectedAsset?.metadata?.yemboEmail);

          if (yemboHelper.yemboEnabled && event?.type === 'virtualEstimate' && !assetHasYemboEmail) {
            return new Observable(observer => {
              confirmationService.confirm({
                header: 'No Yembo Email',
                message: 'This asset does not have a Yembo email configured. Smart Consult will be booked for the default admin email.',
                accept: () => {
                  observer.next('accepted');
                  observer.complete();
                },
                reject: () => {
                  observer.error('cancelled');
                },
                acceptLabel: 'Continue',
                rejectLabel: 'Cancel',
              });
            }).pipe(
              switchMap(() => triggerLockWindowQuery(job, event, bookingData, configs, estimateHelper, lockWindowGQL, localNotify)),
              catchError(() => EMPTY)
            );
          }

          return triggerLockWindowQuery(job, event, bookingData, configs, estimateHelper, lockWindowGQL, localNotify);
        })
      );
    })
  );
}, { functional: true, dispatch: true });

function triggerLockWindowQuery(
  job: any,
  event: any,
  bookingData: any,
  configs: any,
  estimateHelper: EstimateHelperService,
  lockWindowGQL: LockWindowGQL,
  localNotify
) {
  const estimatingLength = configs[CONFIGS_KEYS.estimatingLength]
    ? Number(configs[CONFIGS_KEYS.estimatingLength])
    : undefined;

  const voseLength = configs[CONFIGS_KEYS.virtualEstimateLength]
    ? Number(configs[CONFIGS_KEYS.virtualEstimateLength])
    : undefined;

  const startToDock = bookingData?.eventInput?.includeStartDock;
  const dockToEnd = bookingData?.eventInput?.includeEndDock;
  const selectedStartTime = bookingData?.eventInput?.selectedStartTime;

  const eventTimeFromConfig = {
    estimating: estimatingLength,
    virtualEstimate: voseLength,
  };

  const result = calculateEventTime(
    job,
    event?.type,
    startToDock,
    dockToEnd,
    eventTimeFromConfig
  );

  const timingForQuery = getTimingForQuery(result, selectedStartTime);

  //in case that location was not set
  //eg mover doesn't have dock
  const filteredLocations = result.locations.filter(l => l.locationId);

  const locationsForQuery = filteredLocations.map(location => ({
    estimatedTimeAtLocation: location.estimatedTimeAtLocation,
    locationId: location.locationId,
    order: location.order,
    travelTimeToNextLocationOffset: location.travelTimeToNextLocationOffset,
    type: location.type
  }));

  const lockQueryInput = {
    assetIds: bookingData?.eventInput?.selectedAssets,
    type: event?.type,
    title: event?.title || strToTitleCase(event?.type),
    start: bookingData?.eventInput?.selectedStartTime,
    end: timingForQuery?.end,
    jobId: job?.id,
    updateEventSequentialOrder: true,
    eventId: event?.id,
    attributes: [event?.type],
    locations: locationsForQuery,
    overrideAvailabilities: !estimateHelper.restrictionsEnabled.value,
    overrideUnavailabilities: !estimateHelper.restrictionsEnabled.value,
    modifyStartForTravel: startToDock,
    modifyEndForTravel: dockToEnd,
  } as LockWindowMutationVariables;

  return from(
    lockWindowGQL.mutate(lockQueryInput, {
      context: {
        headers: {
          'x-zone': job.zone.id,
        },
      },
    })
  ).pipe(
    switchMap((response) => {
      localNotify.addToast.next({
        severity: 'success',
        summary: 'Event booked',
      });
      return of(ScheduleEventsActions.bookEventSuccess({
        updatedEvent: response?.data?.lockWindow as unknown as CalendarEvent }));
    }),
    catchError((error) => {

      if (error instanceof ApolloError) {
        localNotify.apolloError(`Apollo Error booking event`, error);
      } else {
        localNotify.addToast.next({
          severity: 'error',
          summary: 'Error booking event',
          detail: error,
        });
      }

      return of(ScheduleEventsActions.bookEventError({ error }));
    })
  );
}

export const calendarAvailabilityRequestedEffect = createEffect(() => {
  const store = inject(Store);
  const actions$ = inject(Actions);
  const availabilityGQL = inject(AvailabilityGQL);

  return actions$.pipe(
    ofType(
      ScheduleEventsActions.dateSelected,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectCalendarAssets),
      store.select(jobToolFeature.selectCalendarViewData),
    ),
    switchMap(([action, job, calendarAssets, calendarViewData]) => {
      const assetIds = calendarAssets?.map(a => a?.id) || [];

      return availabilityGQL.fetch({
        startDate: calendarViewData.startDate,
        endDate: calendarViewData.endDate,
        objectIds: assetIds,
        zone: job?.zone?.id,
      }).pipe(
        map((response) => {
          const availability = response?.data?.availabilities || [];
          return ScheduleEventsActions.calendarAvailabilityLoadingSuccess({ availability });
        }),
        catchError((error) => {
          return of(
            ScheduleEventsActions.calendarAvailabilityLoadingError({
              error,
            })
          );
        })
      );
    })
  );
}, { functional: true, dispatch: true });

export const calendarEventsRequestedEffect = createEffect(() => {
  const store = inject(Store);
  const actions$ = inject(Actions);
  const scheduleEventsGQL = inject(ScheduleEventsGQL);
  const localNotify = inject(FreyaNotificationsService);
  return actions$.pipe(
    ofType(
      ScheduleEventsActions.dateSelected,
      ScheduleEventsActions.bookEventSuccess,
      JobToolActions.eventUpdateSuccess,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectCalendarViewData),
    ),
    switchMap(([action, calendarViewData]) => {

      // Only proceed to fetch data if eventUpdateSuccess was dispatched with retrieveSchedule
      if (action.type === JobToolActions.eventUpdateSuccess.type && !action.retrieveSchedule) {
        return EMPTY;
      }

      return scheduleEventsGQL.fetch({
        filter: {
          min: calendarViewData.min,
          max: calendarViewData.max,
        },
        limit: 2000,
      }).pipe(
        switchMap((response) => {
          if (!response.loading) {

            const calendarEventDataErrors = parseGraphqlErrors(
              response.errors,
              'calendarEvents'
            );

            const calendarEvents = response?.data?.calendarEvents?.events || [];
            calendarEvents.forEach((event, index) => {
              const errors = calendarEventDataErrors.filter(
                (err) => err.listIndex === index
              );
              if (errors?.length) {
                localNotify.error(
                  `Error Occurred on: ${capitalize(event.type)}-${firstInitialLastName(getEventCustomer(event as any)?.user)}`,
                  errors.map((err) => err.property).join(', '),
                  10000
                );
              }
            });

            return of(
              ScheduleEventsActions.calendarEventsLoadingSuccess({
                calendarEvents,
              })
            );
          }
          return of();
        }),
        catchError((error) => {
          const calendarEventDataErrors = parseGraphqlErrors(
            error.graphQLErrors || [],
            'calendarEvents'
          );
          return of(
            ScheduleEventsActions.calendarEventsLoadingError({
              error,
              calendarEventDataErrors,
            })
          );
        })
      );
    })
  );
}, { functional: true, dispatch: true });


export const rescheduleEventEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const bulkEditCalendarEventGQL = inject(BulkEditCalendarEventGQL);

  return actions$.pipe(
    ofType(ScheduleEventsActions.updateEventButtonClicked),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectEventBookingData),
      store.select(eventScheduleSelectors?.selectConfigs),
    ),
    switchMap(([action, job, bookingData, configs]) => {
      const event = job?.events?.find(e => e.id === action?.eventId);

      const estimatingLength = configs[CONFIGS_KEYS.estimatingLength]
        ? Number(configs[CONFIGS_KEYS.estimatingLength]) : undefined;
      const voseLength = configs[CONFIGS_KEYS.virtualEstimateLength]
        ? Number(configs[CONFIGS_KEYS.virtualEstimateLength]) : undefined;

      const eventTimeFromConfig = {
        estimating: estimatingLength,
        virtualEstimate: voseLength,
      }

      const startToDock = bookingData?.eventInput?.includeStartDock;
      const dockToEnd = bookingData?.eventInput?.includeEndDock;
      const selectedStartTime = bookingData?.eventInput?.selectedStartTime;

      const changedAssets = bookingData?.eventInput?.selectedAssets;
      const currentAssets = event?.assets?.map(a => a.id);

      const result = calculateEventTime(
        job,
        event?.type,
        startToDock,
        dockToEnd,
        eventTimeFromConfig
      );

      const timingForQuery = getTimingForQuery(result, selectedStartTime);

      const queryVariables: BulkEditCalendarEventMutationVariables = {
        edits: {
          id: event?.id,
          edit: {
            start: bookingData?.eventInput?.selectedStartTime,
            end: timingForQuery?.end,
            ...(changedAssets?.length && {
              setAssets: {
                addAssets: changedAssets,
                removeAssets: currentAssets,
              },
            }),
          },
        }
      }
      return from(bulkEditCalendarEventGQL.mutate(queryVariables)).pipe(
        map(response => {
          const { total, events } = response?.data?.bulkEditCalendarEvent;

          if (total === events.length) {
            return JobToolActions.eventUpdateSuccess({
                updatedEvents: events as CalendarEvent[],
                retrieveSchedule: true,
            });
        } else {
            const error = new Error('Error updating event');
            console.error('Error updating event:', error);
            return JobToolActions.eventUpdateError({
                error,
            });
        }
        }),
        catchError(error =>
          of(ScheduleEventsActions.assetsLoadingError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });
