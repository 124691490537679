import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { AccountPageComponent } from './account-page/account-page.component';
import { ActOnComponent } from './act-on/act-on.component';
import { AppMainComponent } from './app.main.component';
import { AssetsComponent } from './assets/assets.component';
import { AvailabilityComponent } from './availability/availability.component';
import { BusinessUnitsComponent } from './business-units/business-units.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { PreviewDynamicReportComponent } from './dynamic-reports/preview-dynamic-report/preview-dynamic-report.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { AccountingComponent } from './franchise/accounting/accounting.component';
import { BusinessComponent } from './franchise/business/business.component';
import { BusinessGeneralComponent } from './franchise/business-general/business-general.component';
import { FranchiseInventoryItemsComponent } from './franchise/franchise-inventory-items/franchise-inventory-items.component';
import { RulesSettingsComponent } from './franchise/rules-settings/rules-settings.component';
import { TaxesComponent } from './franchise/taxes/taxes.component';
import { ZoneAreasComponent } from './franchise/zone-areas/zone-areas.component';
import { ZONE_TYPES } from './global.constants';
import { HistorySearchComponent } from './history-search/history-search.component';
import { HomeComponent } from './home/home.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { JobPageComponent } from './jobs/job-page/job-page.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobFilesComponent } from './jobsv2/job-files/job-files.component';
import { JobFinancialsComponent } from './jobsv2/job-financials/job-financials.component';
import { inventoryEffects } from './jobsv2/job-inventory/inventory.effects';
import { inventoryFeature } from './jobsv2/job-inventory/inventory.feature';
import { JobInventoryComponent } from './jobsv2/job-inventory/job-inventory.component';
import { JobOverviewComponent } from './jobsv2/job-overview/job-overview.component';
import * as WorkordersEffects from './jobsv2/job-state/workorders-state/workorders.effects';
import * as EventScheduleEffects from './jobsv2/job-state/event-schedule-state/event-schedule.effects';
import * as JobToolSharedEffects from './jobsv2/job-tool-shared.effects';
import * as UpdateEventsEffects from './jobsv2/UpdateEvents';
import * as jobToolEffects from './jobsv2/job-tool.effects';
import { jobToolFeature } from './jobsv2/job-tool.reducer';
import { JobWorkordersComponent } from './jobsv2/job-workorders/job-workorders.component';
import { Jobsv2Component } from './jobsv2/jobsv2.component';
import * as JobV2CreateCustomerEffects from './jobsv2/jobv2-create/jobv2-create-customer-state/jobv2-create-customer.effects';
import * as JobV2CreateLocationsEffects from './jobsv2/jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.effects';
import * as JobV2CreateEffects from './jobsv2/jobv2-create/jobv2-create-state/jobv2-create.effects';
import { Jobv2CreateComponent } from './jobsv2/jobv2-create/jobv2-create.component';
import * as Jobv2TimelineAvailabilityEffects from './jobsv2/jobv2-create/jobv2-timeline-availability-state/jobv2-timeline-availability-state.effects';
import * as JobV2EditEffect from './jobsv2/jobv2-edit/jobv2-edit-state/jobv2-edit.effects';
import { LeaveJobsV2Guard } from './jobsv2/leave-workorders.guard';
import { LeaveEstimatingGuard } from './leave-estimating.guard';
import { LoadingComponent } from './loading/loading.component';
import * as leadSourceEffects from './marketing/lead-source-settings/lead-source-store/lead-source.effects';
import { LeadSourceFeature } from './marketing/lead-source-settings/lead-source-store/lead-source.reducers';
import { MarketingComponent } from './marketing/marketing.component';
import { NeedsAttentionComponent } from './needs-attention/needs-attention.component';
import { NiceJobsComponent } from './nice-jobs/nice-jobs.component';
import { NotificationViewerComponent } from './notifications/notification-viewer/notification-viewer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { NoAvailableZonesComponent } from './pages/no-available-zones/no-available-zones.component';
import { PaymentRedirectComponent } from './payment/payment-redirect/payment-redirect.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentTypesComponent } from './payment-types/payment-types.component';
import { FRONTEND_PERMISSIONS } from './permissions.constants';
import { ProductsComponent } from './products/products.component';
import { QuickbooksDashboardComponent } from './quickbooks/quickbooks-dashboard/quickbooks-dashboard.component';
import { QuickbooksLoginComponent } from './quickbooks/quickbooks-login/quickbooks-login.component';
import { QuickbooksComponent } from './quickbooks/quickbooks.component';
import { ReportPageComponent } from './reports/report-page/report-page.component';
import { ReportTypePageComponent } from './reports/report-type-page/report-type-page.component';
import { ReportTypesComponent } from './reports/report-types/report-types.component';
import { ScheduledReportPageComponent } from './reports/scheduled-report-page/scheduled-report-page.component';
import { RoutePermissionGuard } from './route-permission.guard';
import { DispatchComponent } from './schedules/dispatch/dispatch.component';
import * as dispatchEffects from './schedules/dispatch/store/dispatch.effects';
import { DispatchFeature } from './schedules/dispatch/store/dispatch.reducer';
import { LeaveScheduleGuard } from './schedules/leave-schedule.guard';
import { SchedulePageComponent } from './schedules/schedule-page/schedule-page.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { SettingsComponent } from './settings/settings.component';
import { SetUrlZoneGuard } from './shared/guards/set-url-zone.guard';
import { icalStateEffects } from './shared/mutate-ical-token/ical.state';
import { UploadGuard } from './upload.guard';
import { UserAuthGuard } from './user-auth.guard';
import { UserPageComponent } from './users/user-page/user-page.component';
import { UsersComponent } from './users/users.component';
import { ZoneTypeGuard } from './zone-type.guard';



const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../app/authentication/authentication.module').then(m => m.AuthenticationModule),
    },
    {
        path: 'register',
        redirectTo: 'auth/signup',
        pathMatch: 'full'
    },
    {
        path: '', component: AppMainComponent,
        canActivate: [SetUrlZoneGuard, UserAuthGuard],
        canActivateChild: [SetUrlZoneGuard, UserAuthGuard],
        data: {
            helpId: 'default',
        },
        children: [
            { path: '', component: HomeComponent, data: { customTitle: 'Dashboard', helpId: 'dashboard' } },
            { path: 'account', component: AccountPageComponent },

            // {path: 'corporate', component: CorporateComponent},
            // {
            //     path: 'franchise-partners',
            //     component: FranchisePartnersComponent,
            //     canActivate: [RoutePermissionGuard],
            //     data: { permissions: PERMISSIONS.frontend.fr }
            // },
            {
                path: 'schedule',
                component: SchedulePageComponent,
                canActivate: [RoutePermissionGuard],
                canDeactivate: [LeaveScheduleGuard],
                providers: [
                    provideEffects(
                        icalStateEffects,
                    )
                ],
                data: {
                    permissions: FRONTEND_PERMISSIONS.schedule,
                    helpId: 'schedule',
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'day',
                        pathMatch: 'full',
                    },
                    /*
                    Ensure that 'dispatch' is the first child route and the second is the default view.
                    This will ensure that the 'dispatch' page is matched first. If the view type is not 'dispatch',
                    the Angular router will load the default view.
                    */

                    {
                        path: 'dispatch',
                        component: DispatchComponent,
                        providers: [
                            provideState(DispatchFeature),
                            provideEffects(
                                dispatchEffects,
                            )
                        ],
                        data: {
                            customTitle: 'from-component'
                        }
                    },
                    {
                        path: ':viewType',
                        component: SchedulesComponent,
                    },
                ],

            },
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.users,
                    helpId: 'users',
                },
                children: [
                    {
                        path: 'customers',
                        component: UsersComponent
                    },
                    {
                        path: 'employees',
                        component: UsersComponent
                    },
                    {
                        path: 'allcustomers',
                        component: UsersComponent
                    }
                ]
            },
            // {
            //     path: 'invoices',
            //     component: InvoicesComponent,
            //     canActivate: [RoutePermissionGuard],
            //     data: { permissions: FRONTEND_PERMISSIONS.in }
            // },
            {
                path: 'estimating',
                component: EstimatesComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.estimating,
                    helpId: 'estimating'
                },
                canDeactivate: [LeaveEstimatingGuard],
            },
            {
                path: 'estimating/:jobId',
                component: EstimatesComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.estimating,
                    customTitleCode: true,
                    helpId: 'estimating'
                },
                canDeactivate: [LeaveEstimatingGuard],
            },
            {
                path: 'notifications',
                component: NotificationsComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.notifications },
                children: [
                    {
                        path: ':notificationId',
                        component: NotificationViewerComponent,
                        canActivate: [RoutePermissionGuard],
                        data: { permissions: FRONTEND_PERMISSIONS.notifications }
                    }
                ]
            },
            {
                path: 'reports',
                component: ReportTypesComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.reports,
                    helpId: 'reports'
                }
            },
            {
                path: 'reports/dynamic/new',
                component: PreviewDynamicReportComponent,
            },
            {
                path: 'reports/:reportId',
                redirectTo: 'reports/static/:reportId',
                pathMatch: 'full',
            },
            {
                path: 'reports/:reportId/data',
                redirectTo: 'reports/static/:reportId/data',
                pathMatch: 'full',
            },
            {
                path: 'reports/dynamic/:reportId',
                component: PreviewDynamicReportComponent,
            },
            {
                path: 'reports/type/:reportTypeId',
                component: ReportTypePageComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.reports, helpId: 'reports' }
            },
            {
                path: 'reports/scheduled/:scheduledReportId',
                component: ScheduledReportPageComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.reports, helpId: 'reports' }
            },
            {
                path: 'reports/static/:reportId',
                component: ReportPageComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.reports, helpId: 'reports' }
            },
            {
                path: 'reports/static/:reportId/:tab',
                component: ReportPageComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.reports, helpId: 'reports' }
            },
            {
                path: 'loading',
                component: LoadingComponent,
            },
            {
                path: 'jobs',
                component: JobsComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.jobs,
                    helpId: 'jobs'
                },
            },
            {
                path: 'jobs/new',
                component: Jobv2CreateComponent,
                canActivate: [RoutePermissionGuard],
                providers: [
                    provideState(jobToolFeature),
                    provideEffects(
                        JobV2CreateEffects,
                        JobV2EditEffect,
                        JobV2CreateLocationsEffects,
                        Jobv2TimelineAvailabilityEffects,
                        JobV2CreateCustomerEffects,
                        JobToolSharedEffects,
                    )
                ],
                data: {
                    permissions: FRONTEND_PERMISSIONS.jobs,
                    helpId: 'jobs'
                },
            },
            {
                path: 'jobs/:jobId/edit',
                component: Jobv2CreateComponent,
                canActivate: [RoutePermissionGuard],
                providers: [
                    provideState(jobToolFeature),
                    provideEffects(
                        JobV2CreateEffects,
                        JobV2CreateCustomerEffects,
                        JobV2EditEffect,
                        JobV2CreateLocationsEffects,
                        JobToolSharedEffects,
                    )
                ],
                data: {
                    permissions: FRONTEND_PERMISSIONS.jobs,
                    helpId: 'jobs'
                },
            },
            {
                path: 'jobs/:jobId',
                redirectTo: 'jobs/:jobId/overview',
                canDeactivate: [LeaveJobsV2Guard],
            },
            {
                path: 'jobs/:jobId',
                component: Jobsv2Component,
                canActivate: [RoutePermissionGuard],
                canDeactivate: [LeaveJobsV2Guard],
                providers: [
                    provideState(jobToolFeature),
                    provideEffects(
                        jobToolEffects,
                        JobV2CreateCustomerEffects,
                        JobV2CreateLocationsEffects,
                        WorkordersEffects,
                        EventScheduleEffects,
                        JobToolSharedEffects,
                        UpdateEventsEffects,
                    )
                ],
                data: {
                    permissions: FRONTEND_PERMISSIONS.jobs,
                    helpId: 'jobs'
                },
                children: [
                    {
                        path: 'overview',
                        component: JobOverviewComponent,
                    },
                    {
                        path: 'workorders',
                        component: JobWorkordersComponent,
                    },
                    {
                        path: 'billing',
                        component: JobFinancialsComponent,
                    },
                    {
                        path: 'inventory',
                        component: JobInventoryComponent,
                        providers: [
                            provideState(inventoryFeature),
                            provideEffects(inventoryEffects),
                        ],
                    },
                    {
                        path: 'documents',
                        component: JobFilesComponent,
                    }
                ]
            },
            {
                path: 'jobs/:stage',
                component: JobsComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.jobs, },
            },
            {
                path: 'products',
                component: ProductsComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.products,
                    helpId: 'products'
                }
            },
            {
                path: 'assets',
                component: AssetsComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.assets,
                    helpId: 'assets'
                }
            },
            {
                path: 'needs-attention',
                component: NeedsAttentionComponent,
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.needsAttention },
            },
            // {
            //     path: 'franchise',
            //     loadChildren: () => import('./franchise/franchise-management.module').then(m => m.FranchiseManagementModule)
            // },
            {
                path: 'business',
                component: BusinessComponent,
                canActivate: [RoutePermissionGuard],
                canActivateChild: [ZoneTypeGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.franchise,
                    helpId: 'business'
                },
                children: [
                    {
                        path: 'general',
                        component: BusinessGeneralComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                        data: {
                            permissions: FRONTEND_PERMISSIONS.franchise,
                            helpId: 'business',
                            hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                        },
                    },
                    {
                        path: 'units',
                        component: BusinessUnitsComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                        data: {
                            permissions: FRONTEND_PERMISSIONS.franchiseManagement,
                            hiddenIfZoneType: [ZONE_TYPES.franchise],
                            helpId: 'business-units'
                        },
                    },
                    {
                        path: 'areas',
                        component: ZoneAreasComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                        data: {
                            permissions: 'zones.view',
                            hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                            helpId: 'areas'
                        },
                    },
                    {
                        path: 'rules',
                        component: RulesSettingsComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                        data: {
                            permissions: 'rules.view',
                            helpId: 'rules',
                        },
                    },
                    {
                        path: 'taxes',
                        component: TaxesComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                        data: {
                            permissions: 'taxes.view',
                            hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                            // TODO: write taxes help page
                            // helpId: 'taxes',
                        },
                    },
                    {
                        path: 'invoices',
                        component: InvoicesComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                    },
                    {
                        path: 'inventory',
                        component: FranchiseInventoryItemsComponent,
                        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
                        data: {
                            permissions: FRONTEND_PERMISSIONS.franchise,
                            hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                            // TODO: write inventory help page
                            // helpId: 'inventory',
                        },
                    },
                    {
                        path: 'availability',
                        component: AvailabilityComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.availability,
                            helpId: 'availability-schedule',
                        },
                    },
                    {
                        path: 'products',
                        component: ProductsComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.products,
                            helpId: 'products',
                        },
                    },
                    {
                        path: 'assets',
                        component: AssetsComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.assets,
                            // TO DO: Write assets help page
                            // helpId: 'assets',
                        },
                    },
                    {
                        path: 'marketing',
                        component: MarketingComponent,
                        providers: [
                            provideState(LeadSourceFeature),
                            provideEffects(
                                leadSourceEffects,
                            )
                        ],
                        data: {
                            permissions: FRONTEND_PERMISSIONS.marketing,
                            // TO DO: Write marketing help page
                            // helpId: 'marketing',
                        },
                    },
                    {
                        path: 'discounts',
                        component: DiscountsComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.marketing,
                            helpId: 'discounts',
                        },
                    },
                    {
                        path: 'settings',
                        component: SettingsComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.settings,
                            // TO DO: Write settings help page
                            // helpId: 'settings',
                        },
                    },
                    {
                        path: 'accounting',
                        component: AccountingComponent,
                        data: {
                            permmissions: FRONTEND_PERMISSIONS.settings,
                        }
                    },
                    {
                        path: 'payment',
                        component: PaymentTypesComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.settings,
                            // TO DO: Write payment types help page
                            // helpId: 'payment',
                        },
                    },
                    {
                        path: 'history',
                        component: HistorySearchComponent,
                        data: {
                            permissions: FRONTEND_PERMISSIONS.settings,
                            // TO DO: Write payment types help page
                            // helpId: 'payment',
                        },
                    },
                ]
            },
            {
                path: 'job/:id',
                component: JobPageComponent,
                canActivate: [RoutePermissionGuard],
                data: {
                    permissions: FRONTEND_PERMISSIONS.jobs,
                    helpId: 'job',
                    customTitleCode: true
                },
            },
            {
                path: 'user/:id/:tab',
                component: UserPageComponent,
                canDeactivate: [UploadGuard],
                canActivate: [RoutePermissionGuard],
                data: { permissions: FRONTEND_PERMISSIONS.users, customTitle: 'from-component', helpId: 'users' }
            },
            {
                path: 'user/:id',
                redirectTo: 'user/:id/overview'
            },
            {
                path: 'integration/nicejobs',
                component: NiceJobsComponent,
            },
            {
                path: 'integration/acton',
                component: ActOnComponent,
            },
            {
                path: 'integration/quickbooks',
                component: QuickbooksComponent,
                children: [
                    {
                        path: 'login',
                        component: QuickbooksLoginComponent,
                    },
                    {
                        path: '',
                        component: QuickbooksDashboardComponent,
                    }
                ]
            }
        ],
    },

    { path: 'payment/redirect', component: PaymentRedirectComponent },
    { path: 'payment/:transaction', component: PaymentComponent },

    { path: 'error', component: AppErrorComponent },
    { path: 'access', component: AppAccessdeniedComponent },
    { path: 'notfound', component: AppNotfoundComponent },
    { path: 'no-available-zones', component: NoAvailableZonesComponent },

    // Redirects
    { path: 'login', redirectTo: '/auth/login' },

    { path: '**', redirectTo: '/notfound' },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
