import { on } from "@ngrx/store"
import { JobToolState } from "../../job-tool.reducer"
import { ScheduleEventsActions } from "./event-schedule.actions"
import { CalendarEventWithLockedAndInvoicedFlag } from "../../jobv2-create/jobv2-interfaces";
import { calculateDateRange, calculateMinMaxFromTimestamp } from "../../jobsv2-events-helpers";
import { LoadingState } from "src/app/utilities/state.util";

export const scheduleEventsReducers = [
    on(ScheduleEventsActions.startEndDockIncluded, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state.eventBookingData,
                eventInput: {
                    ...state?.eventBookingData?.eventInput,
                    ...(res.includeEndDock !== undefined && { includeEndDock: res.includeEndDock }),
                    ...(res.includeStartDock !== undefined && { includeStartDock: res.includeStartDock }),
                },
            },
        };
    }),
    on(ScheduleEventsActions.eventInfoUpdated, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state?.eventBookingData,
                eventInfo: {
                    ...state?.eventBookingData?.eventInfo,
                    totalLocationTime: res?.updatedEventInfo?.totalLocationTime,
                    totalTime: res?.updatedEventInfo?.totalTime,
                    totalTravelTime: res?.updatedEventInfo?.totalTravelTime,
                }
            }
        }
    }),
    on(ScheduleEventsActions.dateSelected, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state?.eventBookingData,
                eventInput: {
                    ...state.eventBookingData.eventInput,
                    selectedDate: res.date,
                    selectedStartTime: undefined,
                    selectedAssets: [],
                },
                availability : {
                    ...state?.eventBookingData?.availability,
                    ...(res?.preselectedAssets?.length
                        ? { availableAssets: res?.preselectedAssets }
                        : {})
                }
            },
            calendarViewData: calculateDateRange(res.date),
        }
    }),
    on(ScheduleEventsActions.findAvailabilitySuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state?.eventBookingData,
                availability: {
                    ...state.eventBookingData.availability,
                    startTimes: res.startTimes,
                    possibleWindows: res.possibleWindows,
                    hasLockedWindows: res.hasLockedWindows,
                }
            }
        }
    }),
    on(ScheduleEventsActions.timeSelected, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state?.eventBookingData,
                eventInput: {
                    ...state?.eventBookingData?.eventInput,
                    selectedStartTime: res.time,
                }
            }
        }
    }),
    on(ScheduleEventsActions.availableAssetsSet, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state?.eventBookingData,
                availability: {
                    ...state.eventBookingData.availability,
                    availableAssets: res.availableAssets,
                }
            }
        }
    }),
    on(ScheduleEventsActions.assetsSelected, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            eventBookingData: {
                ...state?.eventBookingData,
                eventInput: {
                    ...state?.eventBookingData?.eventInput,
                    selectedAssets: res.selectedAssetsIds,
                }
            }
        }
    }),
    on(ScheduleEventsActions.bookButtonClicked, (state: JobToolState) => {
        return {
            ...state,
            callState: {
                ...state.callState,
                bookEvent: LoadingState.MUTATING,
            }
        }
    }),
    on(ScheduleEventsActions.bookEventSuccess, (state: JobToolState, { updatedEvent }) => {
        const updatedEvents = state.job?.events.map(event =>
          event.id === updatedEvent.id ? updatedEvent : event
        );

        return {
          ...state,
          job: {
            ...state.job,
            events: updatedEvents as CalendarEventWithLockedAndInvoicedFlag[],
          },
          eventBookingData: {
            eventInput: {
              minWindowLength: undefined,
              selectedDate: undefined,
              selectedStartTime: undefined,
              selectedAssets: [],
              includeStartDock: true,
              includeEndDock: true,
            },
            eventInfo: undefined,
            availability: {
              startTimes: [],
              possibleWindows: [],
              availableAssets: [],
              hasLockedWindows: false,
            },
          },
          calendarViewData: {
            ...state?.calendarViewData,
            ...calculateMinMaxFromTimestamp(updatedEvent?.start),
          },
          callState: {
            ...state.callState,
            bookEvent: LoadingState.MUTATED,
        }
        };
      }),
    on(ScheduleEventsActions.bookEventError, (state: JobToolState, res) => {
        return {
            ...state,
            eventBookingData: {
                eventInput: {
                    minWindowLength: undefined,
                    selectedDate: undefined,
                    selectedStartTime: undefined,
                    selectedAssets: [],
                    includeStartDock: true,
                    includeEndDock: true,
                },
                eventInfo: undefined,
                availability: {
                    startTimes: [],
                    possibleWindows: [],
                    availableAssets: [],
                    hasLockedWindows: false,
                },
            },
            callState: {
                ...state.callState,
                bookEvent: {
                    error: res.error.message,
                }
            }
        };
    }),
    on(ScheduleEventsActions.bookDialogClosed, (state: JobToolState) => {
        return {
            ...state,
            eventBookingData: {
                eventInput: {
                    minWindowLength: undefined,
                    selectedDate: undefined,
                    selectedStartTime: undefined,
                    selectedAssets: [],
                    includeStartDock: true,
                    includeEndDock: true,
                },
                eventInfo: undefined,
                availability: {
                    startTimes: [],
                    possibleWindows: [],
                    availableAssets: [],
                    hasLockedWindows: false,
                },
            }
        };
    }),
    on(ScheduleEventsActions.calendarEventsLoadingSuccess, (state: JobToolState, res) => {
        return {
            ...state,
            calendarEvents: res.calendarEvents,
        };
    }),
    on(ScheduleEventsActions.assetsLoadingSuccess, (state: JobToolState, res) => {
        return {
            ...state,
            calendarAssets: res.assets,
        };
    }),
    on(ScheduleEventsActions.calendarAvailabilityLoadingSuccess, (state: JobToolState, res) => {
        return {
            ...state,
            calendarAvailability: res.availability,
        };
    }),
]