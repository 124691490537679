import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { ConfirmationService, MenuItem, MenuItemCommandEvent } from 'primeng/api';

import { Menu } from 'primeng/menu';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { EventStatuses } from '../../../schedules/dispatch/store/dispatch.reducer';
import { DetailsHelperService } from '../../../services/details-helper.service';
import { JobToolActions } from '../../job-tool.actions';
import { JobEvent, jobToolFeature } from '../../job-tool.reducer';

import { TimelineCreateEventComponent } from "./timeline-create-event/timeline-create-event.component";
import { TimelineBookEventComponent } from './timeline-book-event/timeline-book-event.component';
import { ScheduleEventsActions } from '../../job-state/event-schedule-state/event-schedule.actions';
import { eventScheduleSelectors } from '../../job-state/event-schedule-state/event-schedule.selectors';
import { switchMap, tap } from 'rxjs';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { EventHelperService } from 'src/app/services/event-helper.service';
import { getMissingLocations, isEventMissingLocations } from '../../jobsv2-helpers';

enum EventMenuItem {
  Confirm = 0,
  Complete,
  Calendar,
  Edit,
  Book,
  Reschedule,
  MoreInfo,
  Cancel,
  UndoCancel,
  Delete
}

interface EventMenuItemData {
  eventId: string;
  eventIndex: number;
  title: string;
}
interface EventActionMenuItem extends MenuItem {
  data?: EventMenuItemData;
}

const menuItemsHiddenByDefault = new Set([
  EventMenuItem.Confirm,
  EventMenuItem.Complete,
  EventMenuItem.Cancel,
  EventMenuItem.UndoCancel,
  EventMenuItem.Delete,
]);

export type EventsWithMissingLocations = Record<string, string[]>;

@Component({
  selector: 'app-overview-timeline',
  standalone: true,
  imports: [
    FreyaCommonModule,
    TimelineCreateEventComponent,
    TimelineBookEventComponent
  ],
  templateUrl: './overview-timeline.component.html',
  styleUrl: './overview-timeline.component.scss'
})
export class OverviewTimelineComponent implements OnInit, OnDestroy {

  @ViewChild('menu') menu: Menu;

  public eventMenuItems: EventActionMenuItem[] = [
    {
      label: 'Confirm',
      icon: 'pi pi-check',
      command: ($event: MenuItemCommandEvent) => {
        this.promoteEvent($event, EventStatuses.Confirmed);
      }
    },
    {
      id: 'complete',
      label: 'Mark complete',
      icon: 'pi pi-check-circle',
      command: ($event: MenuItemCommandEvent) => {
        this.promoteEvent($event, EventStatuses.Completed);
      },
    },
    {
      label: 'Book',
      icon: 'pi pi-calendar-plus',
      command: ($event: MenuItemCommandEvent) => this.bookEvent($event),
    },
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: ($event: MenuItemCommandEvent) => this.editEvent($event)
    },
    {
      label: 'More Info',
      icon: 'pi pi-info-circle',
      command: ($event: MenuItemCommandEvent) => this.openEventDetails($event)
    },
    {
      label: 'Cancel',
      icon: 'pi pi-times',
      command: ($event: MenuItemCommandEvent) => {
        this.cancelEvent($event);
      },
    },
    {
      label: 'Undo Cancel',
      icon: 'pi pi-undo',
      command: ($event: MenuItemCommandEvent) => {
        this.undoCancel($event);
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: ($event: MenuItemCommandEvent) => {
        this.deleteEvent($event);
      },
    },
  ]

  public jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);
  public jobEvents$ = this.store.select(jobToolFeature.selectJobEvents);
  public jobZone$ = this.store.select(eventScheduleSelectors.selectJobZone);
  public eventsWithMissingLocations$ = this.store.select(eventScheduleSelectors.selectEventsWithMissingLocations);

  public jobEvents: JobEvent[] = [];
  public eventUnderEdittingIndex: number | null = null;
  public eventUnderBookingId: string | null = null;
  public isDialogVisible = false;
  public isBookDialogVisible = false;

  timezone: string;
  jobInDifferentTimezoneWarning: string;
  eventsWithMissingLocations: EventsWithMissingLocations;

  private subs = new SubSink();

  constructor(
    private store: Store,
    private detailsHelper: DetailsHelperService,
    private confirmationService: ConfirmationService,
    private timezoneHelper: TimezoneHelperService,
    private eventService: EventHelperService,
  ) { }

  ngOnInit(): void {
    this.watchJobEvents();

    //TO DO move timezone logic from component, potentially handle in branding
    this.subs.sink = this.jobZone$.pipe(
      switchMap((zone) => {
        return this.timezoneHelper.getTimeZoneWithJobAreaPriority(zone?.id);
      }),
      tap(timezone => {
        this.handleJobTimeZone(timezone);
      })
    ).subscribe();

    this.subs.sink = this.eventService.bookEvent$.subscribe((event) => {
      const menuItemEvent: MenuItemCommandEvent = { item: { data: { eventId: event.id } } };
      this.bookEvent(menuItemEvent);
    });

    this.subs.sink = this.eventsWithMissingLocations$.subscribe((eventsWithMissingLocations) => {
      this.eventsWithMissingLocations = eventsWithMissingLocations;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  handleJobTimeZone(timezone: string): void {
    const systemTimeZone = this.timezoneHelper.getCurrentTimezone();
    this.timezone = systemTimeZone;
    if (timezone !== systemTimeZone) {
      this.timezone = timezone;
      this.jobInDifferentTimezoneWarning = `You are selecting event start time in ${timezone} timezone`
    } else {
      this.jobInDifferentTimezoneWarning = '';
    }
  }

  private watchJobEvents() {
    this.subs.sink = this.jobEvents$.subscribe((events) => {
      this.jobEvents = events || [];
    });
  }
  public openActionMenu($event: Event, jobEvent: JobEvent, eventIndex: number) {
    const { event: calendarEvent } = jobEvent;


    this.eventMenuItems.forEach((item, index) => {
      item.data = { eventId: calendarEvent.id, eventIndex, title: calendarEvent.title } as EventMenuItemData;
      item.visible = !menuItemsHiddenByDefault.has(index);

      //TO DO check why managing visibility through filterPastStatuses
      //doesn't work and move it there
      if (item.label === 'Book' || item.label === 'Reschedule') {
        // Set the label based on the event status
        item.label = calendarEvent.status === 'booked' ? 'Reschedule' : 'Book';
        item.disabled = this.isEventMissingLocations(calendarEvent?.id);
        item.tooltip = this.getMissingLocationsTooltip(calendarEvent?.id);

        if (calendarEvent.status === 'completed' || calendarEvent.status === 'cancelled') {
          item.visible = false;
        }
      }

      if (item.label === 'Delete' && calendarEvent?.status === 'required') {
        item.visible = true;
      }
    });

    this.filterPastStatuses(calendarEvent.status as EventStatuses);

    this.menu.toggle($event);
  }

  private promoteEvent($event: MenuItemCommandEvent, status: EventStatuses) {
    const { eventId } = $event.item.data as EventMenuItemData;

    if (eventId) {
      this.store.dispatch(JobToolActions.eventUpdateRequested({
        edits: [{
          id: eventId,
          edit: {
            status
          }
        }]
      }));
    }
  }

  private cancelEvent($event: MenuItemCommandEvent) {

    const { eventId } = $event.item.data as EventMenuItemData;
    if (!eventId) return;

    this.confirmationService.confirm({
      header: 'Cancel Event?',
      message: `Cancelling this event will remove it from the schedule and remove any associated charges and discounts`,
      acceptLabel: 'Cancel this Event',
      acceptIcon: 'pi pi-ban',
      rejectLabel: `Don't Cancel this Event`,
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.store.dispatch(JobToolActions.eventUpdateRequested({
          edits: [
            {
              id: eventId,
              edit: {
                status: EventStatuses.Cancelled
              }
            }
          ]
        }));
      }
    });
  }

  private undoCancel($event: MenuItemCommandEvent) {

    const { eventId } = $event.item.data as EventMenuItemData;
    if (!eventId) return;

    const message = `This will add the event back into the schedule, `
      + 'but it will not actually book the event nor check if there are any conflicting events scheduled for the same time. '
      + 'Please make sure there are no conflicting events, then book the event manually.';

    this.confirmationService.confirm({
      header: 'Undo Cancel?',
      message,
      acceptLabel: 'Undo Cancel',
      acceptIcon: 'pi pi-undo',
      rejectLabel: 'Nevermind',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.store.dispatch(JobToolActions.eventUpdateRequested({
          edits: [
            {
              id: eventId,
              edit: {
                status: EventStatuses.Pending
              }
            }
          ]
        }));
      }
    });
  }

  private deleteEvent($event: MenuItemCommandEvent) {
    const { eventId, title = 'Calendar Event' } = $event.item.data as EventMenuItemData;
    if (!eventId) return;

    this.confirmationService.confirm({
      target: $event.originalEvent.target as EventTarget,
      message: `Are you sure you want to delete event: ${title} ?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      acceptButtonStyleClass: "p-button-danger",
      rejectButtonStyleClass: "p-button-text",
      acceptLabel: 'Delete this Event',
      acceptIcon: 'pi pi-trash',
      rejectLabel: `Don't Delete this Event`,
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.store.dispatch(JobToolActions.eventDeletionRequested({ eventId }));
      },
    });


  }

  private openEventDetails($event: MenuItemCommandEvent) {
    const { eventId } = $event.item.data as EventMenuItemData;
    this.openEventInRightPanel(eventId);
  }

  public openEventInRightPanel(eventId: string) {
    this.detailsHelper.open('calendar-event', { id: eventId });
  }

  private filterPastStatuses(status: EventStatuses) {
    // By default all menu items are visible
    switch (status) {
      case 'pending':
        this.eventMenuItems[EventMenuItem.Cancel].visible = true;
        break;
      case 'required':
        //TO DO check why this cause undefined error
        //this.eventMenuItems[EventMenuItem.Delete].visible = true;
        break;
      case 'booked':
        this.eventMenuItems[EventMenuItem.Confirm].visible = true;
        this.eventMenuItems[EventMenuItem.Complete].visible = true;
        this.eventMenuItems[EventMenuItem.Cancel].visible = true;
        break;
      case 'confirmed':
        this.eventMenuItems[EventMenuItem.Complete].visible = true;
        this.eventMenuItems[EventMenuItem.Cancel].visible = true;
        break;
      case 'completed':
        this.eventMenuItems[EventMenuItem.Cancel].visible = true;
        this.eventMenuItems[EventMenuItem.Book].visible = false;
        break;
      case 'cancelled':
        this.eventMenuItems[EventMenuItem.UndoCancel].visible = true;
        this.eventMenuItems[EventMenuItem.Delete].visible = true;
        this.eventMenuItems[EventMenuItem.Edit].visible = false;
        this.eventMenuItems[EventMenuItem.Book].visible = false;
        break;
    }
  }

  public openBookEventDialog() {
    this.isBookDialogVisible = true;
  }

  public openNewEventDialog() {
    this.isDialogVisible = true;
  }

  bookEvent(eventOrEventId: MenuItemCommandEvent | string): void {
    let eventId: string;

    if (typeof eventOrEventId === 'string') {
        eventId = eventOrEventId;
    } else {
        eventId = eventOrEventId.item.data.eventId;
    }

    this.eventUnderBookingId = eventId;
    this.openBookEventDialog();
    this.store.dispatch(
        ScheduleEventsActions.bookEventScreenOpened({
            eventId,
        })
    );
}

  public editEvent($event: MenuItemCommandEvent) {
    const { eventIndex } = $event.item.data as EventMenuItemData;
    this.eventUnderEdittingIndex = eventIndex;

    this.openNewEventDialog();
  }

  public closeNewEventDialog() {
    this.isDialogVisible = false;
    this.eventUnderEdittingIndex = null;
  }

  public closeBookEventDialog() {
    this.isBookDialogVisible = false;
  }

  public isEventMissingLocations(eventId: string | undefined): boolean {
    return isEventMissingLocations(this.eventsWithMissingLocations, eventId);
  }

  public getMissingLocationsTooltip(eventId: string | undefined): string {
    const missingLocations = getMissingLocations(this.eventsWithMissingLocations, eventId);
    return missingLocations.length > 0
      ? `Missing locations: ${missingLocations.join(', ')}`
      : '';
  }

  get eventUnderBooking(): JobEvent | undefined {
    return this.jobEvents?.find(event => event.event.id === this.eventUnderBookingId);
  }
}
