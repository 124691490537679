<div class="card calendar-holder no-border-radius">
  <div class="p-grid calendar-header-wrapper">
    <div class="p-md-4 p-pr-0 p-mr-auto">
      <span class="button-group">
        <button
          pButton
          icon="pi pi-chevron-left"
          (click)="changeDate('prev')"
        ></button>
        <button
          pButton
          label="Today"
          (click)="changeDate('today')"
          data-cy="today-button"
        ></button>
        <button
          pButton
          icon="pi pi-chevron-right"
          (click)="changeDate('next')"
        ></button>
      </span>
      <span
        *ngIf="calendar?.view?.type === 'dayGridMonth'"
        class="switch-label-group p-ml-2"
      >
        <p-inputSwitch
          inputId="collapsedEvents"
          [(ngModel)]="showMonthEvents"
          (onChange)="setMaxEvents($event.checked)"
        ></p-inputSwitch>
        <label for="collapsedEvents">Show Events</label>
      </span>
    </div>
    <div class="p-col-12 p-md-4 fc calendar-title-wrapper">
      <H2
        class="fc-toolbar-title"
        *ngIf="calendarTitle$ | async as calendarTitle"
      >
        {{ calendarTitle }}
      </H2>
    </div>
    <div class="p-md-4 p-d-inline-flex p-jc-md-end p-ai-start">
      <div class="button-group">
        <button
          pButton
          label="Day"
          (click)="changeView('resourceTimelineDay')"
          data-by="calendar-day-button"
        ></button>
        <button
          pButton
          label="Week"
          (click)="changeView('resourceTimelineWeek')"
          data-by="calendar-week-button"
        ></button>
        <button
          pButton
          label="Month"
          (click)="changeView('dayGridMonth')"
          data-by="calendar-month-button"
        ></button>
      </div>
    </div>
  </div>

  <div class="timezone-warning-container" *ngIf="jobInDifferentTimezoneWarning">
    <p-message severity="warn" [text]="jobInDifferentTimezoneWarning">
    </p-message>
  </div>

  <p-message
    *ngIf="noAvailableAssetsWarning"
    severity="warn"
    [text]="noAvailableAssetsWarning"
    class="warning"
  ></p-message>

  <div class="calendar-holder" style="position: relative">
    <full-calendar #fc [options]="calendarOptions"></full-calendar>
  </div>
</div>
