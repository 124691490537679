import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { InventoryItem, InventoryItemDefinition, JobInventory } from './inventory.feature';

export const InventoryActions = createActionGroup({
	source: 'Inventory',
	events: {
		'Inventory Page Initialized': emptyProps(),
		'Inventory Loading': emptyProps(),
		'Inventory Loaded': props<{
			inventory: JobInventory;

			// deprecated
			notes: string;
		}>(),

		'Inventory Changed': props<{
			action: { type: string };
			lastRoomChanged: string;
			inventory: JobInventory;
			doFocus?: string;
		}>(),

		'Save Button Clicked': emptyProps(),

		'Item Search Complete Request': props<{
			search: string;
			roomId: string;
		}>(),
		'Item Search Complete Result': props<{
			items: InventoryItemDefinition[],
		}>(),
		'Item Search Selected': props<{
			item: InventoryItemDefinition;
			roomId: string;
			doFocus?: string;
		}>(),
		'Item Search Save': props<{
			search: string;
			roomId: string;
			doFocus?: string;
		}>(),
		'Item Search Enter Pressed': props<{
			key: KeyboardEvent;
			roomId: string;
			doFocus?: string;
		}>(),

		'Remove Room Click': props<{
			roomId: string;
		}>(),

		'Edit Item Click': props<{
			item: InventoryItem;
			roomId: string;
		}>(),

		'Remove Item Click': props<{
			item: InventoryItem;
			roomId: string;
		}>(),
		'Quantity Updated': props<{
			item: InventoryItem;
			roomId: string;
			quantity: number;
			doFocus?: string;
		}>(),

		// Add room dialog
		'Add Room Dialog Hidden': emptyProps(),
		'Add Room Button Clicked': emptyProps(),
		'Add Room Room Selected': props<{ room: string }>(),

		// edit item dialog
		'Edit Item Dialog Toggle Visible': props<{visible?: boolean}>(),
		'Edit Item Saved': emptyProps(),
		'Edit Item Updated': props<{ key: keyof InventoryItem, value: any }>(),
	},
});
