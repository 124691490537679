<div class="card">
    <div class="padding">
        <div class="header-with-button">
            <h3> Book {{event?.event?.title }} for {{ customerName }}</h3>
            <button
                pButton
                class="p-button-text p-m-1"
                icon="pi pi-times"
                type="button"
                (click)="onClose()">
            </button>
        </div>
        <div class="main-container">
            <div class="schedule-form">
                <article [style]="event?.style">
                    <div class="eventDetails">
                        <span class="title">{{ event?.title }}</span>
                        <span class="info">{{ event?.details }}</span>
                    </div>
                </article>
                <div class="warnings-container">
                    <p-message *ngIf="(job?.users | jobCustomer) === 'None'" severity="warn" text="Customer Required"
                        styleClass="mr-2"></p-message>
                    <p-message *ngIf="job?.zone?.type !== 'area'" severity="warn"
                        text="Your job needs to be in an area to book events" styleClass="mr-2"></p-message>
                    <p-message *ngIf="missingLocations?.length && estimateHelper.restrictionsEnabled.value" severity="warn"
                        text="Missing Locations: {{missingLocations | join}}" styleClass="cursor-pointer mr-2">
                        <ng-template pTemplate="content">
                            Missing Locations:
                            <ul>
                                <li *ngFor="let location of missingLocations">
                                    {{ location | titlecase }}
                                </li>
                            </ul>
                        </ng-template>
                    </p-message>
                    <p-message
                        *ngIf="event?.event?.status === 'required' && preselectedEventTimeIsNotAvailableWarning"
                        severity="warn"
                        [text]="preselectedEventTimeIsNotAvailableWarning"
                        styleClass="mr-2">
                    </p-message>
                    <p-message
                        *ngIf="event?.event?.status === 'required' && preselectedAssetsTimeAreNotAvailableWarning"
                        severity="warn"
                        [text]="preselectedAssetsTimeAreNotAvailableWarning"
                        styleClass="mr-2">
                    </p-message>
                </div>
                <div *hasPermission="['jobs.update']" [formGroup]="eventForm" [hidden]="event" class="event-form-flex">

                    <div *ngIf="event?.event?.status !== 'booked' || rescheduleEventMode">
                        <strong>Date:</strong>
                        <!-- This is also disabled/enabled in the "reset" method -->
                        <p-calendar
                            class="full-width-calendar"
                            dataType="string"
                            formControlName="date"
                            (onSelect)="eventDateSelected()"
                            appendTo="body"
                            [minDate]="freyaHelperService.lockDateObject$ | async"
                        ></p-calendar>
                        <small *ngIf="freyaHelperService.lockDate$ | async">You must schedule events after {{ formattedLockDate.short }} (Lock Date)</small>
                    </div>

                    <div *ngIf="event?.event?.status !== 'booked' || rescheduleEventMode">
                        <strong>Start Time: </strong>
                        <!-- Restrictions enabled (start time) -->
                        <div [hidden]="!estimateHelper.restrictionsEnabled.value">
                            <p-dropdown
                                class="full-width-dropdown"
                                [placeholder]="timePlaceholder"
                                (onChange)="eventTimeSelected()"
                                [options]="possibleTimes ? possibleTimes : []"
                                formControlName="time"
                                appendTo="body"
                            >
                                <ng-template let-time pTemplate="item">
                                    <p *ngIf="time === 'Now'">Now</p>
                                    <p *ngIf="time !== 'Now'">{{ time | freyaDate: "h:mm a": timezone }}</p>
                                </ng-template>
                                <ng-template let-time pTemplate="selectedItem">
                                    <p *ngIf="time === 'Now'">Now</p>
                                    <p *ngIf="time !== 'Now'">{{ time | freyaDate: "h:mm a": timezone }}</p>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <!-- Restrictions enabled (end time time) -->
                        <div *ngIf="!estimateHelper.restrictionsEnabled.value">
                            <p-calendar
                                showTime="true"
                                placeholder="Select Time"
                                [hourFormat]="12"
                                [timeOnly]="true"
                                formControlName="time"
                                appendTo="body"
                                [minDate]="freyaHelperService.lockDateObject$ | async"
                            >
                            </p-calendar>
                            <strong>⚠️ Restrictions Disabled ⚠️</strong>
                        </div>
                        <!-- Time estimates: -->
                        <div *ngIf="(totalLocationTime$ | async) as totalLocationTime">
                            Location Time: {{ totalLocationTime | secondsToTimeUnit }}
                        </div>
                        <div *ngIf="(totalTravelTime$ | async) as totalTravelTime">
                            Travel Time: {{ totalTravelTime | secondsToTimeUnit }}
                        </div>
                            <small *ngIf="(hasLockedWindows$ | async)">
                                Some times are not shown because they conflict with the lock date ({{ formattedLockDate.long }})
                            </small>
                    </div>
                    <div *ngIf="event?.event?.status !== 'booked' || rescheduleEventMode">
                        <strong>{{ assetTypes?.join(', ') }}: </strong>
                        <br />
                        <p-multiSelect
                            class="full-width-multiselect"
                            placeholder="Select Asset"
                            [options]="possibleAssets ? possibleAssets : []"
                            formControlName="assets"
                            filterBy="name"
                            appendTo="body"
                            [autofocusFilter]="!responsiveHelper.isSmallScreen"
                            (onChange)="assetsSelected()"
                        >
                            <ng-template let-asset pTemplate="item">
                                <p>{{ asset.name }}</p>
                            </ng-template>
                            <ng-template let-asset pTemplate="selectedItems">
                                <p *ngFor="let a of eventForm.value.assets">{{ a.name }}</p>
                                <p *ngIf="!eventForm.value.assets?.length">Select {{ assetTypes?.join(', ') }}</p>
                            </ng-template>
                        </p-multiSelect>
                        <small>
                            Searching for Types: {{ assetTypes?.join(', ') }}
                          </small>

                    </div>
                    <div *ngIf="event?.event?.status !== 'booked' || rescheduleEventMode" class="include-container">
                        <strong class="include-text">Include travel time for:</strong>
                        <br>
                        <p-checkbox
                            [binary]="true"
                            label="Dock to Start Location"
                            formControlName="dockToStart"
                            (onChange)="startEndDockIncluded($event, 'includeStartDock')"
                            class="checkbox m-r-1"
                        ></p-checkbox>
                        <p-checkbox
                            [binary]="true"
                            label="End Location to Dock"
                            formControlName="endToDock"
                            (onChange)="startEndDockIncluded($event, 'includeEndDock')"
                             class="checkbox"
                        ></p-checkbox>
                    </div>
                    <div *ngIf="event?.event?.status !== 'booked' && !rescheduleEventMode">
                        <button
                            *hasPermission="['calendarEvents.create']"
                            pButton
                            class="full-width quick-margin"
                            label="Book"
                            [icon]="(eventBeingScheduled$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-calendar-plus'"
                            [disabled]="(eventBeingScheduled$ | async)
                                || !eventForm.touched
                                || eventForm.invalid
                                || (selectedTime$ | async) === undefined
                                || (selectedAssets$ | async).length === 0"
                            (click)="bookEventButtonClicked()"
                        ></button>
                    </div>
                    <div *ngIf="event?.event?.status === 'booked' && !rescheduleEventMode">
                        <h6>Date</h6>
                        <span class="freya-link-button">{{ event.event.start | freyaDate: 'MMM d, y': timezone }}</span>
                        <div class="p-mt-2">
                            <app-object-state
                                *ngIf="event?.event?.status === 'cancelled'"
                                [object]="event"
                                objectType="event"
                                [colorful]="true"
                            ></app-object-state>
                        </div>
                    </div>
                    <div *ngIf="event?.event?.status === 'booked' && !rescheduleEventMode">
                        <h6>Start / End</h6>
                        <p>{{ event.event | eventDuration: false :false :true :timezone }}</p>
                    </div>
                    <div *ngIf="event?.event?.status === 'booked' && !rescheduleEventMode">
                        <h6>Start / End (Dock to Dock)</h6>
                        <p>{{ event.event | eventDuration: true :false :true :timezone }}</p>
                    </div>
                    <div *ngIf="event?.event?.status === 'booked' && !rescheduleEventMode">
                        <h6>Assets</h6>
                        <p *ngFor="let a of event.event.assets">{{ a.name }}</p>
                    </div>
                    <div *ngIf="event?.event?.start">
                        <span
                            [pTooltip]="event?.event?.locked ? 'This event ends before ' + formattedLockDate.short : undefined"
                            tooltipPosition="bottom"
                            >
                        </span>
                        <span
                            [pTooltip]="event?.event?.locked ? 'This event ends before ' + formattedLockDate.short : undefined"
                            tooltipPosition="bottom"
                        >
                        <div *ngIf="event?.event?.status === 'booked' && !rescheduleEventMode">
                            <button
                                *hasPermission="['calendarEvents.update']"
                                pButton
                                class="full-width quick-margin"
                                label="Reschedule"
                                icon="pi pi-calendar-plus"
                                [disabled]="(eventBeingScheduled$ | async)"
                                (click)="rescheduleButtonClicked()"
                            ></button>
                        </div>
                        <div *ngIf="event?.event?.status === 'booked' && rescheduleEventMode" class="update-cancel-buttons">
                            <button
                                pButton
                                class="full-width quick-margin p-button-text"
                                label="Cancel"
                                icon="pi pi-times"
                                (click)="cancelRescheduleButtonClick()"
                            ></button>
                            <button
                                pButton
                                class="full-width quick-margin"
                                label="Update Event"
                                icon="pi pi-check"
                                [disabled]="(selectedTime$ | async) === undefined"
                                (click)="updateEventButtonClicked()"
                            ></button>
                        </div>
                        </span>
                        <div
                            *ngIf="
                                (event.event.title === 'Time Lock' && !job?.users?.length) ||
                                !job?.users?.[0].user
                            "
                        >
                            <p-message
                                severity="warn"
                                text="Events cannot be booked until a customer has been set"
                            ></p-message>
                        </div>
                    </div>
                </div>
                <p-message
                    *ngIf="jobInDifferentTimezoneWarning"
                    severity="warn"
                    [text]="jobInDifferentTimezoneWarning">
                </p-message>
            </div>
            <div class="schedule-container">
                <app-schedule-v2
                #schedule
                [area]="job?.zone?.type === 'area' && job?.zone?.id"
                [timezone]="timezone"
                [jobInDifferentTimezoneWarning]="jobInDifferentTimezoneWarning"
                [event]="event?.event"
            ></app-schedule-v2>
            </div>
            <div>
            </div>
        </div>
    </div>
</div>