import { createSelector } from "@ngrx/store";
import { jobToolFeature } from "../../job-tool.reducer";
import { eventTypeInfoMapV2 } from "src/app/global.constants";
import { getJobLocation } from "src/app/jobs/jobs.util";
import { brandingFeature } from "src/app/state/branding.store";

export const selectEventMissingLocations = (eventId: string) =>
    createSelector(
        jobToolFeature.selectJob,
        (job) => {
            if (!job || !job.events) {
                return [];
            }

            const event = job.events.find(e => e.id === eventId);
            const eventTypeInfo = eventTypeInfoMapV2[event.type];

            const missingLocations = [];

            for (const locationType of eventTypeInfo.requiredLocations) {
                if (!getJobLocation(job, locationType)) {
                    missingLocations.push(locationType);
                }
            }

            return missingLocations;
        }
    );

export const selectEventsWithMissingLocations = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    if (!job || !job.events) {
      return {};
    }

    const eventsWithMissingLocations: Record<string, string[]> = {};

    for (const event of job.events) {
      const eventTypeInfo = eventTypeInfoMapV2[event.type];

      // Find all missing locations for the current event
      const missingLocations = eventTypeInfo.requiredLocations.filter(
        (locationType) => !getJobLocation(job, locationType)
      );

      if (missingLocations.length > 0) {
        eventsWithMissingLocations[event.id] = missingLocations;
      }
    }

    return eventsWithMissingLocations;
  }
);

export const selectConfigs = brandingFeature?.selectConfigs;

export const selectStartToDockIncluded = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      const startToDockIncluded = eventBookingData?.eventInput?.includeStartDock;
      return startToDockIncluded;
    }
);

export const selectDockToEndIncluded = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      const dockToEndIncluded = eventBookingData?.eventInput?.includeEndDock;
      return dockToEndIncluded;
    }
);

export const selectTotalLocationTime = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      return eventBookingData?.eventInfo?.totalLocationTime;
    }
);

export const selectTotalTravelTime = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      return eventBookingData?.eventInfo?.totalTravelTime;
    }
);

export const selectTotalTime = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      return eventBookingData?.eventInfo?.totalTime;
    }
);

export const selectPossibleTimes = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      return eventBookingData?.availability?.startTimes;
    }
);

export const selectPossibleWindows = createSelector(
  jobToolFeature.selectEventBookingData,
  (eventBookingData) => {
    return eventBookingData?.availability?.possibleWindows;
  }
);

export const selectPossibleAssets = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      return eventBookingData?.availability?.availableAssets;
    }
);

export const selectHasLockedWindows = createSelector(
    jobToolFeature.selectEventBookingData,
    (eventBookingData) => {
      return eventBookingData?.availability?.hasLockedWindows;
    }
);

export const selectEventStartTime = createSelector(
  jobToolFeature.selectEventBookingData,
  (eventBookingData) => {
    return eventBookingData?.eventInput?.selectedStartTime;
  }
);

export const selectEventDate = createSelector(
  jobToolFeature.selectEventBookingData,
  (eventBookingData) => {
    return eventBookingData?.eventInput?.selectedDate;
  }
);

export const selectEventAssets = createSelector(
  jobToolFeature.selectEventBookingData,
  (eventBookingData) => {
    return eventBookingData?.eventInput?.selectedAssets;
  }
);

export const selectJobZone = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    return job?.zone;
  }
);

export const selectJobTimezone = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    return job.timezone;
  }
);

export const selectCalendarAssets = (state) => state.jobTool.calendarAssets;

export const selectFilteredCalendarAssets = (eventType: string) => createSelector(
  selectCalendarAssets,
  (calendarAssets) => {
    const assetTypes = eventTypeInfoMapV2[eventType]?.assetTypes || [];
    return calendarAssets.filter((asset) => assetTypes.includes(asset.type));
  }
);

export const eventScheduleSelectors = {
    selectEventMissingLocations,
    selectEventsWithMissingLocations,
    selectConfigs,
    selectStartToDockIncluded,
    selectDockToEndIncluded,
    selectTotalLocationTime,
    selectTotalTravelTime,
    selectTotalTime,
    selectPossibleTimes,
    selectPossibleAssets,
    selectHasLockedWindows,
    selectEventStartTime,
    selectEventAssets,
    selectPossibleWindows,
    selectJobZone,
    selectJobTimezone,
    selectEventDate,
    selectFilteredCalendarAssets,
}