import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EstimatesJobFragment } from 'graphql.generated';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { withLatestFrom } from 'rxjs';
import { HistoryService } from 'src/app/history/history.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { FreyaHelperService, ViewableArtifact } from 'src/app/services/freya-helper.service';
import { SubSink } from 'subsink';

import { PlusAuthenticationService } from '../../core/public-api';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../services/details-helper.service';
import { calculateJobTotals } from '../job-calculator';
import { WorkOrdersActions } from '../job-state/workorders-state/workorders.actions';
import { workOrdersSelectors } from '../job-state/workorders-state/workorders.selectors';
import { JobToolActions } from '../job-tool.actions';
import { jobToolFeature } from '../job-tool.reducer';
import { CloseJobV2Component } from '../jobv2-create/close-job-componentV2/close-job-componentV2.component';
import { JobCreateLocationsActions } from '../jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.actions';

import { selectCanChangeArea } from '../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';

import { JobStatusDropdownComponent } from "./job-status-dropdown/job-status-dropdown.component";
import { JobTagsComponent } from './job-tags/job-tags.component';

@Component({
  selector: 'app-job-header',
  standalone: true,
  imports: [
    FreyaCommonModule,
    JobStatusDropdownComponent,
    JobTagsComponent
  ],
  templateUrl: './job-header.component.html',
  styleUrl: './job-header.component.scss'
})
export class JobHeaderComponent implements OnInit, OnDestroy {
  loaded$ = this.store.select(jobToolFeature.jobLoaded);
  jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
  job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);
  canChangeArea$ = this.store.select(selectCanChangeArea);
  zone$ = this.store.select(jobToolFeature.selectZone);
  customerName$ = this.store.select(jobToolFeature.selectCustomerName);
  documentArtifacts$ = this.store.select(jobToolFeature.selectDocumentsArtifacts);
  chargesUpdates$ = this.store.select(jobToolFeature.selectChargesUpdates);

  latestInvoiceArtifact: ViewableArtifact | undefined = undefined;

  job: EstimatesJobFragment;

  actionMenuItems: MenuItem[] = [];

  subs = new SubSink();

  constructor(
    public store: Store,
    public detailsHelper: DetailsHelperService,
    public authSvc: PlusAuthenticationService,
    public documentHelper: DocumentHelperService,
    public historyService: HistoryService,
    public dialogService: DialogService,
    private router: Router,
    private freyaHelper: FreyaHelperService,
  ) {
  }

  ngOnInit(): void {

    this.subs.sink = this.job$.subscribe((job) => {
      if (job) {

        this.job = calculateJobTotals(job);
        this.updateToggleActions();
      }
    });

    this.subs.sink = this.documentArtifacts$.subscribe((documentArtifacts) => {

      if (!this.actionMenuItems.length) return;

      this.latestInvoiceArtifact = documentArtifacts['invoice'];
      const downloadInvoiceItem = this.actionMenuItems.find(item => item.id === 'download-invoice');

      if (!downloadInvoiceItem) { return; }

      downloadInvoiceItem.visible = Boolean(this.latestInvoiceArtifact);
    });

    this.job$
      .pipe(withLatestFrom(this.canChangeArea$))
      .subscribe(([ job, canChangeArea ]) => {
        this.setActionMenuItems(job, canChangeArea);
      });
  }

  setActionMenuItems(job: EstimatesJobFragment, canChangeArea: boolean) {

    const isClosed = Boolean(job?.closedAt);

    this.actionMenuItems = [
      {
        id: 'edit',
        label: 'Edit Job',
        icon: 'pi pi-pencil',
        command: () => {
          this.onEditClick(this.job.id);
        }
      },
      {
        id: 'estimate',
        label: 'Open in legacy processor',
        icon: 'pi pi-money-bill',
        command: () => {
          this.onOpenInOldEstimatorClick(this.job.id);
        }
      },
      {
        id: 'download-invoice',
        label: 'Download latest invoice',
        icon: 'pi pi-file-o',
        command: () => {
          this.downloadLatestInvoice();
        },
        visible: false,
      },
      {
        id: 'toggle-open',
        label: undefined,
        icon: undefined,
      },
      {
        id: 'view-history',
        label: 'View History',
        icon: 'pi pi-book',
        command: () => {
          this.viewJobHistory();
        },
      },
      {
        id: 'send-documents',
        label: 'Send Document',
        icon: 'pi pi-send',
        disabled: false,
        command: () => this.documentHelper.openDocumentsDialog({
          jobId: this.job?.id,
          jobCode: this.job?.code
        }),
      },
      {
        id: 'change-area',
        label: 'Change Area',
        icon: 'pi pi-map',
        disabled: !canChangeArea,
        tooltip: !canChangeArea ? 'You must be in the same area as the job to change it' : undefined,
        command: () => this.changeArea(),
      },
      isClosed ? {
        id: 'delete',
        label: 'Delete Job',
        icon: 'pi pi-trash',
        command: () => this.deleteJob(),
      } : null,
    ].filter(Boolean);

    this.updateToggleActions();
  }

  closeJob() {
    return this.dialogService.open(CloseJobV2Component, {
        header: 'Close job',
        width: '42rem',
    });
  }

  reopenJob() {
    this.store.dispatch(JobToolActions.reopenExistingJob({ jobId: this.job?.id }));
  }

  saveJob() {
    this.store.dispatch(WorkOrdersActions.saveButtonClicked());
  }

  deleteJob() {
    this.store.dispatch(JobToolActions.jobDeletedRequested({ jobId: this.job?.id }))
  }

  onEditClick(jobId: string): void {;
    this.router.navigate([`/jobs/${jobId}/edit`]);
  }

  onOpenInOldEstimatorClick(jobId: string): void {;
    this.router.navigate([`/estimating/${jobId}`]);
  }

  downloadLatestInvoice() {
    if (this.latestInvoiceArtifact) {
      this.documentHelper.downloadDocument(this.latestInvoiceArtifact);
    }
  }

  viewJobHistory() {
    if (!this?.job?.id) { return; }
      this.historyService.openHistory('Job', [ this.job.id ]);
  }

  updateToggleActions() {

    if (!this.actionMenuItems.length) { return; }

    const isJobClosed = Boolean(this.job?.closedAt);

    const menuItem = this.actionMenuItems.find(item => item?.id === 'toggle-open');

    if (!menuItem) { return; }

    const { disableClose, reason } = this.freyaHelper.getDisableClose(this.job);

    menuItem.label = isJobClosed ? 'Open' : 'Close';
    menuItem.icon = isJobClosed ? `pi pi-circle` : 'pi pi-circle-fill';
    menuItem.disabled = disableClose;
    menuItem.tooltip = reason;

    menuItem.command = () => {
      if (isJobClosed) {
        this.reopenJob();
      } else {
        this.closeJob();
      }
    };
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async changeArea() {
    const res = await this.freyaHelper.openSelectAreaDialog({
      actionRequired: false,
      onlyShowCurrentSubzones: false,
    });

    if (!res) { return; }

    this.store.dispatch(JobCreateLocationsActions.locationSelectAreaManually({
      zone: res,
      requestUpdate: true,
    }));
  }

}
