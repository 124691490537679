import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Asset, FindQuery } from "graphql.generated";
import { cloneDeep, every, includes } from "lodash";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { eventTypeInfoMapV2 } from "src/app/global.constants";
import { ScheduleEventsActions } from "src/app/jobsv2/job-state/event-schedule-state/event-schedule.actions";
import { eventScheduleSelectors } from "src/app/jobsv2/job-state/event-schedule-state/event-schedule.selectors";
import { FullJobFragmentWithFields, JobEvent, jobToolFeature } from "src/app/jobsv2/job-tool.reducer";
import { getStartUnix } from "src/app/jobsv2/jobsv2-events-helpers";
import { EstimateHelperService } from "src/app/services/estimate-helper.service";
import { FreyaHelperService } from "src/app/services/freya-helper.service";
import { ResponsiveHelperService } from "src/app/services/responsive-helper.service";
import { SharedModule } from "src/app/shared/shared.module";
import { getJobCustomer } from "src/app/utilities/job-customer.util";
import { SubSink } from "subsink";
import { ScheduleV2Component } from "../schedule-v2/schedule-v2.component";
import { FreyaDatePipe } from "src/app/shared/freya-date.pipe";

@Component({
    selector: 'app-timeline-book-event',
    standalone: true,
    imports: [
        FreyaCommonModule,
        SharedModule,
        ReactiveFormsModule,
        ScheduleV2Component,
    ],
    templateUrl: './timeline-book-event.component.html',
    styleUrl: './timeline-book-event.component.scss'
})
export class TimelineBookEventComponent implements OnInit, OnDestroy {
    @Input() event: JobEvent | null;
    @Input() timezone: string;
    @Input() jobInDifferentTimezoneWarning: string;
    @Output() closeDialog = new EventEmitter<void>();

    //observables
    job$ = this.store.select(jobToolFeature.selectJob);
    jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
    totalLocationTime$ = this.store.select(eventScheduleSelectors?.selectTotalLocationTime);
    totalTravelTime$ = this.store.select(eventScheduleSelectors?.selectTotalTravelTime);
    possibleTimes$ = this.store.select(eventScheduleSelectors?.selectPossibleTimes);
    eventBeingScheduled$ = this.store.select(jobToolFeature.isEventBeingScheduled);
    selectedStartDate$ = this.store.select(eventScheduleSelectors.selectEventDate);

    possibleAssets$ = this.store.select(eventScheduleSelectors?.selectPossibleAssets);
    hasLockedWindows$ = this.store.select(eventScheduleSelectors?.selectHasLockedWindows);
    selectedTime$ = this.store.select(eventScheduleSelectors?.selectEventStartTime);
    selectedAssets$ = this.store.select(eventScheduleSelectors?.selectEventAssets);
    missingLocations$;
    public job: FullJobFragmentWithFields;
    eventTypeInfoMap = eventTypeInfoMapV2;
    assetTypes: string[] | [] = [];
    customerName: string;
    preselectedEventTimeIsNotAvailableWarning: string = '';
    preselectedAssetsTimeAreNotAvailableWarning: string = '';
    timePlaceholder: string = 'Select Time';

    // COMPONENT STATE VARIABLES
    eventForm: UntypedFormGroup = new UntypedFormGroup({
        date: new UntypedFormControl({ value: undefined, disabled: true }, [Validators.required]),
        time: new UntypedFormControl({ value: undefined, disabled: true }, [Validators.required]),
        assets: new UntypedFormControl({ value: [], disabled: true }, [Validators.required]),
        dockToStart: new UntypedFormControl({ value: true, disabled: true }),
        endToDock: new UntypedFormControl({ value: true, disabled: true }),
    });
    // Used to reset
    eventFormValues = cloneDeep(this.eventForm.value);

    // BOOKING VARIABLES
    // A list of the required locations that are missing for the event type
    missingLocations: string[] = [];
    // Avaialble start times based on date, ignored if restrictions are disabled
    possibleTimes: number[] = [];
    // Available Assets based on date and start time
    possibleAssets: Asset[] = [];
    // Windows are how the assets and users are sorted, stores raw return
    possibleWindows: FindQuery['find']['windows'] = [];

    formattedLockDate = {
        long: undefined,
        short: undefined,
    };

    rescheduleEventMode = false;

    private subs = new SubSink();

    constructor(
        private store: Store,
        public estimateHelper: EstimateHelperService,
        public freyaHelperService: FreyaHelperService,
        public responsiveHelper: ResponsiveHelperService,
        private freyaDatePipe: FreyaDatePipe,
    ) { }

    ngOnInit(): void {

        this.subs.sink = this.freyaHelperService.lockDate$.subscribe((unixLockDate) => {
            this.setIsFormattedLockDate();
        });

        this.subs.sink = this.job$.subscribe((job) => {
            this.job = cloneDeep(job);
            this.customerName = getJobCustomer(this.job?.users, true) as string;
            this.preselectedEventTimeIsNotAvailableWarning = '';
        });

        if (this.event) {
            this.missingLocations$ = this.store.select(
                eventScheduleSelectors.selectEventMissingLocations(this.event.event.id)
            );

            this.subs.sink = this.missingLocations$.subscribe((missingLocations) => {
                this.missingLocations = missingLocations;
            });

            this.assetTypes = this.eventTypeInfoMap[this.event.event.type].assetTypes
        }

        this.subs.sink = this.possibleTimes$.subscribe((possibleTimes) => {
            this.possibleTimes = cloneDeep(possibleTimes);

            //when event has preselected start time but not booked yet
            if (this.event?.event?.start && this?.event?.event?.status === 'required') {

                //time still available
                if (this.possibleTimes?.includes(this.event?.event?.start)) {

                    this.eventForm.get('time')?.setValue(this.event?.event?.start);

                    const time = getStartUnix(this.event?.event?.start, this.timezone);
                    this.store.dispatch(ScheduleEventsActions.timeSelected({ time, eventId: this.event.event.id }));
                    this.preselectedEventTimeIsNotAvailableWarning = '';
                } else {
                    const formattedTime = this.freyaDatePipe.transform(
                        this.event?.event?.start,
                        'h:mm a',
                        this.timezone
                      );
                    this.timePlaceholder = formattedTime;
                    this.preselectedEventTimeIsNotAvailableWarning =
                        "Start time for the event is not available anymore. Please, select another time slot.";
                }
            }
        });

        this.subs.sink = this.possibleAssets$.subscribe((possibleAssets) => {
            this.possibleAssets = cloneDeep(possibleAssets);

            //when event has preselected assets but not booked yet
            if (this.event?.event?.status === 'required' && this.event?.event?.assets?.length) {
                //preselected assets set into possibleAssets at this point
                //and this.event?.event?.assets among them, so we can show them
                this.eventForm.get('assets')?.patchValue(this.event?.event?.assets);

                const selectedAssetsIds = this.event?.event?.assets?.map(a => a?.id);
                const possibleAssetsIds = this.possibleAssets?.map(a => a.id);

                this.store.dispatch(ScheduleEventsActions.assetsSelected({ selectedAssetsIds }));

                let allSelectedArePossible = false;

                // Check if every ID in selectedAssetsIds is included in possibleAssetsIds
                if (selectedAssetsIds.every(id => possibleAssetsIds.includes(id))) {
                    allSelectedArePossible = true;
                }

                //after user selected time and asset is not available for that time
                if (!allSelectedArePossible) {
                    this.preselectedAssetsTimeAreNotAvailableWarning = `
                    One or few selected assets are not available for selected time. Please, select another time or another asset`
                } else {
                    this.preselectedAssetsTimeAreNotAvailableWarning = '';
                }
            }
        });

        this.subs.sink = this.selectedStartDate$.subscribe((selectedStartDate) => {
            this.eventForm.get('date')?.setValue(selectedStartDate);
        });

        this.manageDisabledFormState();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onClose() {
        this.closeDialog.emit();
        this.cancelRescheduleButtonClick();
    }

    rescheduleButtonClicked() {
        const date = this.eventForm.value.date;
        this.store.dispatch(ScheduleEventsActions.rescheduleButtonClicked({
            date,
            eventType: this.event.event.type,
            currentEventIdToExclude:  this.event.event.id
        }));

        this.rescheduleEventMode = true;
    }

    cancelRescheduleButtonClick() {
        this.rescheduleEventMode = false;
        this.store.dispatch(ScheduleEventsActions.bookDialogClosed());
    }

    updateEventButtonClicked() {
        this.store.dispatch(ScheduleEventsActions.updateEventButtonClicked({ eventId:  this.event.event.id }));
        this.rescheduleEventMode = false;
    }

    setIsFormattedLockDate() {
        this.formattedLockDate = {
            long: this.freyaHelperService.getFormattedLockDate(),
            short: this.freyaHelperService.getFormattedLockDate('MMM DD'),
        };
    }

    eventDateSelected() {
        const date = this.eventForm.value.date;

        this.store.dispatch(ScheduleEventsActions.dateSelected({
            date,
            eventType: this.event.event.type,
            ...(this.event?.event?.status === 'required' && this.event?.event?.start
                ? { currentEventIdToExclude: this.event?.event?.id }
                : {}),
            ...(this.event?.event?.assets?.length && this.event?.event?.status === 'required'
                ? { preselectedAssets: this.event?.event?.assets }
                : {})
        }));
    }

    eventTimeSelected() {
        const rawTime = this.eventForm.controls.time.value;
        //TO DO need to move it from component, either to reducer or to effect
        //to do convertation in reducer, need to have timezone in jobFeature
        const time = getStartUnix(rawTime, this.timezone);
        this.store.dispatch(ScheduleEventsActions.timeSelected({ time, eventId: this.event.event.id }));
    }

    assetsSelected() {
        const selectedAssetsIds = this.eventForm.controls.assets.value.map((a) => a.id);
        this.store.dispatch(ScheduleEventsActions.assetsSelected({ selectedAssetsIds }));
    }

    startEndDockIncluded(event: CheckboxChangeEvent, checkbox: string) {
        this.store.dispatch(ScheduleEventsActions.startEndDockIncluded({
            [checkbox]: event.checked,
            eventId: this.event.event.id
        }))
    }

    bookEventButtonClicked() {
        this.store.dispatch(ScheduleEventsActions.bookButtonClicked({ eventId: this.event.event.id }));
        this.preselectedEventTimeIsNotAvailableWarning = '';
    }

    manageDisabledFormState() {
        this.subs.sink = combineLatest([
            this.jobLoading$ as Observable<boolean>,
            this.missingLocations$ as Observable<string[]>,
            this.estimateHelper.restrictionsEnabled as Observable<boolean>,
            this.possibleTimes$ as Observable<any[]>,
            this.selectedTime$ as Observable<string | number>,
        ]).subscribe(([jobLoading, missingLocations, restrictionsEnabled, possibleTimes, selectedTime]) => {

                const formDisabled = jobLoading
                    || (missingLocations && missingLocations?.length && restrictionsEnabled)
                    || !this.job;

                if (formDisabled) {
                    this.eventForm.disable({ emitEvent: false });
                    return;
                }

                const dateEnabled = getJobCustomer(this.job?.users) !== 'None';

                this.freyaHelperService.setDisabledControls(dateEnabled, this.eventForm, ['date'], true);

                const timeEnabled = Boolean(possibleTimes?.length);

                this.freyaHelperService.setDisabledControls(timeEnabled, this.eventForm, ['time'], true);

                const assetsEnabled = Boolean(selectedTime) || (Boolean(this.possibleAssets?.length));

                this.freyaHelperService.setDisabledControls(assetsEnabled, this.eventForm, ['assets'], true);

                this.freyaHelperService.setDisabledControls(true, this.eventForm, [
                    'dockToStart',
                    'endToDock',
                ], true);
            });
    }

}