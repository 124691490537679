import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Asset, AvailabilityOutput, CalendarEvent, CalendarEventForScheduleFragment, FindQuery } from 'graphql.generated';
import { EventInfo } from 'src/app/services/estimate-helper.service';
import { DataError } from 'src/app/utilities/errors.util';
import { AssetWithConfig } from 'src/app/availability/availability.interfaces';

export const ScheduleEventsActions = createActionGroup({
   source: 'Schedule Event',
   events: {
      'Book Event Screen Opened': props<{ eventId: string }>(),
      'Start End Dock Included': props<{ includeStartDock?: boolean, includeEndDock?: boolean, eventId: string }>(),
      'Event Info Updated': props<{ updatedEventInfo: EventInfo }>(),

      'Date Selected': props<{
         date: string,
         eventType: string,
         currentEventIdToExclude?: string,
         preselectedAssets?: Asset[],
      }>(),
      'Find Availability Success': props<{
         startTimes: any,
         possibleWindows: FindQuery['find']['windows'] | [];
         hasLockedWindows: boolean
      }>(),
      'Find Availability Error': props<{ error: Error }>(),

      'Time Selected': props<{ time: number, eventId: string }>(),

      'Available Assets Set': props<{ availableAssets: any }>(),
      'Assets Selected': props<{ selectedAssetsIds: string[] }>(),

      'Book Button Clicked': props<{ eventId: string }>(),
      'Book Event Success': props<{ updatedEvent: CalendarEvent }>(),
      'Book Event Error': props<{ error: Error }>(),

      'Reschedule Button Clicked': props<{ date: string, eventType: string, currentEventIdToExclude: string }>(),
      'Update Event Button Clicked': props<{ eventId: string }>(),
      'Reschedule Event Success': props<{ updatedEvent: CalendarEvent }>(),
      'Reschedule Event Error': props<{ error: Error }>(),

      'Book Dialog Closed': emptyProps(),

      'Assets Loading Success': props<{ assets: AssetWithConfig[] | [] }>(),
      'Assets Loading Error': props<{ error: Error, }>(),

      'Calendar Availability Loading Success': props<{ availability: AvailabilityOutput[] | [] }>(),
      'Calendar Availability Loading Error': props<{ error: Error, }>(),

      'Calendar Events Loading Success': props<{ calendarEvents: CalendarEventForScheduleFragment[] | [] }>(),
      'Calendar Events Loading Error': props<{ error: Error, calendarEventDataErrors: DataError[] | [], }>(),

      'Noop': emptyProps(),
   },
});
